import react, { useState, useContext } from "react";
import { Panel, FormPanel, Container, Button, ComboBoxField, CheckBoxField, DatePickerField, FileField, Spacer, Grid, Column, FieldSet, TextField, TextAreaField } from '@sencha/ext-react-modern';
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import Calendario from "../components/Calendario/Calendario/Calendario.js";
import CalendarioSintetico from "../components/Calendario/CalendarioSintetico/CalendarioSintetico.js";
import Stazioni from "../components/Calendario/Stazioni/Stazioni.js";
import serviziInfrLocModel from '../models/ServiziInfrLocModel.js';
import { useUser } from '../components/UserContext';
import periodicitaModel from '../models/PeriodicitaIIServiziModel.js';

const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);

const storePeriodicitaIIServizi = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

//const storeGiorni = Ext.create('Ext.data.Store', {
//    fields: ['text', 'value'],
//    data: [
//        {
//            "text": "Sempre", "value": "Sempre"
//        },
//        {
//            "text": "Lunedi", "value": "Lunedi"
//        },
//        {
//            "text": "Martedi", "value": "Martedi"
//        },
//        {
//            "text": "Mercoledi", "value": "Mercoledi"
//        },
//        {
//            "text": "Giovedi", "value": "Giovedi"
//        },
//        {
//            "text": "Venerdi", "value": "Venerdi"
//        },
//        {
//            "text": "Sabato", "value": "Sabato"
//        },
//        {
//            "text": "Domenica", "value": "Domenica"
//        },
//        {
//            "text": "Lavorativi", "value": "Lavorativi"
//        },
//        {
//            "text": "Festivi", "value": "Festivi"
//        },
//        {
//            "text": "PreFestivi", "value": "PreFestivi"
//        },
//        {
//            "text": "Post-Festivi", "value": "Post-Festivi"
//        },
//        {
//            "text": "Seguenti Post-Festivi", "value": "SeguentiPost-Festivi"
//        }
//    ]
//});

//const periodicita = "100000110000011000001100000120000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000";

//const dataInizio = "15-12-2022";

//const dataFine = "23-12-2023";

//const dataAttProvvedimento = "09-06-2023";

////#endregion

////#region Calendario Sintetico

//const periodicitaSintetico = "000000211101";

//const giorniTreno = 333;

////#endregion

////#region stazioni

//const stazPartenza = "Firenze S. Maria Novella";

//const stazArrivo = "Napoli Centrale";
//const colore = "#5fa2dd"


let titolo = "";
let servizioSelezionato = null;
let localitaSelezionata = null;


function PoIILegami(props) {
    const [isCalendarioVisible, setIsCalendarioVisible] = useState(false);

    const contextVersioneRto = useContext(VersioneRtoContext);
    const poSchede = props.storePoSchede;
    const listaPO = contextVersioneRto.listaPO;
    //const rtoSchede = contextVersioneRto.storeRtoSchede;
    const { user, switchUser } = useUser();
    let arrayLocalitaDisponibili = [];
    let arrayServiziDisponibili = [];
    let arrayAreeManovraLoc = [];


    //const storeServiziImpianto = Ext.create('Ext.data.Store', {
    //    proxy: {
    //        type: 'localstorage',
    //    },
    //    autoload: false
    //});

    //function onPeriodicitaClick(grid, info) {
    //    caricaPeriodicitaServizoImpianto(info.record.data.idE_SER_IFS_LOC);
    //    setIsCalendarioVisible(true);
    //    titolo = "Periodicita servizio " + info.record.data.noM_SER_IFS + " - " + info.record.data.noM_LOC;
    //    servizioSelezionato = info.record.data.idE_SER_IFS_LOC;
    //    localitaSelezionata = info.record.data.idE_LOC;
    //}

    //const caricaServiziImpianto = () => {
    //    storeServiziImpianto.loadData(props.impegnoImpiantoPO.serviziInfrastrutturaLocalita);
    //}

    //const caricaPeriodicitaServizoImpianto = (idE_SER_IFS_LOC) => {
    //    var periodicitaByDB = null;
    //    Ext.Ajax.request({
    //        url: 'Po/GetImpegnoImpiantoTrc',
    //        method: 'GET',
    //        params: {
    //            ideUte: user.ideUte,
    //            ideRTOXPO: rtoSchede.data.items[0].data.rto.ideRTO,
    //            ideTrc: listaPO[0].ideTrcOra
    //        },
    //        callback: function (resp, success, operation) {
    //            periodicitaByDB = Ext.decode(operation.responseText);
    //            storePeriodicitaIIServizi.loadData(periodicitaByDB.periodicitaImpegnoImpiantoServizi.filter(function (value) {
    //                return value.ideParent === idE_SER_IFS_LOC;
    //            }))
    //        }
    //    })
    //}

    //caricaServiziImpianto();

    
    const storePeriodicitaIILegamiPO = Ext.create('Ext.data.Store', {
        proxy: {
            type: 'localstorage',
        },
        autoload: false
    });

    const storeLegamiImpiantoPO = Ext.create('Ext.data.Store', {
        proxy: {
            type: 'localstorage',
        },
        autoload: false
    });

    const caricaLegamiImpianto = () => {
        storeLegamiImpiantoPO.loadData(props.impegnoImpiantoPO.legamiLocalita);
    }
    caricaLegamiImpianto();

    function onApriRTOClick(grid, info) {
        window.open("/SchedaRtoLettura?ideRto=" + info.record.data.idE_RTO_LEG + "&ideCli=" + user.ideCli, '_blank');
    }

    return (
    <Container>
        <Panel
            title="Legami Impianto"
            titleCollapse={true}
            iconCls="x-fa fa-link"
            collapsible={{ collapsed: false, direction: 'top' }}
            cls='PnlSectionPOL2 iconPanelsRTO'
        >
            <Container
                layout="vbox" itemId="containerLegamiPo" fitToParent={true} cls="column-in-panel"
            >
                <Grid
                    itemId="gridLegamiImpiantoPo"
                    minHeight={100}
                    maxHeight={230}
                    disabled={isCalendarioVisible}
                    markDirty={true}
                    flex={1}
                    scrollable
                    selectable={{ checkbox: false, mode: 'single' }}
                    store={storeLegamiImpiantoPO}
                    itemConfig={{
                        viewModel: true
                        }}
                    cls="custom-grid"
                >

                    <Column text="Localita" dataIndex="noM_LOC" filter='string' />
                    <Column text="Tempo min Sosta" dataIndex="miN_SOSTA" filter='string' flex={1} />
                    <Column text="N.Treno / RTO" dataIndex="nuM_TRENO" filter='string' flex={1} />
                    <Column itemId='apriPOLegame' flex={1} align="center"
                        cell={{

                            tools: {

                                apriRTO: {
                                    handler: onApriRTOClick.bind(this),
                                    bind: {
                                        iconCls: "x-fa fa-folder-open buttonGridTool",
                                        tooltip: 'Apri RTO Legata',
                                        hidden: '{record.idE_RTO_LEG === "" || record.idE_RTO_LEG === null || record.idE_RTO_LEG === 0}',
                                    }

                                }
                            }

                        }}

                    />
                    <Column text="Legame Indotto" dataIndex="legamE_IMPOSTO" filter='string' />
                    <Column text="Tempo max proseg." dataIndex="maX_SOSTA" filter='string' flex={1} />
                    <Column text="Altra IF" dataIndex="altraIF" filter='string' flex={1} />
                    <Column text="Note Altra IF" dataIndex="nota" filter='string' flex={1} />
                    <Column text="Periodicit&aacute" dataIndex="periodicita" filter='string' flex={1} />

                </Grid>
              
            </Container>
        </Panel>
    </Container>
    );
}

export default PoIILegami;
import react, { useState, useContext } from "react";
import { Panel, FormPanel, Container, Button, ComboBoxField, CheckBoxField, DatePickerField, FileField, Spacer, Grid, Column, FieldSet, TextField, TextAreaField } from '@sencha/ext-react-modern';
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import Calendario from "../components/Calendario/Calendario/Calendario.js";
import CalendarioSintetico from "../components/Calendario/CalendarioSintetico/CalendarioSintetico.js";
import Stazioni from "../components/Calendario/Stazioni/Stazioni.js";
import serviziInfrLocModel from '../models/ServiziInfrLocModel.js';
import { useUser } from '../components/UserContext';
import periodicitaModel from '../models/PeriodicitaIIServiziModel.js';

const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);

const storePeriodicitaIIServizi = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

const storeGiorni = Ext.create('Ext.data.Store', {
    fields: ['text', 'value'],
    data: [
        {
            "text": "Sempre", "value": "Sempre"
        },
        {
            "text": "Lunedi", "value": "Lunedi"
        },
        {
            "text": "Martedi", "value": "Martedi"
        },
        {
            "text": "Mercoledi", "value": "Mercoledi"
        },
        {
            "text": "Giovedi", "value": "Giovedi"
        },
        {
            "text": "Venerdi", "value": "Venerdi"
        },
        {
            "text": "Sabato", "value": "Sabato"
        },
        {
            "text": "Domenica", "value": "Domenica"
        },
        {
            "text": "Lavorativi", "value": "Lavorativi"
        },
        {
            "text": "Festivi", "value": "Festivi"
        },
        {
            "text": "PreFestivi", "value": "PreFestivi"
        },
        {
            "text": "Post-Festivi", "value": "Post-Festivi"
        },
        {
            "text": "Seguenti Post-Festivi", "value": "SeguentiPost-Festivi"
        }
    ]
});

const periodicita = "100000110000011000001100000120000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000001100000110000011000";

const dataInizio = "15-12-2022";

const dataFine = "23-12-2023";

const dataAttProvvedimento = "09-06-2023";

//#endregion

//#region Calendario Sintetico

const periodicitaSintetico = "000000211101";

const giorniTreno = 333;

//#endregion

//#region stazioni

const stazPartenza = "Firenze S. Maria Novella";

const stazArrivo = "Napoli Centrale";
const colore = "#5fa2dd"


let titolo = "";
let servizioSelezionato = null;
let localitaSelezionata = null;


function PoIIServizi(props) {
    const [isCalendarioVisible, setIsCalendarioVisible] = useState(false);

    const contextVersioneRto = useContext(VersioneRtoContext);
    const poSchede = props.storePoSchede;
    const listaPO = contextVersioneRto.listaPO;
    const rtoSchede = contextVersioneRto.storeRtoSchede; 
    const { user, switchUser } = useUser();
    let arrayLocalitaDisponibili = [];
    let arrayServiziDisponibili = [];
    let arrayAreeManovraLoc = [];


    const storeServiziImpianto = Ext.create('Ext.data.Store', {
        proxy: {
            type: 'localstorage',
        },
        autoload: false
    });

    function onPeriodicitaClick(grid, info) {
        caricaPeriodicitaServizoImpianto(info.record.data.idE_SER_IFS_LOC);
        setIsCalendarioVisible(true);
        titolo = "Periodicita servizio " + info.record.data.noM_SER_IFS + " - " + info.record.data.noM_LOC;
        servizioSelezionato = info.record.data.idE_SER_IFS_LOC;
        localitaSelezionata = info.record.data.idE_LOC;
    }

    const caricaServiziImpianto = () => {
        storeServiziImpianto.loadData(props.impegnoImpiantoPO.serviziInfrastrutturaLocalita);
    }

    const caricaPeriodicitaServizoImpianto = (idE_SER_IFS_LOC) => {
        var periodicitaByDB = null;
        Ext.Ajax.request({
            url: 'Po/GetImpegnoImpiantoTrc',
            method: 'GET',
            params: {
                ideUte: user.ideUte,
                ideRTOXPO: rtoSchede.data.items[0].data.rto.ideRTO,
                ideTrc: listaPO[0].ideTrcOra
            },
            callback: function (resp, success, operation) {
                periodicitaByDB = Ext.decode(operation.responseText);
                storePeriodicitaIIServizi.loadData(periodicitaByDB.periodicitaImpegnoImpiantoServizi.filter(function (value) {
                    return value.ideParent === idE_SER_IFS_LOC;
                }))
            }
        })
    }

    caricaServiziImpianto();


    return (
    <Container>
        <Panel
            title="Servizi Impianto"
            titleCollapse={true}
            iconCls="x-fa fa-charging-station"
            collapsible={{ collapsed: false, direction: 'top' }}
            cls='PnlSectionPOL2 iconPanelsRTO'
        >
            <Container
                layout="vbox" itemId="containerServPo" fitToParent={true} cls="column-in-panel"
            >
                <Grid
                    itemId="gridServiziImpiantoPo"
                    minHeight='100'
                    height='100'
                    disabled={isCalendarioVisible}
                    markDirty={true}
                    flex={1}
                    scrollable
                    selectable={{ checkbox: false, mode: 'single' }}
                    store={storeServiziImpianto}
                    itemConfig={{
                        viewModel: true
                    }}
                >
                    <Column flex={1} text="Localita" dataIndex="noM_LOC" filter='string' />
                    <Column text="Servizio" dataIndex="noM_SER_IFS" flex={1}   />
                    <Column text="Area" dataIndex="noM_AREA" flex={1}  />
                    <Column text="Minuti" dataIndex="durata" filter='string' flex={1}  />
                    <Column text="Periodicita" dataIndex="periodicita" filter='string' flex={1} />
                    <Column itemId='actionRTOIIServizi' editable={false} text="Azioni" flex={1}
                        cell={{

                            tools: {
                                periodicitaRTOIISERVIZI: {

                                    handler: onPeriodicitaClick.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-calendar",

                                        tooltip: 'Periodicita'
                                    }

                                }

                            }

                        }}
                    />
                </Grid>
                {isCalendarioVisible && (
                    <Panel title={titolo} itemId="panelCalendarioPerServIIPo" closable={true} listeners=
                        {
                            {
                                close: function () {
                                    setIsCalendarioVisible(false);
                                }
                            }} 
                    >
                        <Grid
                            itemId="gridPeriodicitaIIServiziPo"
                            minHeight={100}
                            maxHeight={230}
                            markDirty={true}
                            flex={1}
                            scrollable
                            selectable={{ checkbox: false, mode: 'single' }}
                            store={storePeriodicitaIIServizi}
                            itemConfig={{
                                viewModel: true
                                }}
                            cls="custom-grid"
                        >
                                <Column text="Operativit&aacute" dataIndex="ideOpv" width={150} filter='string' renderer={function (value, metaData, records, view) {
                                if (value === 0)
                                    return "Circola"
                                else
                                    return "Soppresso"
                            }} />
                            <Column text="Dal" width={200} type="datecolumn" formatter='date("d/m/Y")' filter='date' dataIndex="datIniPer"  />
                            <Column text="Al" width={200} type="datecolumn" formatter='date("d/m/Y")' filter='date' dataIndex="datFinPer"  />
                            <Column text="Giorni" editable={false} dataIndex="desPer" filter='string' flex={1} />
                        </Grid>
                        <Panel collapsible={false} cls="calendar-container">
                            <br />
                            <div className="periodicitaHeader">
                                <CalendarioSintetico
                                    giorniTreno={giorniTreno}
                                    periodicitaSintetico={periodicitaSintetico}
                                />
                            </div>
                        </Panel>
                        <Panel collapsible={false}>
                            <Calendario
                                dataInizio={dataInizio}
                                dataFine={dataFine}
                                periodicita={periodicita}
                                dataAttProvvedimento={dataAttProvvedimento}
                                periodicitaSintetico={periodicitaSintetico}
                                IsNonEffettuabile = {true}
                                isAttivazioneProvvedimento = {false}
                            />
                        </Panel>
                    </Panel>)}
            </Container>
        </Panel>
    </Container>
    );
}

export default PoIIServizi;
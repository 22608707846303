import React from 'react';
import { Panel, Grid, Column, Container, Loadmask } from '@sencha/ext-react-modern';
import { useState, useContext, useEffect } from "react";
import { useUser } from '../components/UserContext';
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import localitaPercorsoModel from '../models/LocalitaPercorsoRTOModel.js';
import corrispondenzeModel from '../models/CorrispondenzeModel.js'
let ideLocPrec = null;
const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);

let impegnoImpiantoByDB = [];

const storeCorrispondenze = Ext.create('Ext.data.Store', {
    //model: tipologieRTOModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Rto/LeggiListaCorrispondenzeRTO',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storeAnagraficaImpreseEstere = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiAnagraficaImpresePartnerEstere',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeLocalitaEstere = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiAnagraficaLocalitaEstere',
        reader: {
            type: 'json'
        },
        timeout: 600000
    },
    autoload: false
});
const storePercorso = Ext.create('Ext.data.Store', {
    model: localitaPercorsoModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});
var idePrcRtoSelezionato = null;

const storeOperazioniTecniche = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiaOperazioniTecniche',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storeRaccordi = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiRaccordoByLocalita',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});


function RtoPercorso(props) {
    const contextVersioneRto = useContext(VersioneRtoContext);
    const rtoSchede = contextVersioneRto.storeRtoSchede;
    const { user, switchUser } = useUser();
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere caricamento in corso..." });
    let arrayAnaLocalitaIta = [];
    let arrayAnaTipoFermata = [];
    let arrayAnaOpeTec = [];
    let arrayAnaCatalog = [];
    let arrayAnaLocEstere = [];
    let arrayAnaImpreseEstere = [];
    let arrayVuoto = [];
    let arrayAnaRaccordi = [];

    const [statePanelCollapse, setStatePanelCollapse] = useState(props.collapse);

    useEffect(() => {
        setStatePanelCollapse(props.collapse);
    }, [props.collapse]);


    for (let i = 0; i < contextVersioneRto.storeLocalita.data.items.length; i++) {
        let app = { text: contextVersioneRto.storeLocalita.data.items[i].data.nomLoc, value: contextVersioneRto.storeLocalita.data.items[i].data.ideLoc };
        arrayAnaLocalitaIta.push(app);
    }

    for (let i = 0; i < contextVersioneRto.storeTipologieFermata.data.items.length; i++) {
        let app = { text: contextVersioneRto.storeTipologieFermata.data.items[i].data.sigTipPer, value: contextVersioneRto.storeTipologieFermata.data.items[i].data.ideTipFer };
        arrayAnaTipoFermata.push(app);
    }

    for (let i = 0; i < contextVersioneRto.storeOperazioniTecniche.data.items.length; i++) {
        let app = { text: contextVersioneRto.storeOperazioniTecniche.data.items[i].data.sigOpeTec, value: contextVersioneRto.storeOperazioniTecniche.data.items[i].data.sigOpeTec };
        arrayAnaOpeTec.push(app);
    }

    for (let i = 0; i < contextVersioneRto.storeInfoCatalogo.data.items.length; i++) {
        let app = { text: contextVersioneRto.storeInfoCatalogo.data.items[i].data.des_Kat_Trc, value: contextVersioneRto.storeInfoCatalogo.data.items[i].data.cod_Kat_Trc };
        arrayAnaCatalog.push(app);
    }
    

    const caricaLocalitaEstere = (flgCnf, nazOri, sigPas, app) => {  
        return new Promise(function (resolve, reject) {
            if (arrayAnaLocEstere.length === 0 && nazOri !== 4 && flgCnf === true && (sigPas === 'O' || sigPas === 'D')) {
                app.up().setMasked({
                    xtype: 'loadmask',
                    message: '<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Attendere caricamento località estere in corso...</span></div>'
                });

                storeAnagraficaImpreseEstere.clearData();
                storeAnagraficaImpreseEstere.removeAll();
                storeAnagraficaImpreseEstere.load({
                    params: { ideCli: rtoSchede.data.items[0].data.rto.ideCli },
                    callback: (records, operation, success) => {
                        arrayAnaImpreseEstere = [];
                        if (success) {
                            for (let i = 0; i < records.length; i++) {
                                let app = { text: records[i].data.nom_Agz, value: records[i].data.ide_Agz };
                                arrayAnaImpreseEstere.push(app);
                            }
                            Ext.ComponentQuery.query('#cmbImpresaPartner')[0].setOptions(arrayAnaImpreseEstere);
                            storeLocalitaEstere.clearData();
                            storeLocalitaEstere.removeAll();
                            storeLocalitaEstere.load({
                                params: { ideRTO: rtoSchede.data.items[0].data.rto.ideRTO },
                                callback: function (records, operation, success) {
                                     app.up().setMasked(false);
                                    arrayAnaLocEstere = [];
                                    if (success) {
                                        for (let i = 0; i < records.length; i++) {
                                            if (records[i].data.ideLocPCS !== -1) {
                                                let app = { text: records[i].data.nomLocPCS, value: records[i].data.ideLocPCS };
                                                arrayAnaLocEstere.push(app);
                                            }
                                        }
                                        Ext.ComponentQuery.query('#cmbLocEstera')[0].setOptions(arrayAnaLocEstere);
                                    }
                                    resolve();
                                }
                            });
                        }
                    }
                });
            }
            else {
                Ext.ComponentQuery.query('#cmbImpresaPartner')[0].setOptions(arrayVuoto);
                Ext.ComponentQuery.query('#cmbLocEstera')[0].setOptions(arrayVuoto);
                Ext.ComponentQuery.query('#cmbImpresaPartner')[0].setOptions(arrayAnaImpreseEstere);
                Ext.ComponentQuery.query('#cmbLocEstera')[0].setOptions(arrayAnaLocEstere);
                resolve();
            }
        })
    };    
    const caricaDati = () => {       
        setStateLoadMask({ hidden: false, message: "Attendere caricamento in corso..." });
        caricaPercorso();           
    }

   

    const caricaPercorso = () => {
        Ext.Ajax.request({
            url: 'Rto/LeggiPercorsoRTO',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                for (let i = 0; i < returnObj.length ; i++){
                    returnObj[i].flgCfn = returnObj[i].flgCfn;
                    returnObj[i].flgServizio = returnObj[i].flgFlg.indexOf("S") !== -1;
                    returnObj[i].flgManovra = returnObj[i].flgFlg.indexOf("M") !== -1;
                    returnObj[i].flgLegame = returnObj[i].flgFlg.indexOf("L") !== -1;
                }
                storePercorso.loadData(returnObj);
                Ext.ComponentQuery.query('#gridPercorso')[0].setStore(storePercorso);
                props.aggiornaStateTabPeriodicita(false);
                setStateLoadMask({ hidden: true, message: "Attendere caricamento in corso..." });
            }
        });
    }

    if (props.modificheTabPercorso === true) {
        caricaPercorso();
    }
    
    useEffect(() => {  
            caricaDati();
    }, [rtoSchede.data.items[0].data.rto.ideRTO]);  


    function onAggiungiPunPercClick(grid, info) {
        var store = grid.getStore();
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = Ext.create(localitaPercorsoModel, {});
            record.data.sigPas = "F";
            record.data.opeTec = "";
            record.data.prgPrc = info.record.data.prgPrc + 1;
            store.insert(info.cell.row._recordIndex + 1, record);
            rowediting.startEdit(record, 2);
        }
    }

    function onModificaPunPercClick(grid, info) {
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 2);
        }
    }

    function onModificaCorrispondenze(grid, info) {
        idePrcRtoSelezionato = info.record.data.idePrcRTO;

            storeCorrispondenze.clearData();
            storeCorrispondenze.removeAll();
            storeCorrispondenze.load({
                params: {
                    idePerRto: info.record.data.idePrcRTO
                }
            });

            var dialog = Ext.create({
                xtype: 'dialog',
                title: 'Corrispondenze',
                layout: 'fit',
                closable: "true",
                tools: [
                    {
                        iconCls: 'x-fa fa-plus', tooltip: 'Nuovo', handler: function (btn, e) {
                            //'this' is now the button
                            var grid = Ext.ComponentQuery.query('#gridCorrispondenze')[0];
                            var store = grid.getStore();
                            var rowediting = grid.getPlugin('rowedit');
                            if (rowediting.editing === false) {
                                var record = Ext.create(corrispondenzeModel, {});
                                store.insert(0, record);
                                rowediting.startEdit(0, 0);
                            }
                        }
                    },
                    {
                        iconCls: 'x-fa fa-check', tooltip: 'Salva', handler: function (btn, e) {
                            setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso..." });
                            let corrispondenzaJson = [];
                            for (var i = 0; i < storeCorrispondenze.getCount(); i++) {
                                corrispondenzaJson.push(Ext.encode(storeCorrispondenze.data.items[i].data));
                            }
                            Ext.Ajax.request({
                                url: 'Rto/InserisciCorrispondenze',
                                method: 'POST',
                                params: {
                                    ideUte: user.ideUte,
                                    ipAddress: "",
                                    idePrcRto: idePrcRtoSelezionato,
                                    corrispondenzaDes: corrispondenzaJson
                                },
                                callback: function (records, success, operation) {
                                    if (success) {
                                        var returnObj = Ext.decode(operation.responseText);
                                        //modifica gestione errore
                                        if (returnObj.resp == "KO") {
                                            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                            Ext.Msg.alert('Inserimento corrispondenze fallito', returnObj.msgErr)
                                        }
                                        else {
                                            dialog.destroy();
                                            caricaPercorso();
                                            Ext.toast({ message: 'Corrispondenze create', timeout: 4000 });
                                        }
                                    }
                                    else {
                                        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                        Ext.Msg.alert('Inserimento corrispondenze fallito', 'Inserimento corrispondenze fallito');
                                    }
                                }
                            })
                        }
                    }

                ],
                items: [
                    {
                        xtype: 'formpanel',
                        cls: 'PnlSectionL1',
                        ref: form => form = (form),
                        items: [
                            {
                                xtype: 'grid',
                                itemId: "gridCorrispondenze",
                                markDirty: true,
                                flex: 1,
                                minHeight: '200',
                                height: '200',
                                scrollable: true,
                                selectable: { checkbox: false, mode: 'single' },
                                store: storeCorrispondenze,
                                plugins: {
                                    gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" },
                                },
                                itemConfig: {
                                    viewModel: true
                                },

                                columns: [
                                    {
                                        text: "Minuti",
                                        flex: 1,
                                        dataIndex: "temCrp",
                                        filter: 'string',
                                        editor: {
                                            xtype: 'numberfield', triggerAction: 'all'
                                        }
                                    },
                                    {
                                        text: "Tipo",
                                        dataIndex: "ideTipCrp",
                                        flex: 1,
                                        filter: 'string',
                                        editor: {
                                            xtype: 'selectfield', triggerAction: 'all', mode: 'local', options:
                                                [
                                                    { text: 'Prima', value: 1 },
                                                    { text: 'Dopo', value: 2 }
                                                ]
                                            ,
                                            allowBlank: false
                                        }
                                    },

                                    {
                                        text: "Treno",
                                        flex: 1,
                                        dataIndex: "nomTreCrp",
                                        filter: 'string',
                                        editor: {
                                            xtype: 'textfield', triggerAction: 'all'
                                        }
                                    },
                                    {
                                        editable: false,
                                        text: "Azioni",
                                        flex: 1,
                                        cell: {

                                            tools: {

                                                aggiungiCorrispondenza: {

                                                    handler: function (btn, e) {
                                                        //'this' is now the button
                                                        var grid = Ext.ComponentQuery.query('#gridCorrispondenze')[0];
                                                        var store = grid.getStore();
                                                        var rowediting = grid.getPlugin('rowedit');
                                                        if (rowediting.editing === false) {
                                                            var record = Ext.create(corrispondenzeModel, {});
                                                            store.insert(0, record);
                                                            rowediting.startEdit(0, 0);
                                                        }
                                                    },

                                                    bind: {

                                                        iconCls: "x-fa fa-plus",

                                                        tooltip: 'Aggiungi',

                                                    }
                                                },

                                                eliminaCorrispondenza: {

                                                    handler: function (btn, e) {
                                                        Ext.ComponentQuery.query('#gridCorrispondenze')[0].getStore().remove(e.record);
                                                    },

                                                    bind: {

                                                        iconCls: "x-fa fa-trash",

                                                        tooltip: 'Elimina'
                                                    }
                                                }


                                            }
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            });

            dialog.show();
        
    }

    function onEliminaPunPercClick(grid, info) {
        if (Ext.ComponentQuery.query('#gridPercorso')[0].getStore().getRange().length > 1) {
            Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione del punto percorso?", function (button) {

                if (button === 'yes') {
                    setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso..." });
                    var percorsoByDB = null;
                    Ext.Ajax.request({
                        url: 'Rto/LeggiPercorsoRTO',
                        method: 'GET',
                        params: {
                            ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                        },
                        callback: function (resp, success, operation) {
                            percorsoByDB = Ext.decode(operation.responseText);
                            if (percorsoByDB.find(per => per.idePrcRTO === info.record.data.idePrcRTO) != null) {
                                Ext.Ajax.request({
                                    url: 'Rto/EliminaPuntoPercorsoRTO',
                                    method: 'POST',
                                    params: {
                                        ideUte: user.ideUte,
                                        ipAddress: "",
                                        idePrcRTO: info.record.data.idePrcRTO,
                                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                                        ideLoc: info.record.data.ideLoc
                                    },
                                    callback: function (records, success, operation) {
                                        if (success) {
                                            var returnObj = Ext.decode(operation.responseText);
                                            //modifica gestione errore
                                            if (returnObj.resp == "KO") {
                                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                                Ext.Msg.alert('Elimina punto percorso fallito', returnObj.msgErr)
                                            }
                                            else {                          
                                                Ext.toast({ message: 'Punto Percorso eliminato', timeout: 4000 });
                                                props.RefreshRto();
                                                props.aggiornaStateModifica(true);
                                            }
                                        }
                                        else {
                                            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                            Ext.Msg.alert('Elimina punto percorso fallito', 'Elimina punto percorso fallito');
                                        }
                                    }
                                });
                            }
                            else {                    
                                Ext.ComponentQuery.query('#gridPercorso')[0].getStore().remove(info.record);
                                Ext.toast({ message: 'Punto Percorso eliminato', timeout: 4000 });
                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                            }
                        }
                    })

                }
            })
        }
        else {
            Ext.toast({ message: 'Impossibile eliminare', timeout: 4000 });
        }
    }

    function caricamentoImpegnoImpianto() {
        return new Promise(function (resolve, reject) {
            setStateLoadMask({ hidden: false, message: "Caricamento impegno impianto in corso...", ricarica: false });
            Ext.Ajax.request({
                url: 'Rto/GetImpegnoImpianto',
                method: 'GET',
                params: {
                    ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                },
                callback: function (resp, success, operation) {
                    impegnoImpiantoByDB = [];
                    impegnoImpiantoByDB = Ext.decode(operation.responseText);
                    setStateLoadMask({ hidden: true, message: "Caricamento localita disponibili in corso...", ricarica: false });
                    resolve();
                }
            })
        })
    }

    return (
        <Container cls="container-spinner" height="100%" width="100%" layout="fit" >  
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoDet"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
            <Panel
                title="Percorso"
                scrollable
                titleCollapse={true}
                iconCls="x-fa fa-road"
                collapsible={{ collapsed: statePanelCollapse, direction: 'top' }}
                cls='PnlSectionL1 iconPanelsRTO'
                >

                <Panel layout="hbox" >
                    <Panel layout="vbox" flex={1} cls="column-in-panel">
                        <Grid
                            itemId="gridPercorso"
                            minHeight={300}
                            maxHeight={500}
                            markDirty={true}
                            flex={1}
                            cls="custom-grid"
                            scrollable
                            selectable={{ checkbox: false, mode: 'single' }}
                            store={storePercorso}
                            plugins={{
                                gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" },
                            }}
                            itemConfig={{
                                viewModel: true
                            }}
                            listeners={{
                                beforeedit: function (editor, context, eOpts) {
                                    if (!props.inEdit)
                                        return false;
                                    Ext.ComponentQuery.query('#gridPercorso')[0].setMinHeight(Ext.ComponentQuery.query('#gridPercorso')[0]._minHeight + 50);
                                    caricaLocalitaEstere(context.record.data.flgCfn, rtoSchede.data.items[0].data.rto.ideNazOri, context.record.data.sigPas,this).then(function () {
                                        caricamentoImpegnoImpianto().then(function () {
                                            var presenzaManovre = ((impegnoImpiantoByDB.manovreImpegnoImpianto.find(man => man.idE_PRC_RTO === context.record.data.idePrcRTO) != undefined));
                                            var presenzaLegami = ((impegnoImpiantoByDB.legamiLocalita.find(leg => leg.idE_PRC_RTO === context.record.data.idePrcRTO && (leg.legamE_IMPOSTO === 'No' || leg.legamE_IMPOSTO === null)) != undefined));
                                            var presenzaServizi = ((impegnoImpiantoByDB.serviziInfrastrutturaLocalita.find(ser => ser.idE_PRC_RTO === context.record.data.idePrcRTO) != undefined));
                                            ideLocPrec = context.record.data.ideLoc;
                                            let arraySelezione = [];
                                            if (context.record.data.opeTec !== undefined)
                                                arraySelezione = context.record.data.opeTec.split(' ');
                                            storeOperazioniTecniche.clearData();
                                            storeOperazioniTecniche.removeAll();
                                            storeOperazioniTecniche.load({
                                                params: {
                                                    ideRto: rtoSchede.data.items[0].data.rto.ideRTO
                                                },
                                                callback: function () {
                                                    Ext.ComponentQuery.query('#cmbOpTec')[0].setStore(storeOperazioniTecniche);
                                                    Ext.ComponentQuery.query('#cmbOpTec')[0].setValue(arraySelezione);
                                                    if (context.record.data.sigPas === 'F' && rtoSchede.data.items[0].data.rto.desTipTra !== "Catalogo") {
                                                        Ext.ComponentQuery.query('#nSosta')[0].enable();
                                                    }
                                                    else {
                                                        Ext.ComponentQuery.query('#nSosta')[0].disable();
                                                    }
                                                    if (rtoSchede.data.items[0].data.rto.ideTipTra === 3 && (context.record.data.sigPas === 'O' || context.record.data.sigPas === 'D')) {
                                                        Ext.ComponentQuery.query('#cmbRaccordo')[0].enable();
                                                        arrayAnaRaccordi = [];
                                                        storeRaccordi.clearData();
                                                        storeRaccordi.removeAll();
                                                        storeRaccordi.load({
                                                            params: {
                                                                idloc: context.record.data.ideLoc
                                                            },
                                                            callback: function () {
                                                                for (let i = 0; i < storeRaccordi.data.length; i++) {
                                                                    let app = { text: storeRaccordi.data.items[i].data.etichetta, value: storeRaccordi.data.items[i].data.valore };
                                                                    arrayAnaRaccordi.push(app);
                                                                }
                                                                Ext.ComponentQuery.query('#cmbRaccordo')[0].setOptions(arrayAnaRaccordi);
                                                                if (rtoSchede.data.items[0].data.rto.ideNazOri !== 4 && context.record.data.ideLoc !== undefined && contextVersioneRto.storeLocalita.findRecord("ideLoc", context.record.data.ideLoc, 0, false, false, true).data.flgCfn === 1 && (context.record.data.sigPas === 'O' || context.record.data.sigPas === 'D')) {
                                                                    Ext.ComponentQuery.query('#cmbLocEstera')[0].enable();
                                                                    Ext.ComponentQuery.query('#cmbImpresaPartner')[0].enable();
                                                                }
                                                                else {
                                                                    Ext.ComponentQuery.query('#cmbLocEstera')[0].disable();
                                                                    Ext.ComponentQuery.query('#cmbImpresaPartner')[0].disable();
                                                                }


                                                                if (rtoSchede.data.items[0].data.rto.desTipTra === "Catalogo" && context.record.data.sigPas !== 'O' && context.record.data.sigPas !== 'D') {
                                                                    Ext.ComponentQuery.query('#cmbPas')[0].disable();
                                                                    Ext.ComponentQuery.query('#timeOraArr')[0].disable();
                                                                    Ext.ComponentQuery.query('#textOffArr')[0].disable();
                                                                    Ext.ComponentQuery.query('#timeOraPar')[0].disable();
                                                                    Ext.ComponentQuery.query('#textOffPar')[0].disable();
                                                                    Ext.ComponentQuery.query('#cmbLocIta')[0].disable();
                                                                    Ext.ComponentQuery.query('#cmbTipoFermata')[0].disable();
                                                                    Ext.ComponentQuery.query('#cmbCat')[0].disable();
                                                                    Ext.ComponentQuery.query('#checkAltraRete')[0].disable();
                                                                    Ext.ComponentQuery.query('#checkFac')[0].disable();
                                                                    Ext.ComponentQuery.query('#txtNote')[0].enable();
                                                                }
                                                                else if (rtoSchede.data.items[0].data.rto.desTipTra === "Catalogo" && (context.record.data.sigPas === 'O' || context.record.data.sigPas === 'D')) {
                                                                    Ext.ComponentQuery.query('#cmbPas')[0].disable();
                                                                    Ext.ComponentQuery.query('#timeOraArr')[0].disable();
                                                                    Ext.ComponentQuery.query('#textOffArr')[0].disable();
                                                                    Ext.ComponentQuery.query('#timeOraPar')[0].disable();
                                                                    Ext.ComponentQuery.query('#textOffPar')[0].disable();
                                                                    Ext.ComponentQuery.query('#cmbLocIta')[0].disable();
                                                                    Ext.ComponentQuery.query('#cmbTipoFermata')[0].disable();
                                                                    Ext.ComponentQuery.query('#cmbCat')[0].disable();

                                                                    if (rtoSchede.data.items[0].data.rto.ideNazOri === 0 && context.record.data.flgCfn === true && presenzaLegami === false && presenzaManovre === false && presenzaServizi === false)
                                                                        Ext.ComponentQuery.query('#checkAltraRete')[0].enable();
                                                                    else {
                                                                        Ext.ComponentQuery.query('#checkAltraRete')[0].disable();
                                                                        Ext.ComponentQuery.query('#checkAltraRete')[0].setValue(false);
                                                                    }
                                                                    Ext.ComponentQuery.query('#checkFac')[0].disable();
                                                                    Ext.ComponentQuery.query('#txtNote')[0].enable();
                                                                }
                                                                else if (rtoSchede.data.items[0].data.rto.desTipTra !== "Catalogo" && context.record.data.sigPas === 'O') {
                                                                    Ext.ComponentQuery.query('#cmbPas')[0].disable();
                                                                    Ext.ComponentQuery.query('#timeOraArr')[0].disable();
                                                                    Ext.ComponentQuery.query('#textOffArr')[0].disable();
                                                                    Ext.ComponentQuery.query('#timeOraPar')[0].disable();
                                                                    Ext.ComponentQuery.query('#textOffPar')[0].disable();
                                                                    Ext.ComponentQuery.query('#cmbLocIta')[0].disable();
                                                                    Ext.ComponentQuery.query('#cmbTipoFermata')[0].disable();
                                                                    Ext.ComponentQuery.query('#cmbCat')[0].disable();
                                                                    if (rtoSchede.data.items[0].data.rto.ideNazOri === 0 && context.record.data.flgCfn === true && presenzaLegami === false && presenzaManovre === false && presenzaServizi === false)
                                                                        Ext.ComponentQuery.query('#checkAltraRete')[0].enable();
                                                                    else {
                                                                        Ext.ComponentQuery.query('#checkAltraRete')[0].disable();
                                                                        Ext.ComponentQuery.query('#checkAltraRete')[0].setValue(false);
                                                                    }
                                                                    Ext.ComponentQuery.query('#checkFac')[0].disable();
                                                                    Ext.ComponentQuery.query('#txtNote')[0].enable();
                                                                }
                                                                else if (rtoSchede.data.items[0].data.rto.desTipTra !== "Catalogo" && context.record.data.sigPas === 'D') {
                                                                    Ext.ComponentQuery.query('#cmbPas')[0].disable();
                                                                    Ext.ComponentQuery.query('#timeOraArr')[0].disable();
                                                                    Ext.ComponentQuery.query('#textOffArr')[0].enable();
                                                                    Ext.ComponentQuery.query('#timeOraPar')[0].disable();
                                                                    Ext.ComponentQuery.query('#textOffPar')[0].disable();
                                                                    Ext.ComponentQuery.query('#cmbLocIta')[0].disable();
                                                                    Ext.ComponentQuery.query('#cmbTipoFermata')[0].disable();
                                                                    Ext.ComponentQuery.query('#cmbCat')[0].disable();
                                                                    if (rtoSchede.data.items[0].data.rto.ideNazOri === 0 && context.record.data.flgCfn === true && presenzaLegami === false && presenzaManovre === false && presenzaServizi === false)
                                                                        Ext.ComponentQuery.query('#checkAltraRete')[0].enable();
                                                                    else {
                                                                        Ext.ComponentQuery.query('#checkAltraRete')[0].disable();
                                                                        Ext.ComponentQuery.query('#checkAltraRete')[0].setValue(false);
                                                                    }
                                                                    Ext.ComponentQuery.query('#checkFac')[0].disable();
                                                                    Ext.ComponentQuery.query('#txtNote')[0].enable();
                                                                }
                                                                else if (rtoSchede.data.items[0].data.rto.desTipTra !== "Catalogo" && context.record.data.sigPas !== 'O' && context.record.data.sigPas !== 'D') {
                                                                    Ext.ComponentQuery.query('#cmbPas')[0].enable();
                                                                    Ext.ComponentQuery.query('#timeOraArr')[0].enable();
                                                                    Ext.ComponentQuery.query('#textOffArr')[0].enable();
                                                                    Ext.ComponentQuery.query('#timeOraPar')[0].enable();
                                                                    Ext.ComponentQuery.query('#textOffPar')[0].enable();
                                                                    Ext.ComponentQuery.query('#cmbLocIta')[0].enable();
                                                                    Ext.ComponentQuery.query('#cmbTipoFermata')[0].enable();
                                                                    Ext.ComponentQuery.query('#cmbCat')[0].enable();
                                                                    Ext.ComponentQuery.query('#checkFac')[0].enable();
                                                                    Ext.ComponentQuery.query('#txtNote')[0].enable();
                                                                }
                                                            }
                                                        });
                                                    }
                                                    else {
                                                        Ext.ComponentQuery.query('#cmbRaccordo')[0].disable();
                                                        if (rtoSchede.data.items[0].data.rto.ideNazOri !== 4 && context.record.data.ideLoc !== undefined && contextVersioneRto.storeLocalita.findRecord("ideLoc", context.record.data.ideLoc, 0, false, false, true).data.flgCfn === 1 && (context.record.data.sigPas === 'O' || context.record.data.sigPas === 'D')) {
                                                            Ext.ComponentQuery.query('#cmbLocEstera')[0].enable();
                                                            Ext.ComponentQuery.query('#cmbImpresaPartner')[0].enable();
                                                        }
                                                        else {
                                                            Ext.ComponentQuery.query('#cmbLocEstera')[0].disable();
                                                            Ext.ComponentQuery.query('#cmbImpresaPartner')[0].disable();
                                                        }


                                                        if (rtoSchede.data.items[0].data.rto.desTipTra === "Catalogo" && context.record.data.sigPas !== 'O' && context.record.data.sigPas !== 'D') {
                                                            Ext.ComponentQuery.query('#cmbPas')[0].disable();
                                                            Ext.ComponentQuery.query('#timeOraArr')[0].disable();
                                                            Ext.ComponentQuery.query('#textOffArr')[0].disable();
                                                            Ext.ComponentQuery.query('#timeOraPar')[0].disable();
                                                            Ext.ComponentQuery.query('#textOffPar')[0].disable();
                                                            Ext.ComponentQuery.query('#cmbLocIta')[0].disable();
                                                            Ext.ComponentQuery.query('#cmbTipoFermata')[0].disable();
                                                            Ext.ComponentQuery.query('#cmbCat')[0].disable();
                                                            Ext.ComponentQuery.query('#checkAltraRete')[0].disable();
                                                            Ext.ComponentQuery.query('#checkFac')[0].disable();
                                                            Ext.ComponentQuery.query('#txtNote')[0].enable();
                                                        }
                                                        else if (rtoSchede.data.items[0].data.rto.desTipTra === "Catalogo" && (context.record.data.sigPas === 'O' || context.record.data.sigPas === 'D')) {
                                                            Ext.ComponentQuery.query('#cmbPas')[0].disable();
                                                            Ext.ComponentQuery.query('#timeOraArr')[0].disable();
                                                            Ext.ComponentQuery.query('#textOffArr')[0].disable();
                                                            Ext.ComponentQuery.query('#timeOraPar')[0].disable();
                                                            Ext.ComponentQuery.query('#textOffPar')[0].disable();
                                                            Ext.ComponentQuery.query('#cmbLocIta')[0].disable();
                                                            Ext.ComponentQuery.query('#cmbTipoFermata')[0].disable();
                                                            Ext.ComponentQuery.query('#cmbCat')[0].disable();

                                                            if (rtoSchede.data.items[0].data.rto.ideNazOri === 0 && context.record.data.flgCfn === true && presenzaLegami === false && presenzaManovre === false && presenzaServizi === false)
                                                                Ext.ComponentQuery.query('#checkAltraRete')[0].enable();
                                                            else {
                                                                Ext.ComponentQuery.query('#checkAltraRete')[0].disable();
                                                                Ext.ComponentQuery.query('#checkAltraRete')[0].setValue(false);
                                                            }
                                                            Ext.ComponentQuery.query('#checkFac')[0].disable();
                                                            Ext.ComponentQuery.query('#txtNote')[0].enable();
                                                        }
                                                        else if (rtoSchede.data.items[0].data.rto.desTipTra !== "Catalogo" && context.record.data.sigPas === 'O') {
                                                            Ext.ComponentQuery.query('#cmbPas')[0].disable();
                                                            Ext.ComponentQuery.query('#timeOraArr')[0].disable();
                                                            Ext.ComponentQuery.query('#textOffArr')[0].disable();
                                                            Ext.ComponentQuery.query('#timeOraPar')[0].disable();
                                                            Ext.ComponentQuery.query('#textOffPar')[0].disable();
                                                            Ext.ComponentQuery.query('#cmbLocIta')[0].disable();
                                                            Ext.ComponentQuery.query('#cmbTipoFermata')[0].disable();
                                                            Ext.ComponentQuery.query('#cmbCat')[0].disable();
                                                            if (rtoSchede.data.items[0].data.rto.ideNazOri === 0 && context.record.data.flgCfn === true && presenzaLegami === false && presenzaManovre === false && presenzaServizi === false)
                                                                Ext.ComponentQuery.query('#checkAltraRete')[0].enable();
                                                            else {
                                                                Ext.ComponentQuery.query('#checkAltraRete')[0].disable();
                                                                Ext.ComponentQuery.query('#checkAltraRete')[0].setValue(false);
                                                            }
                                                            Ext.ComponentQuery.query('#checkFac')[0].disable();
                                                            Ext.ComponentQuery.query('#txtNote')[0].enable();
                                                        }
                                                        else if (rtoSchede.data.items[0].data.rto.desTipTra !== "Catalogo" && context.record.data.sigPas === 'D') {
                                                            Ext.ComponentQuery.query('#cmbPas')[0].disable();
                                                            Ext.ComponentQuery.query('#timeOraArr')[0].disable();
                                                            Ext.ComponentQuery.query('#textOffArr')[0].enable();
                                                            Ext.ComponentQuery.query('#timeOraPar')[0].disable();
                                                            Ext.ComponentQuery.query('#textOffPar')[0].disable();
                                                            Ext.ComponentQuery.query('#cmbLocIta')[0].disable();
                                                            Ext.ComponentQuery.query('#cmbTipoFermata')[0].disable();
                                                            Ext.ComponentQuery.query('#cmbCat')[0].disable();
                                                            if (rtoSchede.data.items[0].data.rto.ideNazOri === 0 && context.record.data.flgCfn === true && presenzaLegami === false && presenzaManovre === false && presenzaServizi === false)
                                                                Ext.ComponentQuery.query('#checkAltraRete')[0].enable();
                                                            else {
                                                                Ext.ComponentQuery.query('#checkAltraRete')[0].disable();
                                                                Ext.ComponentQuery.query('#checkAltraRete')[0].setValue(false);
                                                            }
                                                            Ext.ComponentQuery.query('#checkFac')[0].disable();
                                                            Ext.ComponentQuery.query('#txtNote')[0].enable();
                                                        }
                                                        else if (rtoSchede.data.items[0].data.rto.desTipTra !== "Catalogo" && context.record.data.sigPas !== 'O' && context.record.data.sigPas !== 'D') {
                                                            Ext.ComponentQuery.query('#cmbPas')[0].enable();
                                                            Ext.ComponentQuery.query('#timeOraArr')[0].enable();
                                                            Ext.ComponentQuery.query('#textOffArr')[0].enable();
                                                            Ext.ComponentQuery.query('#timeOraPar')[0].enable();
                                                            Ext.ComponentQuery.query('#textOffPar')[0].enable();
                                                            Ext.ComponentQuery.query('#cmbLocIta')[0].enable();
                                                            Ext.ComponentQuery.query('#cmbTipoFermata')[0].enable();
                                                            Ext.ComponentQuery.query('#cmbCat')[0].enable();
                                                            Ext.ComponentQuery.query('#checkFac')[0].enable();
                                                            Ext.ComponentQuery.query('#txtNote')[0].enable();
                                                        }
                                                    }

                                                }
                                            });

                                        })
                                    })
                               
                                },
                                edit: function (grid, location) {
                                    Ext.ComponentQuery.query('#gridPercorso')[0].setMinHeight(Ext.ComponentQuery.query('#gridPercorso')[0]._minHeight - 50);
                                    var percorsoByDB = null;
                                    var oraPartenzaRTO = rtoSchede.data.items[0].data.rto.oraPar;
                                    if (location.record.data.sigPas === 'O')
                                        location.record.data.idePas = 1;
                                    else if (location.record.data.sigPas === 'D')
                                        location.record.data.idePas = 2;
                                    else if (location.record.data.sigPas === 'T')
                                        location.record.data.idePas = 3;
                                    else if (location.record.data.sigPas === 'F')
                                        location.record.data.idePas = 4;
                                    if (typeof location.record.data.oraArr === 'object') {
                                        if (location.record.data.oraArr !== null)
                                            var timeArr = ('0' + location.record.data.oraArr.getHours()).slice(-2) + ':' + ('0' + location.record.data.oraArr.getMinutes()).slice(-2);
                                        else
                                            var timeArr = null;
                                        location.record.data.oraArr = timeArr;
                                    }
                                    else {
                                        location.record.data.oraArr = null;
                                    }
                                    if (typeof location.record.data.oraPar === 'object') {
                                        if (location.record.data.oraPar !== null)
                                            var timePar = ('0' + location.record.data.oraPar.getHours()).slice(-2) + ':' + ('0' + location.record.data.oraPar.getMinutes()).slice(-2);
                                        else
                                            var timePar = null;
                                        location.record.data.oraPar = timePar;
                                    }
                                    else {
                                        location.record.data.oraPar = null;
                                    }
                                    var arrayOp = [];
                                    if (location.record.data.opeTec.length > 0) {
                                        for (var i = 0; i < location.record.data.opeTec.length; i++) {
                                            arrayOp.push(contextVersioneRto.storeOperazioniTecniche.findRecord("sigOpeTec", location.record.data.opeTec[i], 0, false, false, true).data.ideOpeTec);
                                        }
                                    }
                                    location.record.data.opeTec = arrayOp.toString();

                                    //controllo validita
                                    Ext.Ajax.request({
                                        url: 'Rto/ValidazioneOrarioPuntoPercorso',
                                        method: 'POST',
                                        params: {
                                            puntoPercorsoDes: Ext.encode(location.record.data),
                                            ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                                            oraParRTO: oraPartenzaRTO
                                        },
                                        callback: function (records, success, operation) {
                                            if (success) {
                                                var returnObj = Ext.decode(operation.responseText);
                                                //modifica gestione errore
                                                if (returnObj.resp === "KO") {
                                                    Ext.toast({ message: returnObj.msgErr, timeout: 4000 });
                                                    location.record.reject();
                                                }
                                                else {
                                                    Ext.Ajax.request({
                                                        url: 'Rto/LeggiPercorsoRTO',
                                                        method: 'GET',
                                                        params: {
                                                            ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                                        },
                                                        callback: function (resp, success, operation) {
                                                            setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso..." });
                                                            const idPuntoPercorso = location.record.data.idePrcRTO;
                                                            percorsoByDB = Ext.decode(operation.responseText);

                                                            if (percorsoByDB.find(puntPer => puntPer.idePrcRTO === idPuntoPercorso) == null) {
                                                                var puntoPercorsoNewObject = location.record.data;

                                                                var puntoPercorsoNewJson = Ext.encode(puntoPercorsoNewObject);
                                                                Ext.Ajax.request({
                                                                    url: 'Rto/InserisciPuntoPercorsoRTO',
                                                                    method: 'POST',
                                                                    params: {
                                                                        ideUte: user.ideUte,
                                                                        ipAddress: "",
                                                                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                                                                        puntoPercorsoDes: puntoPercorsoNewJson,
                                                                        oraParRTO: oraPartenzaRTO
                                                                    },
                                                                    callback: function (records, success, operation) {
                                                                        if (success) {
                                                                            var returnObj = Ext.decode(operation.responseText);
                                                                            //modifica gestione errore
                                                                            if (returnObj.resp == "KO") {
                                                                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                                                                Ext.Msg.alert('Inserimento punto percorso fallito', returnObj.msgErr)
                                                                            }
                                                                            else {
                                                                                Ext.toast({ message: 'Punto Percorso creato', timeout: 4000 });
                                                                                props.RefreshRto();
                                                                                props.aggiornaStateModifica(true);
                                                                            }
                                                                        }
                                                                        else {
                                                                            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                                                            Ext.Msg.alert('Inserimento punto percorso fallito', 'Inserimento punto percorso fallito');
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            else {

                                                                var puntoPercorsoObject = location.record.data;
                                                                puntoPercorsoObject.oraArr = (Ext.ComponentQuery.query('#timeOraArr')[0]._value != null && Ext.ComponentQuery.query('#timeOraArr')[0]._value != "") ? (('0' + Ext.ComponentQuery.query('#timeOraArr')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#timeOraArr')[0]._value.getMinutes()).slice(-2)) : null;
                                                                puntoPercorsoObject.oraPar = (Ext.ComponentQuery.query('#timeOraPar')[0]._value != null && Ext.ComponentQuery.query('#timeOraPar')[0]._value != "") ? (('0' + Ext.ComponentQuery.query('#timeOraPar')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#timeOraPar')[0]._value.getMinutes()).slice(-2)) : null;
                                                                var puntoPercorsoJson = Ext.encode(puntoPercorsoObject);
                                                                Ext.Ajax.request({
                                                                    url: 'Rto/ModificaPuntoPercorsoRTO',
                                                                    method: 'POST',
                                                                    params: {
                                                                        ideUte: user.ideUte,
                                                                        ipAddress: "",
                                                                        puntoPercorsoDes: puntoPercorsoJson,
                                                                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                                                                        oraParRTO: oraPartenzaRTO,
                                                                        ideLocPrec: ideLocPrec
                                                                    },
                                                                    callback: function (records, success, operation) {
                                                                        if (success) {
                                                                            var returnObj = Ext.decode(operation.responseText);
                                                                            //modifica gestione errore
                                                                            if (returnObj.resp === "KO") {
                                                                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                                                                Ext.Msg.alert('Modifica punto percorso fallita', returnObj.msgErr);
                                                                                location.record.reject();
                                                                            }
                                                                            else {
                                                                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                                                                Ext.toast({ message: 'Punto Percorso modificato', timeout: 4000 });
                                                                                props.RefreshRto();
                                                                                props.aggiornaStateModifica(true);
                                                                            }
                                                                        }
                                                                        else {
                                                                            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                                                            Ext.Msg.alert('Modifica punto percorso fallita', 'Modifica punto percorso fallita');
                                                                            location.record.reject();
                                                                        }

                                                                    }
                                                                });
                                                            }
                                                        }
                                                    })

                                                }
                                            }
                                            else {
                                                Ext.Msg.alert('Controllo validita punto percorso fallito', 'Controllo validita punto percorso fallito');
                                                location.record.reject();
                                            }
                                        }
                                    })

                                },
                                canceledit: function (editor, context) {
                                    caricaPercorso();

                                }
                            }}

                        >
                            <Column width={100} itemId='actionPercorso' editable={false} text="Azioni" hidden={!props.inEdit}
                                cell={{

                                    tools: {

                                        aggiungiPercorso: {

                                            handler: onAggiungiPunPercClick.bind(this),

                                            bind: {

                                                iconCls: "x-fa fa-plus",

                                                tooltip: 'Aggiungi',

                                                hidden: '{(record.sigPas == "D")}'
                                            }

                                        },

                                        modificaPercorso: {

                                            handler: onModificaPunPercClick.bind(this),

                                            bind: {

                                                iconCls: "x-fa fa-edit",

                                                tooltip: 'Modifica'
                                            }

                                        },

                                        eliminaPercorso: {

                                            handler: onEliminaPunPercClick.bind(this),

                                            bind: {

                                                iconCls: "x-fa fa-trash",

                                                tooltip: 'Elimina',

                                                hidden: '{((record.sigPas) == "O") || ((record.sigPas) == "D")}',
                                            }

                                        }



                                    }

                                }}
                            />
                            <Column width={20} cell={{
                                bind: {
                                    //cls: '{(record.sigPas == "O") ? "origine" : "")}'
                                    cls: '{(record.sigPas == "O") ? "origine" : ((record.sigPas == "D") ? "destino" : ((record.sigPas == "F") ? "fermata" : "transito"))}'
                                }
                            }} />
                            <Column dataIndex="sigPas" width={60} filter='string' editor={{
                                xtype: 'selectfield', editable: true, itemId: 'cmbPas', triggerAction: 'all', mode: 'local', options:
                                    [
                                        { text: 'O', value: 'O' },
                                        { text: 'D', value: 'D' },
                                        { text: 'F', value: 'F' },
                                        { text: 'T', value: 'T' }
                                    ]
                                ,
                                allowBlank: false,
                                listeners: {
                                    change: function (combo, value) {
                                        caricamentoImpegnoImpianto().then(function () {
                                                var presenzaManovre = ((impegnoImpiantoByDB.manovreImpegnoImpianto.find(man => man.idE_PRC_RTO === combo._validationRecord.data.idePrcRTO) != undefined));
                                                var presenzaLegami = ((impegnoImpiantoByDB.legamiLocalita.find(leg => leg.idE_PRC_RTO === combo._validationRecord.data.idePrcRTO && (leg.legamE_IMPOSTO === 'No' || leg.legamE_IMPOSTO === null)) != undefined));
                                                var presenzaServizi = ((impegnoImpiantoByDB.serviziInfrastrutturaLocalita.find(ser => ser.idE_PRC_RTO === combo._validationRecord.data.idePrcRTO) != undefined));
                                                if (value === 'F' && rtoSchede.data.items[0].data.rto.ideTipTra !== "Merci" && rtoSchede.data.items[0].data.rto.ideTipTra !== "Non commerciale") {
                                                    Ext.ComponentQuery.query('#cmbTipoFermata')[0].enable();
                                                }
                                                else {
                                                    Ext.ComponentQuery.query('#cmbTipoFermata')[0].disable();
                                                    Ext.ComponentQuery.query('#cmbTipoFermata')[0].setValue("");
                                                    Ext.ComponentQuery.query('#checkFac')[0].setValue("");
                                                }
                                            if ((value === 'O' || value === 'D') && rtoSchede.data.items[0].data.rto.ideNazOri === 0 && combo._validationRecord.data.flgCfn === true && presenzaManovre === false && presenzaLegami === false && presenzaServizi === false) {
                                                    Ext.ComponentQuery.query('#checkAltraRete')[0].enable();
                                                }
                                                else {
                                                    Ext.ComponentQuery.query('#checkAltraRete')[0].disable();
                                                    Ext.ComponentQuery.query('#checkAltraRete')[0].setValue(false);
                                                }
                                        })
                                    },
                                    select: function (combo, value) {
                                        caricamentoImpegnoImpianto().then(function () {
                                                var presenzaManovre = ((impegnoImpiantoByDB.manovreImpegnoImpianto.find(man => man.idE_PRC_RTO === combo._validationRecord.data.idePrcRTO) != undefined));
                                                var presenzaLegami = ((impegnoImpiantoByDB.legamiLocalita.find(leg => leg.idE_PRC_RTO === combo._validationRecord.data.idePrcRTO && (leg.legamE_IMPOSTO === 'No' || leg.legamE_IMPOSTO === null)) != undefined));
                                                var presenzaServizi = ((impegnoImpiantoByDB.serviziInfrastrutturaLocalita.find(ser => ser.idE_PRC_RTO === combo._validationRecord.data.idePrcRTO) != undefined));
                                                if (value.data.text === 'F' && rtoSchede.data.items[0].data.rto.ideTipTra !== "Merci" && rtoSchede.data.items[0].data.rto.ideTipTra !== "Non commerciale") {
                                                    Ext.ComponentQuery.query('#cmbTipoFermata')[0].enable();
                                                }
                                                else {
                                                    Ext.ComponentQuery.query('#cmbTipoFermata')[0].disable();
                                                    Ext.ComponentQuery.query('#cmbTipoFermata')[0].setValue("");
                                                    Ext.ComponentQuery.query('#checkFac')[0].setValue("");
                                                }
                                            if ((value.data.text === 'O' || value.data.text === 'D') && rtoSchede.data.items[0].data.rto.ideNazOri === 0 && combo._validationRecord.data.flgCfn === true && presenzaManovre === false && presenzaLegami === false && presenzaServizi === false) {
                                                    Ext.ComponentQuery.query('#checkAltraRete')[0].enable();
                                                }
                                                else {
                                                    Ext.ComponentQuery.query('#checkAltraRete')[0].disable();
                                                    Ext.ComponentQuery.query('#checkAltraRete')[0].setValue(false);
                                                }
                                        })
                                    }
                                }

                            }} />
                            <Column text="Arriva" width={100} dataIndex="oraArr" filter='string' editor={{
                                xtype: 'timefield', itemId: 'timeOraArr', maxLength: 5, format: 'H:i',
                                picker: {
                                    meridiem: false,
                                    alignPMInside: true
                                }, triggerAction: 'all'

                            }} />
                            <Column text="Offset Arr." width={120} dataIndex="ofsArr" filter='string' editor={{
                                xtype: 'textfield', triggerAction: 'all', itemId: 'textOffArr'

                            }} />
                            <Column text="Sosta" width={120} dataIndex="minPas" filter='number' editor={{
                                xtype: 'numberfield', triggerAction: 'all', itemId: 'nSosta'
                            }} />
                            <Column text="Parte" width={100} dataIndex="oraPar" filter='string' editor={{
                                xtype: 'timefield', itemId: 'timeOraPar', maxLength:5, format: 'H:i',
                                picker: {
                                    meridiem: false,
                                    alignPMInside: true
                                }, triggerAction: 'all'

                            }} />
                            <Column text="Offeset Par." width={120} dataIndex="ofsPar" filter='string' editor={{
                                xtype: 'textfield', itemId: 'textOffPar'

                            }} />
                            <Column text="Localita" width={250} renderer={function (value, metaData, records, view) {
                                return contextVersioneRto.storeLocalita.findRecord("ideLoc", value, 0, false, false, true) != null ? contextVersioneRto.storeLocalita.findRecord("ideLoc", value, 0, false, false, true).data.nomLoc : metaData.data.nomLoc;
                            }} editor={{
                                xtype: 'combobox', itemId: 'cmbLocIta', triggerAction: 'all', options: arrayAnaLocalitaIta, mode: 'local', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true
                                }} filter='string' dataIndex="ideLoc" />
                            <Column text="Tipo Fermata" dataIndex="ideTipFer" width={150} renderer={function (value, metaData, records, view) {
                                return contextVersioneRto.storeTipologieFermata.findRecord("ideTipFer", value, 0, false, false, true) != null ? contextVersioneRto.storeTipologieFermata.findRecord("ideTipFer", value, 0, false, false, true).data.sigTipPer : "";
                            }} editor={{
                                xtype: 'combobox', itemId: 'cmbTipoFermata', triggerAction: 'all', mode: 'local', options: arrayAnaTipoFermata,
                                listeners: {
                                    change: function (combo, value) {
                                        if (value === 3 || value === 4 || value === 5 || value === 6) {
                                            Ext.ComponentQuery.query('#checkFac')[0].enable();
                                        }
                                        else {
                                            Ext.ComponentQuery.query('#checkFac')[0].disable();
                                            Ext.ComponentQuery.query('#checkFac')[0].setValue("");
                                        }
                                    },
                                    select: function (combo, value) {
                                        if (value.data.value === 3 || value.data.value === 4 || value.data.value === 5 || value.data.value === 6) {
                                            Ext.ComponentQuery.query('#checkFac')[0].enable();
                                        }
                                        else {
                                            Ext.ComponentQuery.query('#checkFac')[0].disable();
                                            Ext.ComponentQuery.query('#checkFac')[0].setValue("");
                                        }
                                    }
                                }
                                }} filter='string' />
                            <Column text="Raccordo" dataIndex="idE_RCR" renderer={function (value, metaData, records, view) {
                                return metaData.data.noM_RCR;
                            }} width={250} editor={{
                                xtype: 'combobox', itemId: 'cmbRaccordo', triggerAction: 'all', mode: 'local', options: arrayAnaRaccordi
                            }} filter='string'  />  
                            <Column width={75} text='Fac' dataIndex='flgFac' renderer={function (value, metaData, records, view) {
                                return "";
                            }} editor={{
                                xtype: 'checkbox',
                                itemId: 'checkFac'
                            }}
                                cell={{

                                    tools: {
                                        prova: {

                                            bind: {

                                                iconCls: "x-fa fa-check",

                                                hidden: '{!(record.flgFac)}',

                                                tooltip: 'Facoltativa'
                                            }

                                        }

                                    }

                                }}
                            />
                            <Column width={250} text="Op. Tecniche" dataIndex="opeTec"   editor={{
                                xtype: 'combobox', itemId: 'cmbOpTec', triggerAction: 'all', mode: 'local', valueField:"sigOpeTec", displayField:"sigOpeTec", multiSelect: true
                            }} filter='string' />
                            <Column text="Catalogo" width={150} dataIndex="rifKat" editor={{
                                xtype: 'combobox', itemId: 'cmbCat', triggerAction: 'all', mode: 'local', options: arrayAnaCatalog
                            }} filter='string' />
                            <Column width={50} tooltip='Transita per altra rete' text='<span class="x-fa fa-flag"/>' dataIndex='transitaPerAltraRete' renderer={function (value, metaData, records, view) {
                                return "";
                            }} editor={{
                                xtype: 'checkbox',
                                itemId: 'checkAltraRete'
                            
                            }}
                                cell={{

                                    tools: {
                                        prova: {

                                            bind: {

                                                iconCls: "x-fa fa-check",

                                                hidden: '{!(record.transitaPerAltraRete)}',

                                                tooltip: 'Transita per altra rete'
                                            }

                                        }

                                    }

                                }}
                            />
                            <Column dataIndex="ide_Agz" text="Impresa Partner" width={250} renderer={function (value, metaData, records, view) {
                                if (storeAnagraficaImpreseEstere.data.items.length > 0)
                                    return (storeAnagraficaImpreseEstere.findRecord("ide_Agz", value, 0, false, false, true) != null && value !== -1) ? storeAnagraficaImpreseEstere.findRecord("ide_Agz", value, 0, false, false, true).data.nom_Agz : "";
                                else
                                    return metaData.data.nom_Agz;
                            }} editor={{
                                xtype: 'combobox', itemId: 'cmbImpresaPartner', typeAhead: 'true', mode: 'local', triggerAction: 'all', options: arrayAnaImpreseEstere
                            }} filter='string' />
                            <Column dataIndex="ide_Loc_Pcs" text="Localita estera" width={250} renderer={function (value, metaData, records, view) {
                                if (storeLocalitaEstere.data.items.length > 0)
                                    return (storeLocalitaEstere.findRecord("ideLocPCS", value, 0, false, false, true) != null && value !== -1) ? storeLocalitaEstere.findRecord("ideLocPCS", value, 0, false, false, true).data.nomLocPCS : "";
                                else
                                    return metaData.data.nom_Loc_Pcs;
                            }} editor={{
                                xtype: 'combobox', typeAhead: true, itemId: 'cmbLocEstera', mode: 'local', hideTrigger: true, options: arrayAnaLocEstere
                            }} filter='string' />
                                <Column dataIndex="giO_PER" text='<span class="x-far fa-calendar-check"/>' width={75} filter='string' />
                            <Column filter='string' text='<span class="x-fa fa-train-tram"/>' width={75} 
                                    cell={{
                                        tools: {
                                            transitaEstera: {

                                                bind: {

                                                    iconCls: "x-fa fa-flag",
                                                
                                                    hidden: '{!((record.flgFlg) === "B")}',
                                                    //hidden: '{Ext.String.indexOf(record.flgFlg, "B") !== -1}',

                                                    tooltip: 'Transita per altra rete'
                                                }

                                            },
                                            servizi: {

                                                bind: {

                                                    iconCls: "x-fa fa-charging-station",

                                                    hidden: '{!((record.flgServizio) == true)}',
                                                    //hidden: '{Ext.String.indexOf(record.flgFlg, "B") !== -1}',

                                                    tooltip: 'Presenti Servizi II'
                                                }

                                            },
                                            manovre: {

                                                bind: {

                                                    iconCls: "x-fa fa-shuffle",

                                                    hidden: '{!((record.flgManovra) == true)}',
                                                    //hidden: '{Ext.String.indexOf(record.flgFlg, "B") !== -1}',

                                                    tooltip: 'Presenti Manovre II'
                                                }

                                            },
                                            legami: {

                                                bind: {

                                                    iconCls: "x-fa fa-link",

                                                    hidden: '{!((record.flgLegame) == true)}',
                                                    //hidden: '{Ext.String.indexOf(record.flgFlg, "B") !== -1}',

                                                    tooltip: 'Presenti Legami II'
                                                }

                                            }

                                        }

                                    }}
                                />
                            <Column text="Note" width={250} dataIndex="notLoc" editor={{ xtype: 'textfield', itemId: 'txtNote' }} filter='string' />
                            <Column text="Corrispondenze" filter='string' width={250} renderer={function (value, metaData, record, rowIndex, colIndex, store, view) {
                                return metaData.data.crp;
                            }} editable={false}  cell={{

                                tools: {

                                    modificaCorrispondenze: {

                                        handler: onModificaCorrispondenze.bind(this), 

                                        bind: {

                                            iconCls: "x-fa fa-edit buttonMerciTool",

                                            tooltip: 'Modifica Corrispondenze',

                                            hidden: !props.inEdit || "{(record.sigPas==='O')}"
                                        }
                                    }

                                }

                            }} />
                        </Grid>
                    </Panel>
                </Panel>

            </Panel>
        </Container>
    );

}

export default RtoPercorso;
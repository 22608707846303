import react, { useState, useContext } from "react";
import { Panel, FormPanel, Container, Button, ComboBoxField, CheckBoxField, DatePickerField, FileField, Spacer, Grid, Column, FieldSet, TextField, TextAreaField } from '@sencha/ext-react-modern';
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import Calendario from "../components/Calendario/Calendario/Calendario.js";
import CalendarioSintetico from "../components/Calendario/CalendarioSintetico/CalendarioSintetico.js";
import Stazioni from "../components/Calendario/Stazioni/Stazioni.js";
import serviziInfrLocModel from '../models/ServiziInfrLocModel.js';
import { useUser } from '../components/UserContext';
import periodicitaModel from '../models/PeriodicitaIIServiziModel.js';

const Ext = window['Ext'];

const storePeriodicitaIIManovrePO = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

const storeManovreImpiantoPO = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

const PoIIManovreScheda = (props) => {
    //-----------------------
    let row = props.selectedRow.data;
    //-----------------------
    const isAutoprod = () => {
        return (row.auT_PRD === 1) ? true : false;
    }
    const bkColor = "background:#f8f8f8";

    return (
        <Container>
            <FormPanel
                layout="vbox"
                itemId="idPoIIManovreScheda"
                ref={form => form = (form)}
                docked="bottom"
                bodyStyle={bkColor + "; padding:10px;"}
            >
                <FieldSet layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} >                   
                    <TextField label="Localit&aacute" margin="0 10 0 0"  width={250} value={row.noM_LOC} disabled={true} />
                    <TextField label="Tipo Gestione" margin="0 10 0 0"  value={(row.auT_PRD === 1) ? "Autoproduzione" : "Gestore Manovra"} disabled={true} />
                    <TextField label="Gestore" margin="0 10 0 0" value={row.dsC_GESTORE} disabled={true} hidden={isAutoprod} />
                    <TextField label="Operazioni" margin="0 10 0 0" value={row.operazioni} disabled={true} hidden={isAutoprod} />
                </FieldSet>
                <FieldSet layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} >
                    <TextField label="Periodicit&aacute" value={row.periodicita} disabled={true} flex={1} />
                </FieldSet>
                <FieldSet layout="hbox" margin="0px" >
                    <Panel layout="vbox" hidden={isAutoprod} bodyStyle={bkColor}>
                        <FieldSet layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} >
                            <TextField label="MAD" value={row.maD_ORA} disabled={true} />
                            <TextField label="Offset" labelwidth={50} value={row.maD_OFF_SET} disabled={true} />
                        </FieldSet>
                        <FieldSet layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} >
                            <TextField label="Consegna materiale rotabile" value={row.crM_ORA} disabled={true} />
                            <TextField label="Offset" labelwidth={50} value={row.crM_OFF_SET} disabled={true} />
                        </FieldSet>
                        <FieldSet layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} >
                            <TextField label="Termine operazioni prelim." value={row.toP_ORA} disabled={true} />
                            <TextField label="Offset" labelwidth={50} value={row.toP_OFF_SET} disabled={true} />
                        </FieldSet>
                        <FieldSet layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} >
                            <TextField label="Trasf.materiale su bin.partenza" value={row.tmP_ORA} disabled={true} />
                            <TextField label="Offset" labelwidth={50} value={row.tmP_OFF_SET} disabled={true} />
                        </FieldSet>
                        <FieldSet layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} >
                            <TextField label="Aggancio/sgancio con recupero loc." value={row.asL_ORA} disabled={true} />
                            <TextField label="Offset" labelwidth={50} value={row.asL_OFF_SET} disabled={true} />
                        </FieldSet>
                        <FieldSet layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} >
                            <TextField label="Applicazione/rimozione fanali di coda" value={row.arF_ORA} disabled={true} />
                            <TextField label="Offset" labelwidth={50} value={row.arF_OFF_SET} disabled={true} />
                        </FieldSet>
                    </Panel>
                    <Panel layout="vbox" defaults={{ labelAlign: "left" }} bodyStyle={bkColor}>
                        <TextField label="Inizio" value={row.orA_INI} disabled={true} />
                        <TextField label="Fine" value={row.orA_FIN} disabled={true} />
                    </Panel>
                    <Panel layout="vbox" margin="0 5 0 0" defaults={{ labelAlign: "right" }} bodyStyle={bkColor}>
                        <TextField label="Offset" labelwidth={50} width={80} value={row.ofF_SET_INI} disabled={true} />
                        <TextField label="Offset" labelwidth={50} width={80} value={row.ofF_SET_FIN} disabled={true} />
                    </Panel>
                    <Panel layout="vbox" margin="0 5 0 10" defaults={{ labelAlign: "left" }} bodyStyle={bkColor}>
                        <TextField label="Area" value={row.noM_ARE_ORI} disabled={true} />
                        <TextField label="Area" value={row.noM_AREA_DES} disabled={true} />
                    </Panel>
                    <Panel layout="vbox" defaults={{ labelAlign: "left" }} bodyStyle={bkColor}>
                        <TextField label="Fascio" value={row.noM_FASCIO_ORI} disabled={true} />
                        <TextField label="Fascio" value={row.noM_FASCIO_DES} disabled={true} />
                    </Panel>
                    <Panel layout="vbox" defaults={{ labelAlign: "left" }} bodyStyle={bkColor}>
                        <TextField label="Binario" value={row.noM_BINARIO_ORI} disabled={true} />
                        <TextField label="Binario" value={row.noM_BINARIO_DES} disabled={true} />
                    </Panel>
                </FieldSet>

                <FieldSet layout="vbox" margin="0px" defaults={{ labelAlign: "left" }} >
                    <TextAreaField label="Note" value={row.note} disabled={true} flex={1} />
                </FieldSet>

                <FieldSet layout="vbox" margin="0px" defaults={{ labelAlign: "left" }} >
                    <TextField label="Massa" value={row.massa} disabled={true} />
                    <TextField label="Lunghezza" value={row.lunghezza} disabled={true} />
                </FieldSet>

                <FieldSet layout="vbox" margin="0px" >
                    <Panel layout="hbox" bodyStyle={bkColor}>
                        <CheckBoxField label="MP" labelWidth="30" checked={row.mp == 0 ? false : true} disabled={true} />
                        <TextField label="Note MP" labelAlign="left" margin="0 0 0 180" flex={1} value={row.mP_NOTE} disabled={true} />
                    </Panel>
                    <Panel layout="hbox" bodyStyle={bkColor}>
                        <CheckBoxField label="TE" labelWidth="30" margin="0 25 0 0" checked={row.te == 0 ? false : true} disabled={true} />
                        <CheckBoxField label="TS" labelWidth="30" margin="0 25 0 0" checked={row.ts == 0 ? false : true} disabled={true} />
                        <CheckBoxField label="TP" labelWidth="30" margin="0 20 0 0" checked={row.tp == 0 ? false : true} disabled={true} />
                        <TextField label="Note TE" labelAlign="left" flex={1} value={row.tE_NOTE} disabled={true} />
                    </Panel>
                    <Panel layout="hbox" bodyStyle={bkColor}>
                        <CheckBoxField label="TC" labelWidth="30" checked={row.tc == 0 ? false : true} disabled={true} />
                        <TextField label="Note TC" labelAlign="left" margin="0 0 0 180" flex={1} value={row.tC_NOTE} disabled={true} />
                    </Panel>
                </FieldSet>
            </FormPanel>
        </Container>
    );
}

function PoIIManovre(props) {

    const [isCalendarioVisible, setIsCalendarioVisible] = useState(false);

    const contextVersioneRto = useContext(VersioneRtoContext);
    const poSchede = props.storePoSchede;
    const listaPO = contextVersioneRto.listaPO;
    const { user, switchUser } = useUser();

    const caricaManovreImpianto = () => {
        storeManovreImpiantoPO.loadData(props.impegnoImpiantoPO.manovreImpegnoImpianto);
    }
    caricaManovreImpianto();


    let selectedRow = null;
    const [state, setState] = useState({ selectedRow: null })

    function onGridSelect(e) {
        var grid = Ext.ComponentQuery.query('#gridManovrePO')[0];
        var gridSelection = grid.getSelections();
        if (gridSelection.length > 0) {
            state.selectedRow = gridSelection[0];
            setState({ ...state });
        }
    }

    return (
    <Container>
        <Panel
            title="Manovre"
            titleCollapse={true}
            iconCls="x-fa fa-shuffle"
            collapsible={{ collapsed: false, direction: 'top' }}
            cls='PnlSectionPOL2 iconPanelsRTO'
        >
            <Container
                layout="vbox" fitToParent={true} cls="column-in-panel"
            >
                <Grid
                    itemId="gridManovrePO"
                    minHeight={100}
                    maxHeight={230}
                    markDirty={true}
                    flex={1}
                    scrollable
                    selectable={{ checkbox: false, mode: 'single' }}
                    onSelect={onGridSelect}                    
                    store={storeManovreImpiantoPO}
                    plugins={{
                        gridfilters: {}
                    }}
                    itemConfig={{
                        viewModel: true
                    }}
                    cls="custom-grid"
                >
                    <Column text="Localit&aacute" dataIndex="noM_LOC" filter='string' flex={1}
                    />
                    <Column text="Tipo Gestione" dataIndex="auT_PRD" flex={1} renderer={function (value) { return (value === 1) ? "Autoproduzione" : "Gestore Manovra"; }}
                    />
                    <Column text="Inizio Attivita" dataIndex="orA_INI" filter='string' flex={1}
                    />
                    <Column text="Area Origine" dataIndex="noM_ARE_ORI" filter='string' flex={1}
                    />
                    <Column text="Fine Attivita" dataIndex="orA_FIN" filter='string' flex={1}
                    />
                    <Column text="Area Destinazione" dataIndex="noM_AREA_DES" filter='string' flex={1}
                    />
                    <Column text="Periodicit&aacute" dataIndex="periodicita" filter='string' flex={1} editor={{
                        xtype: 'textfield', itemId: 'test4'

                    }} />
                   
                </Grid>

                {state.selectedRow != null && <PoIIManovreScheda selectedRow={state.selectedRow} />}

            </Container>
        </Panel>
    </Container>
    );
}

export default PoIIManovre;
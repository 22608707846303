import React from 'react';
import { Panel, Loadmask, Grid, Column, Container } from '@sencha/ext-react-modern';
import { useState, useContext, useEffect } from "react";
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import servCommModel from '../models/RtoServizioCommercialeModel.js';
import localitaModel from '../models/LocalitaModel.js';
import { useUser } from '../components/UserContext';
import AUTO_SEGUI from './../images/serviziCommerciali/svg/AUTO_SEGUI.svg';
import CARR_LETT from './../images/serviziCommerciali/svg/CARR_LETT.svg';
import CUCCETT from './../images/serviziCommerciali/svg/CUCCETT.svg';
import GARSCIO from './../images/serviziCommerciali/svg/GARSCIO.svg';
import GARSCIOFES from './../images/serviziCommerciali/svg/GARSCIOFES.svg';
import MINIBAR from './../images/serviziCommerciali/svg/MINIBAR.svg';
import NTV_CPS from './../images/serviziCommerciali/svg/NTV_CPS.svg';
import NTV_PR from './../images/serviziCommerciali/svg/NTV_PR.svg';
import NTV_REST from './../images/serviziCommerciali/svg/NTV_REST.svg';
import NTV_SC from './../images/serviziCommerciali/svg/NTV_SC.svg';
import NTV_SNK from './../images/serviziCommerciali/svg/NTV_SNK.svg';
import NTV_WIFI from './../images/serviziCommerciali/svg/NTV_WIFI.svg';
import PREN_FAC from './../images/serviziCommerciali/svg/PREN_FAC.svg';
import PREN_OBBL from './../images/serviziCommerciali/svg/PREN_OBBL.svg';
import PRI_SEC from './../images/serviziCommerciali/svg/PRI_SEC.svg';
import RISTOR from './../images/serviziCommerciali/svg/RISTOR.svg';
import SERV_L3 from './../images/serviziCommerciali/svg/SERV_L3.svg';
import SERV_TL from './../images/serviziCommerciali/svg/SERV_TL.svg';
import SLNZ from './../images/serviziCommerciali/svg/SLNZ.svg';
import TICKETLESS from './../images/serviziCommerciali/svg/TICKETLESS.svg';
import TRANO_BAR from './../images/serviziCommerciali/svg/TRANO_BAR.svg';
import TRAS_DIS from './../images/serviziCommerciali/svg/TRAS_DIS.svg';
import TRASP_BIC from './../images/serviziCommerciali/svg/TRASP_BIC.svg';
import TRASP_IND from './../images/serviziCommerciali/svg/TRASP_IND.svg';
import TRASP_PRIMA from './../images/serviziCommerciali/svg/TRASP_PRIMA.svg';
import TRASP_SECO from './../images/serviziCommerciali/svg/TRASP_SECO.svg';
const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);

var rangeDateStart = null;
var rangeDateEnd = null;
var ideLocAppOri = null;
var ideLocAppDes = null;
let arrayLocalita = [];
let prgOri = 0;
let prgDes = 0;

const storeServiziCommerciali = Ext.create('Ext.data.Store', {
    model: servCommModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

const storeLocalita = Ext.create('Ext.data.Store', {
    model: localitaModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});
const images = {
    AUTO_SEGUI,
    CARR_LETT,
    CUCCETT,
    GARSCIO,
    GARSCIOFES,
    MINIBAR,
    NTV_CPS,
    NTV_PR,
    NTV_REST,
    NTV_SC,
    NTV_SNK,
    NTV_WIFI,
    PREN_FAC,
    PREN_OBBL,
    PRI_SEC,
    RISTOR,
    SERV_L3,
    SERV_TL,
    SLNZ,
    TICKETLESS,
    TRANO_BAR,
    TRAS_DIS,
    TRASP_BIC,
    TRASP_IND,
    TRASP_PRIMA,
    TRASP_SECO
  };
const getstoreAnaServComm = () => {
    var storeAnaServComm = Ext.create('Ext.data.Store', {
        proxy: {
            method: 'GET',
            type: 'ajax',
            url: 'Anagrafiche/LeggiServiziCommerciali',
            reader: {
                type: 'json'
            }
        },
        autoload: false
    });

    storeAnaServComm.on('load', function() {
        storeAnaServComm.each(function(record) {
            
            const image =`<img width='20' height='20' src='${images[record.data.codSerCmm]}' />`
            var text = record.get('desSerCmm');
            record.set('desSerCmmWithImage', image + text);
        });
    });

    return storeAnaServComm;
}


var storeAnaServComm = getstoreAnaServComm()


function RtoServiziCommerciali(props) {
    const contextVersioneRto = useContext(VersioneRtoContext);
    const rtoSchede = contextVersioneRto.storeRtoSchede;
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
    rangeDateStart = new Date(contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", rtoSchede.data.items[0].data.rto.ideAnnFro, 0, false, false, true).data.datIniVal);
    rangeDateEnd = new Date(contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", rtoSchede.data.items[0].data.rto.ideAnnFro, 0, false, false, true).data.datFinVal);
    ideLocAppOri = rtoSchede.data.items[0].data.percorsoRTO.find(x => x.idePas === 1).ideLoc;
    ideLocAppDes = rtoSchede.data.items[0].data.percorsoRTO.find(x => x.idePas === 2).ideLoc;
    const { user, switchUser } = useUser();

    const [statePanelCollapse, setStatePanelCollapse] = useState(props.collapse);

    useEffect(() => {
        setStatePanelCollapse(props.collapse);
    }, [props.collapse]);

    Ext.Ajax.request({
        url: 'Rto/LeggiPercorsoRTO',
        method: 'GET',
        params: {
            ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
        },
        callback: function (records, success, operation) {
            var returnObj = Ext.decode(operation.responseText);
            storeLocalita.clearData();
            storeLocalita.removeAll();
            storeLocalita.loadData(returnObj);
            arrayLocalita = [];

            for (let i = 0; i < storeLocalita.data.items.length; i++) {
                let app = { text: storeLocalita.data.items[i].data.nomLoc, value: storeLocalita.data.items[i].data.ideLoc, prgPrc: storeLocalita.data.items[i].data.prgPrc };
                arrayLocalita.push(app);
            }
        }
    });


    const caricaServiziCommerciali = () => {
        Ext.Ajax.request({
            url: 'Rto/LeggiServiziCommercialiRTO',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (records, success, operation) {
                setStateLoadMask({ hidden: true, message: "Attendere caricamento in corso...", ricarica: false });
                props.aggiornaStateTabServiziComm(false);
                var returnObj = Ext.decode(operation.responseText);
                storeServiziCommerciali.loadData(returnObj);
                Ext.ComponentQuery.query('#gridServiziCommerciali')[0].setStore(storeServiziCommerciali);
            }
        });
    }
    if (stateLoadMask.ricarica === true || props.modificheTabServiziComm === true)
        caricaServiziCommerciali();



    function onEliminaServCommClick(grid, info) {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione del servizio commerciale?", function (button) {

            if (button === 'yes') {
                setStateLoadMask({ hidden: false, message: "Attendere eliminazione in corso...", ricarica: false });
                var serviziCommercialiByDB = null;
                Ext.Ajax.request({
                    url: 'Rto/LeggiServiziCommercialiRTO',
                    method: 'GET',
                    params: {
                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                    },
                    callback: function (resp, success, operation) {
                        serviziCommercialiByDB = Ext.decode(operation.responseText);
                        if (serviziCommercialiByDB.find(per => per.ideSerCmmRTO === info.record.data.ideSerCmmRTO) != null) {
                            Ext.Ajax.request({
                                url: 'Rto/EliminaSpecificaServiziCommerciali',
                                method: 'POST',
                                params: {
                                    ideUte: user.ideUte,
                                    ipAddress: "",
                                    ideSerCmmRto: info.record.data.ideSerCmmRTO
                                },
                                callback: function (records, success, operation) {
                                    setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                                    if (success) {
                                        var returnObj = Ext.decode(operation.responseText);
                                        //modifica gestione errore
                                        if (returnObj.resp == "KO") {
                                            Ext.Msg.alert('Elimina servizio commerciale fallito', returnObj.msgErr)
                                        }
                                        else {
                                            Ext.toast({ message: 'Servizio commerciale eliminato', timeout: 4000 });
                                            props.aggiornaStateModifica(true);
                                        }
                                    }
                                    else {
                                        Ext.Msg.alert('Elimina servizio commerciale fallito', 'Elimina servizio commerciale fallito');
                                    }
                                }
                            });
                        }
                        else {
                            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: false });
                            Ext.ComponentQuery.query('#gridServiziCommerciali')[0].getStore().remove(info.record);
                            Ext.toast({ message: 'Servizio commerciale eliminato', timeout: 4000 });
                        }
                    }
                })

            }
        })

    }


    function onAggiungiServCommClick(grid, info) {

        var store = grid.getStore();
        var record = Ext.create(servCommModel, {});
        record.data.ideLocPrv = ideLocAppOri;
        record.data.ideLocDsn = ideLocAppDes;
        record.data.datIni = rangeDateStart;
        record.data.datFin = rangeDateEnd;
        record.data.prgPrcPrv = prgOri;
        record.data.prgPrcDsn = prgDes;
        store.insert(info.cell.row._recordIndex + 1, record);
        grid.getPlugin('rowedit').startEdit(record, 2);
    }

    function onModificaServCommClick(grid, info) {
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 2);
        }
    }

    function onNuovoServizioClick() {
        var grid = Ext.ComponentQuery.query('#gridServiziCommerciali')[0];
        var store = grid.getStore();
        var record = Ext.create(servCommModel, {});
        record.data.ideLocPrv = ideLocAppOri;
        record.data.ideLocDsn = ideLocAppDes;
        record.data.datIni = rangeDateStart;
        record.data.datFin = rangeDateEnd;
        record.data.prgPrcPrv = prgOri;
        record.data.prgPrcDsn = prgDes;
        store.insert(grid.getStore().data.length, record);
        grid.getPlugin('rowedit').startEdit(record, 2);
    }

    function handleCollapse(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#nuovoServizioComm')[0].setHidden(true);
    };

    function handleExpand(panel, direction, eOpts) {
        if (props.inEdit === true || props.modificaServiziCommerciali === true)
            Ext.ComponentQuery.query('#nuovoServizioComm')[0].setHidden(false);
    };
    return (
        <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoDet"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
            <Panel
                title="Servizi Commerciali"
                scrollable
                titleCollapse={true}
                iconCls="x-fa fa-luggage-cart"
                collapsible={{ collapsed: statePanelCollapse, direction: 'top' }}
                onCollapse={handleCollapse}
                onExpand={handleExpand}
                cls='PnlSectionL1 iconPanelsRTO'
                tools={[
                    {
                        iconCls: 'x-fa x-fa fa-plus', tooltip: 'Nuovo', hidden: props.inEdit === false && props.modificaServiziCommerciali === false , itemId:"nuovoServizioComm", handler: onNuovoServizioClick.bind(this)
                }
                /* { type: 'menu', handler: toolHandler }*/
            ]}
        >
            <Panel layout="hbox" >
                    <Panel layout="vbox" flex={1} cls="column-in-panel">
                    <Grid                        
                            itemId="gridServiziCommerciali"
                         minHeight={100}
                         maxHeight={150}
                        markDirty={true}
                        flex={1}
                        scrollable
                        selectable={{ checkbox: false, mode: 'single' }}
                        store={storeServiziCommerciali}
                        plugins={{ gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" } }}
                        itemConfig={{
                            viewModel: true
                        }}
                        listeners={{
                            beforeedit: function (editor, context, eOpts) {
                                Ext.ComponentQuery.query('#gridServiziCommerciali')[0].setMinHeight(Ext.ComponentQuery.query('#gridServiziCommerciali')[0]._minHeight + 50);
                                if (!props.inEdit && props.modificaServiziCommerciali === false)
                                    return false;
                                Ext.Ajax.request({
                                    url: 'Rto/LeggiPercorsoRTO',
                                    method: 'GET',
                                    params: {
                                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                    },
                                    callback: function (records, success, operation) {
                                        var returnObj = Ext.decode(operation.responseText);
                                        storeLocalita.clearData();
                                        storeLocalita.removeAll();
                                        storeLocalita.loadData(returnObj);
                                        arrayLocalita = [];

                                        for (let i = 0; i < storeLocalita.data.items.length; i++) {
                                            let app = { text: storeLocalita.data.items[i].data.nomLoc, value: storeLocalita.data.items[i].data.ideLoc, prgPrc: storeLocalita.data.items[i].data.prgPrc };
                                            arrayLocalita.push(app);
                                        }
                                        for (let j = 0; j < Ext.ComponentQuery.query('#cmbLocPrvComm').length; j++) {
                                            Ext.ComponentQuery.query('#cmbLocPrvComm')[j].setOptions(arrayLocalita);
                                        }
                                        for (let k = 0; k < Ext.ComponentQuery.query('#cmbLocDsnComm').length; k++) {
                                            Ext.ComponentQuery.query('#cmbLocDsnComm')[k].setOptions(arrayLocalita);
                                        }

                                    }
                                });
                                let arraySelezione = [];
                                if (context.record.data.serCmm !== undefined)
                                    arraySelezione = context.record.data.serCmm.split(' ');
                                storeAnaServComm.clearData();
                                storeAnaServComm.removeAll();
                                storeAnaServComm.load({
                                    params: {
                                        ideSerCmmRTO: (context.record.data.ideSerCmmRTO !== null && context.record.data.ideSerCmmRTO !== undefined) ? context.record.data.ideSerCmmRTO : 0
                                    },
                                    callback: function () {
                                        Ext.ComponentQuery.query('#comboAnaSer')[0].setStore(storeAnaServComm);
                                        Ext.ComponentQuery.query('#comboAnaSer')[0].setValue(arraySelezione);
                                    }
                                });
                            },
                            edit: function (grid, location) {
                                Ext.ComponentQuery.query('#gridServiziCommerciali')[0].setMinHeight(Ext.ComponentQuery.query('#gridServiziCommerciali')[0]._minHeight - 50);
                                setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso...", ricarica: false });
                                var serviziCommercialiByDB = null;
                                Ext.Ajax.request({
                                    url: 'Rto/LeggiServiziCommercialiRTO',
                                    method: 'GET',
                                    params: {
                                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                    },
                                    callback: function (resp, success, operation) {
                                        const idServizioCommerciale = location.record.data.ideSerCmmRTO;
                                        serviziCommercialiByDB = Ext.decode(operation.responseText);

                                        if (serviziCommercialiByDB.find(servComm => servComm.ideSerCmmRTO === idServizioCommerciale) == null) {
                                            var servCommNewObject = location.record.data;
                                            servCommNewObject.datIni.setHours(servCommNewObject.datIni.getHours() + 4);
                                            servCommNewObject.datFin.setHours(servCommNewObject.datFin.getHours() + 4);
                                            servCommNewObject.prgPrcDsn = storeLocalita.findRecord("ideLoc", servCommNewObject.ideLocDsn, 0, false, false, true).data.prgPrc;
                                            servCommNewObject.prgPrcPrv = storeLocalita.findRecord("ideLoc", servCommNewObject.ideLocPrv, 0, false, false, true).data.prgPrc;
                                            servCommNewObject.serCmm = servCommNewObject.serCmm !== undefined ? servCommNewObject.serCmm.toString() : "";
                                            var servCommNewJson = Ext.encode(servCommNewObject);
                                            Ext.Ajax.request({
                                                url: 'Rto/InserisciSpecificaServiziCommerciali',
                                                method: 'POST',
                                                params: {
                                                    ideUte: user.ideUte,
                                                    ipAddress: "",
                                                    ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                                                    srvDes: servCommNewJson
                                                },
                                                callback: function (records, success, operation) {
                                                    setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                                                    if (success) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        //modifica gestione errore
                                                        if (returnObj.resp == "KO") {
                                                            Ext.Msg.alert('Inserimento servizio commerciale fallito', returnObj.msgErr)
                                                        }
                                                        else {
                                                            Ext.toast({ message: 'Servizio Commerciale creato', timeout: 4000 });
                                                            props.aggiornaStateModifica(true);
                                                        }
                                                    }
                                                    else {
                                                        Ext.Msg.alert('Inserimento servizio commerciale fallito', 'Inserimento servizio commerciale fallito');
                                                    }
                                                }
                                            })
                                        }
                                        else {
                                            var servCommModObject = location.record.data;
                                            servCommModObject.datIni.setHours(servCommModObject.datIni.getHours() + 4);
                                            servCommModObject.datFin.setHours(servCommModObject.datFin.getHours() + 4);
                                            servCommModObject.prgPrcDsn = prgDes;
                                            servCommModObject.prgPrcPrv = prgOri;
                                            servCommModObject.serCmm = servCommModObject.serCmm.toString();
                                            var servCommModJson = Ext.encode(servCommModObject);
                                            Ext.Ajax.request({
                                                url: 'Rto/ModificaSpecificaServiziCommerciali',
                                                method: 'POST',
                                                params: {
                                                    ideUte: user.ideUte,
                                                    ipAddress: "",
                                                    ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                                                    srvDes: servCommModJson
                                                },
                                                callback: function (records, success, operation) {
                                                    setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                                                    if (success) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        //modifica gestione errore
                                                        if (returnObj.resp == "KO") {
                                                            Ext.Msg.alert('Modifica servizio commerciale fallito', returnObj.msgErr)
                                                        }
                                                        else {
                                                            Ext.toast({ message: 'Servizio Commerciale modificato', timeout: 4000 });
                                                            props.aggiornaStateModifica(true);
                                                        }
                                                    }
                                                    else {
                                                        Ext.Msg.alert('Modifica servizio commerciale fallita', 'Modifica servizio commerciale fallita');
                                                    }
                                                }
                                            })
                                        }
                                    }
                                })

                            },
                            canceledit: function (editor, context) {
                                caricaServiziCommerciali();
                            }
                        }}
                    >
                        <Column width={200} text="Da" dataIndex="ideLocPrv" renderer={function (value, metaData, records, view) {
                            return arrayLocalita.find(x => x.value === value) != null ? arrayLocalita.find(x => x.value === value).text : "";
                            }} editor={{
                                xtype: 'combobox', triggerAction: 'all', mode: 'local', itemId: "cmbLocPrvComm", options: arrayLocalita, allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true
                                }} filter='string' />
                        <Column width={200} text="A" dataIndex="ideLocDsn" renderer={function (value, metaData, records, view) {
                            return arrayLocalita.find(x => x.value === value) != null ? arrayLocalita.find(x => x.value === value).text : "";
                            }} editor={{
                                xtype: 'combobox', triggerAction: 'all', itemId: "cmbLocDsnComm", mode: 'local', options: arrayLocalita, allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true,

                                }} filter='string' />
                        <Column text="Dal" width={200} type="datecolumn" formatter='date("d/m/Y")' filter='date' dataIndex="datIni" editor={{
                            xtype: 'datefield', triggerAction: 'all', allowBlank: false, itemId: "dataIniServComm", requiredMessage: "Questo campo non puo' essere vuoto", required: true, listeners: {
                                change: function (filed, newValue) {
                                    if (newValue > rangeDateEnd || newValue < rangeDateStart || newValue > Ext.ComponentQuery.query('#dataFinServComm')[0]._value) {
                                        Ext.ComponentQuery.query('#dataIniServComm')[0].setValue(null);
                                    }
                                }
                            } }} />
                        <Column text="Al" width={200} type="datecolumn" formatter='date("d/m/Y")' filter='date' dataIndex="datFin" editor={{
                            xtype: 'datefield', triggerAction: 'all', allowBlank: false, itemId: "dataFinServComm", requiredMessage: "Questo campo non puo' essere vuoto", required: true, listeners: {
                                change: function (filed, newValue) {
                                    if (newValue > rangeDateEnd || newValue < rangeDateStart || newValue < Ext.ComponentQuery.query('#dataIniServComm')[0]._value) {
                                        Ext.ComponentQuery.query('#dataFinServComm')[0].setValue(null);
                                    }
                                }
                            } }} />
                        <Column flex={1} text="Servizi Disponibili" dataIndex="serCmm" renderer={function (value, metaData, records, view) {
                            let arrayImmagini = [];
                            if (typeof value === 'string' && value !== undefined && value !== null && value.length > 0) {
                                const arrayDisponibili = value.split(' ');
                                for (var i = 0; i < arrayDisponibili.length; i++) {
                                    arrayImmagini.push(<img style={{ marginRight: '2px' }} width='25' height='25' src={require(`../images/serviziCommerciali/svg/${arrayDisponibili[i]}.svg`)} />);
                                }
                            }
                            return arrayImmagini;
                        }} editor={{
                            xtype: 'combobox', itemId: 'comboAnaSer', triggerAction: 'all', 
                            mode: 'local', 
                            valueField: 'codSerCmm', 
                            displayField: 'desSerCmm', 
                            multiSelect: true, 
                            itemTpl: new Ext.XTemplate('<div class="alignItemsInSelect">{desSerCmmWithImage}</div>'),
                        }} />
                        <Column width={100} itemId='actionServComm' editable={false} text="Azioni" hidden={!props.inEdit && !props.modificaServiziCommerciali}
                            cell={{

                                tools: {

                                    aggiungiServComm: {

                                        handler: onAggiungiServCommClick.bind(this),

                                        bind: {

                                            iconCls: "x-fa fa-plus",

                                            tooltip: 'Aggiungi'
                                        }

                                    },

                                    modificaServComm: {

                                        handler: onModificaServCommClick.bind(this),

                                        bind: {

                                            iconCls: "x-fa fa-edit",

                                            tooltip: 'Modifica'
                                        }

                                    },

                                    eliminaServiziComm: {

                                        handler: onEliminaServCommClick.bind(this),

                                        bind: {

                                            iconCls: "x-fa fa-trash",

                                            tooltip: 'Elimina'
                                        }

                                    }



                                }

                            }}

                        />
                    </Grid>
                </Panel>
                </Panel>

            </Panel>
        </Container>
    );
}

export default RtoServiziCommerciali;
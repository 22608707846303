import React from 'react';
import { useState, useContext } from "react";
import { Panel, Loadmask, Container, Button, Grid, Column } from '@sencha/ext-react-modern';
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import Calendario from "../components/Calendario/Calendario/Calendario.js";
import CalendarioSintetico from "../components/Calendario/CalendarioSintetico/CalendarioSintetico.js";
import localitaPercorsoModel from '../models/LocalitaPercorsoRTOModel.js';
import serviziInfrLocModel from '../models/ServiziInfrLocModel.js';
import { useUser } from '../components/UserContext';
import periodicitaModel from '../models/PeriodicitaIIServiziModel.js';
import { parseDate } from "../components/Calendario/Calendario/Funzioni.js";
import IMP_1 from './../images/servizi/IMP_1.svg'
import IMP_2 from './../images/servizi/IMP_2.svg'
import IMP_3 from './../images/servizi/IMP_3.svg'
import IMP_4 from './../images/servizi/IMP_4.svg'
import IMP_5 from './../images/servizi/IMP_5.svg'
import IMP_6 from './../images/servizi/IMP_6.svg'
import IMP_7 from './../images/servizi/IMP_7.svg'
import IMP_8 from './../images/servizi/IMP_8.svg'
import IMP_9 from './../images/servizi/IMP_9.svg'
import IMP_10 from './../images/servizi/IMP_10.svg'
const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);

var rangeDateStart = null;
var rangeDateEnd = null;

const storePeriodicitaIIServizi = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

const storePercorso = Ext.create('Ext.data.Store', {
    model: localitaPercorsoModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});



function onNuovaPeriodicitaIIServClick() {
    var grid = Ext.ComponentQuery.query('#gridPeriodicitaIIServizi')[0];
    var store = grid.getStore();
    var record = Ext.create(periodicitaModel, {});
    record.data.datIniPer = rangeDateStart;
    record.data.datFinPer = rangeDateEnd;
    record.data.flgSmr = true;
    record.data.ideOpv = 0;
    store.insert(grid.getStore().data.length, record);
    grid.getPlugin('rowedit').startEdit(record, 1);
}


let titolo = "";
let servizioSelezionato = null;
let localitaSelezionata = null;

const storeServiziImpianto = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});


function RtoIIServizi(props) {
    const [isCalendarioVisible, setIsCalendarioVisible] = useState(false);
    function aggiornaControllo(stato) {
        props.aggiornaStateControllo(stato);
    }
    function aggiornaStateCheck(ckPeriodi) {
        props.aggiornaStateModifica(true);
        props.aggiornaStatePeriodII(ckPeriodi);
    }
    function aggiornaStateServiziDuplicati(ckServiziDuplicati) {
        props.aggiornaStateModifica(true);
        props.aggiornaStateServII(ckServiziDuplicati);
    }
    
    const contextVersioneRto = useContext(VersioneRtoContext);
    const rtoSchede = contextVersioneRto.storeRtoSchede;
    const { user, switchUser } = useUser();
    const [calendars, setCalendars] = useState(null);
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere salvataggio in corso..." , ricarica: true});
    rangeDateStart = new Date(contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", rtoSchede.data.items[0].data.rto.ideAnnFro, 0, false, false, true).data.datIniVal);
    rangeDateEnd = new Date(contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", rtoSchede.data.items[0].data.rto.ideAnnFro, 0, false, false, true).data.datFinVal);
    let arrayLocalitaDisponibili = [];
    let arrayServiziDisponibili = [];
    let arrayAreeManovraLoc = [];
    let tipServInfApp = props.tipiServizioInfrastruttura;
    let areeApp = props.aree;
    let locApp = props.localitaDisponibili;
    const colore = "#5fa2dd"
    for (let i = 0; i < locApp.length; i++) {
        let app = { text: locApp[i].nomLoc, value: locApp[i].ideLoc, idePrc: locApp[i].idePrcRTO };
        arrayLocalitaDisponibili.push(app);
    }

    function onModificaServizioIIClick(grid, info) {
        caricamentoLocalitaDisponibili().then(function () {
            var rowediting = grid.getPlugin('rowedit');
            if (rowediting.editing === false) {
                var record = info.record;
                rowediting.startEdit(record, 2);
            }
        });
    }

    const caricaPercorso = () => {
        Ext.Ajax.request({
            url: 'Rto/LeggiPercorsoRTO',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                for (let i = 0; i < returnObj.length; i++) {
                    returnObj[i].flgCfn = returnObj[i].flgCfn;
                    returnObj[i].flgServizio = returnObj[i].flgFlg.indexOf("S") !== -1;
                    returnObj[i].flgManovra = returnObj[i].flgFlg.indexOf("M") !== -1;
                    returnObj[i].flgLegame = returnObj[i].flgFlg.indexOf("L") !== -1;
                }
                storePercorso.loadData(returnObj);
                Ext.ComponentQuery.query('#gridPercorso')[0].setStore(storePercorso);
            }
        });
    }

    function onAggiungiServizioIIClick(grid, info) {
        caricamentoLocalitaDisponibili().then(function () {
            var store = grid.getStore();
            var record = Ext.create(serviziInfrLocModel, {});
            store.insert(info.cell.row._recordIndex + 1, record);
            grid.getPlugin('rowedit').startEdit(record, 1);
        });
    }

    function caricamentoLocalitaDisponibili() {
        return new Promise(function (resolve, reject) {
            setStateLoadMask({ hidden: false, message: "Caricamento localita disponibili in corso...", ricarica: false });
            Ext.ComponentQuery.query('#cmbLocalitaServiziII')[0].disable();
            Ext.ComponentQuery.query('#cmbServiziDisponibiliII')[0].disable();
            Ext.ComponentQuery.query('#cmbAreeII')[0].disable();
            Ext.Ajax.request({
                url: 'Rto/GetAnagraficheII',
                method: 'POST',
                params: { ideRto: rtoSchede.data.items[0].data.rto.ideRTO },
                callback: function (resp, success, operation) {
                    if (success) {
                        var resptext = Ext.decode(operation.responseText);
                        tipServInfApp = Ext.encode(resptext.tipoServizioInfrastruttura);
                        areeApp = Ext.encode(resptext.areeManovraLoc);
                        Ext.Ajax.request({
                            url: 'Rto/GetLocalitaDisponibiliII',
                            method: 'POST',
                            params: { ideRto: rtoSchede.data.items[0].data.rto.ideRTO, impegnoImpianto: Ext.encode(resptext.impianti) },
                            callback: function (resp, success, operation) {
                                Ext.ComponentQuery.query('#cmbLocalitaServiziII')[0].enable();
                                if (success) {
                                    var resptextBefore = Ext.decode(operation.responseText);
                                    locApp = resptextBefore;
                                    arrayLocalitaDisponibili = [];
                                    for (let i = 0; i < resptextBefore.length; i++) {
                                        let app = { text: resptextBefore[i].nomLoc, value: resptextBefore[i].ideLoc, idePrc: resptextBefore[i].idePrcRTO };
                                        arrayLocalitaDisponibili.push(app);
                                    }
                                    Ext.ComponentQuery.query('#cmbLocalitaServiziII')[0].setOptions(arrayLocalitaDisponibili);
                                    setStateLoadMask({ hidden: true, message: "Caricamento localita disponibili in corso...", ricarica: false });
                                    resolve();
                                }
                            }
                        })
                    }
                }
            });
        })

    }


    function modificaPeriodicita(grid, info) {
        setStateLoadMask({ hidden: false, message: "Attendere caricamento periodicita in corso...", ricarica: false });
        Ext.Ajax.request({
            url: 'Rto/LeggiServiziImpegnoImpianto',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                let arrayCalendario = [];
                if (returnObj.find(x => x.idE_SER_IFS_LOC === info.record.data.idE_SER_IFS_LOC).snG_PER_ERR !== "") {
                    arrayCalendario.push(returnObj.find(x => x.idE_SER_IFS_LOC === info.record.data.idE_SER_IFS_LOC));
                    setCalendars(arrayCalendario);
                }
                else {
                    setCalendars(null);
                }
                caricaPeriodicitaServizoImpianto(info.record.data.idE_SER_IFS_LOC);
                setIsCalendarioVisible(true);
                titolo = "Periodicita servizio " + info.record.data.noM_SER_IFS + " - " + info.record.data.noM_LOC;
                servizioSelezionato = info.record.data.idE_SER_IFS_LOC;
                localitaSelezionata = info.record.data.idE_LOC;
                setStateLoadMask({ hidden: true, message: "Attendere caricamento periodicita in corso...", ricarica: false });
            }
        });

    }

    function refreshGridPeriodicitaServiII() {
        setStateLoadMask({ hidden: false, message: "Refresh griglia periodicita servizi...", ricarica: false });
        var periodicitaByDB = null;
        Ext.Ajax.request({
            url: 'Rto/GetImpegnoImpianto',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (resp, success, operation) {
                periodicitaByDB = Ext.decode(operation.responseText);
                storePeriodicitaIIServizi.loadData(periodicitaByDB.periodicitaImpegnoImpiantoServizi.filter(function (value) {
                    return value.ideParent === servizioSelezionato;
                }));
                Ext.ComponentQuery.query('#gridPeriodicitaIIServizi')[0].setStore(storePeriodicitaIIServizi);
                Ext.Ajax.request({
                    url: 'Rto/LeggiServiziImpegnoImpianto',
                    method: 'GET',
                    params: {
                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                    },
                    callback: function (records, success, operation) {
                        var returnObj = Ext.decode(operation.responseText);
                        let arrayCalendario = [];
                        if (returnObj.find(x => x.idE_SER_IFS_LOC === servizioSelezionato).snG_PER_ERR !== "") {
                            arrayCalendario.push(returnObj.find(x => x.idE_SER_IFS_LOC === servizioSelezionato));
                            setCalendars(arrayCalendario);
                        }
                        else {
                            setCalendars(null);
                        }
                        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                    }
                })
            }
        });
    }

    function onEliminaPeriodicitaServizioIIClick(grid, info) {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione della periodicita servizio?", function (button) {

            if (button === 'yes') {
                setStateLoadMask({ hidden: false, message: "Attendere eliminazione in corso...", ricarica: false });
                var periodicitaByDB = null;
                Ext.Ajax.request({
                    url: 'Rto/GetImpegnoImpianto',
                    method: 'GET',
                    params: {
                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                    },
                    callback: function (resp, success, operation) {
                        const idPeriodicita = info.record.data.idePerImpegnoImpianto;
                        periodicitaByDB = Ext.decode(operation.responseText);

                        if (periodicitaByDB.periodicitaImpegnoImpiantoServizi.find(per => per.idePerImpegnoImpianto === idPeriodicita) != null) {
                            var periodicitaJson = Ext.encode(periodicitaByDB.periodicitaImpegnoImpiantoServizi.find(per => per.idePerImpegnoImpianto === idPeriodicita));
                            Ext.Ajax.request({
                                url: 'Rto/CancellaPeriodicitaServiziImpegnoImpianto',
                                method: 'POST',
                                params: {
                                    ideUte: user.ideUte,
                                    periodicitaDes: periodicitaJson
                                },
                                callback: function (records, success, operation) {
                                    if (success) {
                                        var returnObj = Ext.decode(operation.responseText);
                                        //modifica gestione errore
                                        if (returnObj.resp === "KO") {
                                            Ext.Msg.alert('Elimina periodicita servizio fallito', returnObj.msgErr)
                                        }
                                        else {
                                            refreshGridPeriodicitaServiII();
                                            Ext.toast({ message: 'Periodicita servizio eliminata', timeout: 4000 });
                                            aggiornaControllo(true);
                                            Ext.Ajax.request({
                                                url: 'Rto/CheckPeriodicitaImpegnoImpianto',
                                                method: 'POST',
                                                params: {
                                                    ideRto: rtoSchede.data.items[0].data.rto.ideRTO
                                                },
                                                callback: function (records, success, operation) {
                                                    var returnCheck = Ext.decode(operation.responseText);
                                                    if (returnCheck.length > 0) {
                                                        aggiornaStateCheck(false);
                                                    }
                                                    else {
                                                        aggiornaStateCheck(true);
                                                    }
                                                }
                                            });
                                        }
                                    }
                                    else {
                                        Ext.Msg.alert('Elimina periodicita servizio fallito', 'Elimina periodicita servizio fallito');
                                        setStateLoadMask({ hidden: true, message: "Attendere eliminazione in corso...", ricarica: false });
                                    }
                                }
                            });
                        }
                        else {
                            Ext.ComponentQuery.query('#gridPeriodicitaIIServizi')[0].getStore().remove(info.record);
                            Ext.toast({ message: 'Periodicita servizio eliminata', timeout: 4000 });
                        }
                    }
                })

            }
        })

    }

    function onAggiungiPeriodicitaServizioIIClick(grid, info) {

        var store = grid.getStore();
        var record = Ext.create(periodicitaModel, {});
        record.data.datIniPer = rangeDateStart;
        record.data.datFinPer = rangeDateEnd;
        record.data.flgSmr = true;
        record.data.ideOpv = 0;
        store.insert(info.cell.row._recordIndex + 1, record);
        grid.getPlugin('rowedit').startEdit(record, 1);
    }

    function onModificaPeriodicitaServizioIIClick(grid, info) {

        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 2);
        }
    }


    const caricaServiziImpianto = () => {
        Ext.Ajax.request({
            url: 'Rto/LeggiServiziImpegnoImpianto',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                inEdit: props.inEdit
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                storeServiziImpianto.loadData(returnObj);
                Ext.ComponentQuery.query('#gridServiziImpianto')[0].setStore(storeServiziImpianto);
            }
        });
    }

    const caricaPeriodicitaServizoImpianto = (idE_SER_IFS_LOC) => {
        var periodicitaByDB = null;
        Ext.Ajax.request({
            url: 'Rto/GetImpegnoImpianto',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (resp, success, operation) {
                periodicitaByDB = Ext.decode(operation.responseText);
                storePeriodicitaIIServizi.loadData(periodicitaByDB.periodicitaImpegnoImpiantoServizi.filter(function (value) {
                    return value.ideParent === idE_SER_IFS_LOC;
                }))
            }
        })
    }

    if (stateLoadMask.ricarica === true)
        caricaServiziImpianto();

    function onNuovoServizioImpiantoClick() {
        caricamentoLocalitaDisponibili().then(function () {
            var grid = Ext.ComponentQuery.query('#gridServiziImpianto')[0];
            var store = grid.getStore();
            var record = Ext.create(periodicitaModel, {});
            store.insert(grid.getStore().data.length, record);
            grid.getPlugin('rowedit').startEdit(record, 1);
        })
       
    }

    function modificaGiorniCircolazione(grid, info) {
        var dialog = Ext.create({
            xtype: 'dialog',
            title: 'Dettaglio Giorni',
            layout: 'fit',
            width: '500px',
            closable: "true",
            listeners: {
                close: function (dialog) {
                    let serviziIIByDB = null;
                    dialog.destroy();
                    refreshGridPeriodicitaServiII();
                    aggiornaControllo(true);
                    Ext.Ajax.request({
                        url: 'Rto/CheckPeriodicitaSML',
                        method: 'POST',
                        params: {
                            idePer: servizioSelezionato,
                            ideTipo: 1
                        },
                        callback: function (records, success, operation) {
                            var returnCheck = Ext.decode(operation.responseText);
                            if (returnCheck.length > 0) {
                                Ext.Msg.alert('Attenzione', returnCheck);
                                aggiornaStateCheck(false);
                            }
                            else {
                                aggiornaStateCheck(true);
                                let hasDuplicates = false;
                                //verifico che non ci siano servizi duplicati
                                Ext.Ajax.request({
                                    url: 'Rto/LeggiServiziImpegnoImpianto',
                                    method: 'GET',
                                    params: {
                                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                    },
                                    callback: function (resp, success, operation) {
                                        serviziIIByDB = Ext.decode(operation.responseText);

                                        for (let i = 0; i < serviziIIByDB.length; i++) {
                                            if (hasDuplicates) {
                                                break;
                                            }

                                            Ext.Ajax.request({
                                                url: 'Rto/CheckServiziDuplicati',
                                                method: 'POST',
                                                async: false,
                                                params: {
                                                    idServ: serviziIIByDB[i].idE_SER_IFS_LOC
                                                },
                                                callback: function (records, success, operation) {
                                                    var returnCheckServ = Ext.decode(operation.responseText);
                                                    if (returnCheckServ.length > 0) {
                                                        Ext.Msg.alert('Attenzione servizi duplicati', returnCheckServ);
                                                        aggiornaStateServiziDuplicati(false);
                                                        props.aggiornaStateModifica(false);
                                                        hasDuplicates = true;
                                                    }
                                                }
                                            });
                                        }
                                    }
                                });
                                if (hasDuplicates === false) {
                                    props.aggiornaStateModifica(true);
                                    aggiornaStateServiziDuplicati(true);
                                }
                            }
                        }
                    });
            }
        },
            items: [
                {
                    xtype: 'formpanel',
                    cls: 'PnlSectionL1',
                    ref: form => form = (form),
                    itemId: 'formDettaglioGiorniIIServizi',
                    items: [
                        {
                            xtype: 'fieldset',
                            defaults: { labelAlign: "left" },
                            items: [
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Sempre",
                                            flex: 1,
                                            checked: info.record.data.flgSmr,
                                            margin: "5 5 5 5",
                                            name: "checkSmrIIServizi",
                                            itemId: "checkSmrIIServizi",
                                            listeners: {
                                                change: function (checkbox, newValue, oldValue, eOpts) {
                                                    if (newValue) {
                                                        Ext.ComponentQuery.query('#checkLndIIServizi')[0].disable();
                                                        Ext.ComponentQuery.query('#checkMrtIIServizi')[0].disable();
                                                        Ext.ComponentQuery.query('#checkMrcIIServizi')[0].disable();
                                                        Ext.ComponentQuery.query('#checkGvdIIServizi')[0].disable();
                                                        Ext.ComponentQuery.query('#checkVnrIIServizi')[0].disable();
                                                        Ext.ComponentQuery.query('#checkSbtIIServizi')[0].disable();
                                                        Ext.ComponentQuery.query('#checkDmnIIServizi')[0].disable();
                                                        Ext.ComponentQuery.query('#checkLavIIServizi')[0].disable();
                                                        Ext.ComponentQuery.query('#checkFesIIServizi')[0].disable();
                                                        Ext.ComponentQuery.query('#checkPtfIIServizi')[0].disable();
                                                        Ext.ComponentQuery.query('#checkPofIIServizi')[0].disable();
                                                        Ext.ComponentQuery.query('#checkSegPofIIServizi')[0].disable();
                                                        Ext.ComponentQuery.query('#checkLndIIServizi')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkMrtIIServizi')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkMrcIIServizi')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkGvdIIServizi')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkVnrIIServizi')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkSbtIIServizi')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkDmnIIServizi')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkLavIIServizi')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkFesIIServizi')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkPtfIIServizi')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkPofIIServizi')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkSegPofIIServizi')[0].setChecked(false);
                                                    }
                                                    else {
                                                        Ext.ComponentQuery.query('#checkLndIIServizi')[0].enable();
                                                        Ext.ComponentQuery.query('#checkMrtIIServizi')[0].enable();
                                                        Ext.ComponentQuery.query('#checkMrcIIServizi')[0].enable();
                                                        Ext.ComponentQuery.query('#checkGvdIIServizi')[0].enable();
                                                        Ext.ComponentQuery.query('#checkVnrIIServizi')[0].enable();
                                                        Ext.ComponentQuery.query('#checkSbtIIServizi')[0].enable();
                                                        Ext.ComponentQuery.query('#checkDmnIIServizi')[0].enable();
                                                        Ext.ComponentQuery.query('#checkLavIIServizi')[0].enable();
                                                        Ext.ComponentQuery.query('#checkFesIIServizi')[0].enable();
                                                        Ext.ComponentQuery.query('#checkPtfIIServizi')[0].enable();
                                                        Ext.ComponentQuery.query('#checkPofIIServizi')[0].enable();
                                                        Ext.ComponentQuery.query('#checkSegPofIIServizi')[0].enable();
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Lavorativi",
                                            flex: 1,
                                            checked: info.record.data.flgLav,
                                            margin: "5 5 5 5",
                                            name: "checkLavIIServizi",
                                            itemId: "checkLavIIServizi",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Lunedi",
                                            flex: 1,
                                            checked: info.record.data.flgLnd,
                                            margin: "5 5 5 5",
                                            name: "checkLndIIServizi",
                                            itemId: "checkLndIIServizi",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Festivi",
                                            flex: 1,
                                            checked: info.record.data.flgFes,
                                            margin: "5 5 5 5",
                                            name: "checkFesIIServizi",
                                            itemId: "checkFesIIServizi",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Martedi",
                                            flex: 1,
                                            checked: info.record.data.flgMrt,
                                            margin: "5 5 5 5",
                                            name: "checkMrtIIServizi",
                                            itemId: "checkMrtIIServizi",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Prefestivi",
                                            flex: 1,
                                            checked: info.record.data.flgPtf,
                                            margin: "5 5 5 5",
                                            name: "checkPtfIIServizi",
                                            itemId: "checkPtfIIServizi",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Mercoledi",
                                            flex: 1,
                                            checked: info.record.data.flgMrc,
                                            margin: "5 5 5 5",
                                            name: "checkMrcIIServizi",
                                            itemId: "checkMrcIIServizi",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Post Festivi",
                                            flex: 1,
                                            checked: info.record.data.flgPof,
                                            margin: "5 5 5 5",
                                            name: "checkPofIIServizi",
                                            itemId: "checkPofIIServizi",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Giovedi",
                                            flex: 1,
                                            checked: info.record.data.flgGvd,
                                            margin: "5 5 5 5",
                                            name: "checkGvdIIServizi",
                                            itemId: "checkGvdIIServizi",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Seguenti Post Festivi",
                                            flex: 1,
                                            checked: info.record.data.flgSegPof,
                                            margin: "5 5 5 5",
                                            name: "checkSegPofIIServizi",
                                            itemId: "checkSegPofIIServizi",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    width: '49%',
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Venerdi",
                                            flex: 1,
                                            checked: info.record.data.flgVnr,
                                            margin: "5 5 5 5",
                                            name: "checkVnrIIServizi",
                                            itemId: "checkVnrIIServizi",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    width: '49%',
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Sabato",
                                            flex: 1,
                                            checked: info.record.data.flgSbt,
                                            margin: "5 5 5 5",
                                            name: "checkSbtIIServizi",
                                            itemId: "checkSbtIIServizi",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    width: '49%',
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Domenica",
                                            flex: 1,
                                            checked: info.record.data.flgDmn,
                                            margin: "5 5 5 5",
                                            name: "checkDmnIIServizi",
                                            itemId: "checkDmnIIServizi",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            xtype: 'toolbar',
                            shadow: false,
                            cls: 'PnlSectionL1',
                            docked: 'bottom',
                            layout: { type: 'hbox', pack: 'right' },
                            items: [
                                {
                                    xtype: 'button',
                                    cls: 'appButton',
                                    text: 'RESET',
                                    handler: () =>
                                        Ext.ComponentQuery.query('#formDettaglioGiorniIIServizi')[0].reset()
                                },
                                {
                                    xtype: 'button',
                                    cls: 'appButton',
                                    text: 'OK',
                                    handler: (btn) => {
                                        const values = btn.up('formpanel').getValues();
                                        var serviziIIByDB = null;
                                        if (!((values.checkSmrIIServizi === false || values.checkSmrIIServizi === null) && (values.checkLndIIServizi === false || values.checkLndIIServizi === null) && (values.checkMrtIIServizi === false || values.checkMrtIIServizi === null) && (values.checkMrcIIServizi === false || values.checkMrcIIServizi === null) && (values.checkGvdIIServizi === false || values.checkGvdIIServizi === null) && (values.checkVnrIIServizi === false || values.checkVnrIIServizi === null) && (values.checkSbtIIServizi === false || values.checkSbtIIServizi === null) && (values.checkDmnIIServizi === false || values.checkDmnIIServizi === null) && (values.checkLavIIServizi === false || values.checkLavIIServizi === null) && (values.checkFesIIServizi === false || values.checkFesIIServizi === null) && (values.checkPtfIIServizi === false || values.checkPtfIIServizi === null) && (values.checkPofIIServizi === false || values.checkPofIIServizi === null) && (values.checkSegPofIIServizi === false || values.checkSegPofIIServizi === null))) {
                                            info.record.data.flgSmr = values.checkSmrIIServizi === true ? true : false;
                                            info.record.data.flgLnd = values.checkLndIIServizi === true ? true : false;
                                            info.record.data.flgMrt = values.checkMrtIIServizi === true ? true : false;
                                            info.record.data.flgMrc = values.checkMrcIIServizi === true ? true : false;
                                            info.record.data.flgGvd = values.checkGvdIIServizi === true ? true : false;
                                            info.record.data.flgVnr = values.checkVnrIIServizi === true ? true : false;
                                            info.record.data.flgSbt = values.checkSbtIIServizi === true ? true : false;
                                            info.record.data.flgDmn = values.checkDmnIIServizi === true ? true : false;
                                            info.record.data.flgLav = values.checkLavIIServizi === true ? true : false;
                                            info.record.data.flgFes = values.checkFesIIServizi === true ? true : false;
                                            info.record.data.flgPtf = values.checkPtfIIServizi === true ? true : false;
                                            info.record.data.flgPof = values.checkPofIIServizi === true ? true : false;
                                            info.record.data.flgSegPof = values.checkSegPofIIServizi === true ? true : false;
                                            var periodicitaServiziIIObject = info.record.data;
                                            periodicitaServiziIIObject.ideLoc = localitaSelezionata;
                                            periodicitaServiziIIObject.ideParent = servizioSelezionato;
                                            var periodicitaJson = Ext.encode(periodicitaServiziIIObject);
                                            setStateLoadMask({ hidden: false, message: "Salvataggio in corso...", ricarica: true });
                                            Ext.Ajax.request({
                                                url: 'Rto/ModificaPeriodicitaServiziImpegnoImpianto',
                                                method: 'POST',
                                                params: {
                                                    ideUte: user.ideUte,
                                                    periodicitaDes: periodicitaJson
                                                },
                                                callback: function (records, success, operation) {
                                                    if (success) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        //modifica gestione errore
                                                        if (returnObj.resp === "KO") {
                                                            Ext.Msg.alert('Modifica periodicita servizio fallita', returnObj.msgErr);
                                                        }
                                                        else {
                                                            dialog.destroy();
                                                            refreshGridPeriodicitaServiII();
                                                            Ext.toast({ message: 'Periodicita servizio modificata', timeout: 4000 });
                                                            aggiornaControllo(true);
                                                            Ext.Ajax.request({
                                                                url: 'Rto/CheckPeriodicitaSML',
                                                                method: 'POST',
                                                                params: {
                                                                    idePer: servizioSelezionato,
                                                                    ideTipo: 1
                                                                },
                                                                callback: function (records, success, operation) {
                                                                    var returnCheck = Ext.decode(operation.responseText);
                                                                    if (returnCheck.length > 0) {
                                                                        Ext.Msg.alert('Attenzione', returnCheck);
                                                                        aggiornaStateCheck(false);
                                                                    }
                                                                    else {
                                                                        aggiornaStateCheck(true);
                                                                        let hasDuplicates = false;
                                                                        //verifico che non ci siano servizi duplicati
                                                                        Ext.Ajax.request({
                                                                            url: 'Rto/LeggiServiziImpegnoImpianto',
                                                                            method: 'GET',
                                                                            params: {
                                                                                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                                                            },
                                                                            callback: function (resp, success, operation) {
                                                                                serviziIIByDB = Ext.decode(operation.responseText);

                                                                                for (let i = 0; i < serviziIIByDB.length; i++) {
                                                                                    if (hasDuplicates) {
                                                                                        break;
                                                                                    }

                                                                                    Ext.Ajax.request({
                                                                                        url: 'Rto/CheckServiziDuplicati',
                                                                                        method: 'POST',
                                                                                        async: false,
                                                                                        params: {
                                                                                            idServ: serviziIIByDB[i].idE_SER_IFS_LOC
                                                                                        },
                                                                                        callback: function (records, success, operation) {
                                                                                            var returnCheckServ = Ext.decode(operation.responseText);
                                                                                            if (returnCheckServ.length > 0) {
                                                                                                Ext.Msg.alert('Attenzione servizi duplicati', returnCheckServ);
                                                                                                aggiornaStateServiziDuplicati(false);
                                                                                                props.aggiornaStateModifica(false);
                                                                                                hasDuplicates = true;
                                                                                            }
                                                                                        }
                                                                                    });
                                                                                }
                                                                            }
                                                                        });
                                                                        if (hasDuplicates === false) {
                                                                            props.aggiornaStateModifica(true);
                                                                            aggiornaStateServiziDuplicati(true);
                                                                        }
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    }
                                                    else {
                                                        Ext.Msg.alert('Modifica periodicita servizio fallita', 'Modifica periodicita servizio fallita');
                                                        setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                    }

                                                }
                                            });
                                        }
                                        else {
                                            Ext.Msg.alert('Dettaglio Giorni', 'Scegliere almeno un giorno')
                                        }


                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        });

        dialog.show();
    }

    function onEliminaServiziIIClick(grid, info) {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione del servizio?", function (button) {

            if (button === 'yes') {
                setStateLoadMask({ hidden: false, message: "Eliminazione servizio in corso...", ricarica: false });
                var serviziIIByDB = null;
                Ext.Ajax.request({
                    url: 'Rto/LeggiServiziImpegnoImpianto',
                    method: 'GET',
                    params: {
                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                    },
                    callback: function (resp, success, operation) {
                        serviziIIByDB = Ext.decode(operation.responseText);
                        if (serviziIIByDB.find(per => per.idE_SER_IFS_LOC === info.record.data.idE_SER_IFS_LOC) !== undefined) {
                            Ext.Ajax.request({
                                url: 'Rto/DeleteImpegnoImpiantoServizi',
                                method: 'POST',
                                params: {
                                    ideUte: user.ideUte,
                                    idePerc: arrayLocalitaDisponibili.find(x => x.value === info.record.data.idE_LOC).idePrc,
                                    ideServizio: info.record.data.idE_SER_IFS,
                                    ideArea: info.record.data.iD_AREA,
                                    durata: info.record.data.durata,
                                    ideservizioII: info.record.data.idE_SER_IFS_LOC
                                },
                                callback: function (records, success, operation) {
                                    if (success) {
                                        var returnObj = Ext.decode(operation.responseText);
                                        //modifica gestione errore
                                        if (returnObj.resp == "KO") {
                                            Ext.Msg.alert('Elimina servizio fallito', returnObj.msgErr)
                                        }
                                        else {
                                            setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: true });
                                            Ext.toast({ message: 'Servizio eliminato', timeout: 4000 });
                                            caricaPercorso();
                                            aggiornaControllo(true);
                                            Ext.Ajax.request({
                                                url: 'Rto/CheckPeriodicitaImpegnoImpianto',
                                                method: 'POST',
                                                params: {
                                                    ideRto: rtoSchede.data.items[0].data.rto.ideRTO
                                                },
                                                callback: function (records, success, operation) {
                                                    var returnCheck = Ext.decode(operation.responseText);
                                                    if (returnCheck.length > 0) {
                                                        aggiornaStateCheck(false);
                                                    }
                                                    else {
                                                        aggiornaStateCheck(true);
                                                        let hasDuplicates = false;
                                                        //verifico che non ci siano servizi duplicati
                                                        Ext.Ajax.request({
                                                            url: 'Rto/LeggiServiziImpegnoImpianto',
                                                            method: 'GET',
                                                            params: {
                                                                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                                            },
                                                            callback: function (resp, success, operation) {
                                                                serviziIIByDB = Ext.decode(operation.responseText);

                                                                for (let i = 0; i < serviziIIByDB.length; i++) {
                                                                    if (hasDuplicates) {
                                                                        break;
                                                                    }

                                                                    Ext.Ajax.request({
                                                                        url: 'Rto/CheckServiziDuplicati',
                                                                        method: 'POST',
                                                                        params: {
                                                                            idServ: serviziIIByDB[i].idE_SER_IFS_LOC
                                                                        },
                                                                        callback: function (records, success, operation) {
                                                                            var returnCheckServ = Ext.decode(operation.responseText);
                                                                            if (returnCheckServ.length > 0) {
                                                                                Ext.Msg.alert('Attenzione servizi duplicati', returnCheckServ);
                                                                                aggiornaStateServiziDuplicati(false);
                                                                                props.aggiornaStateModifica(false);
                                                                                hasDuplicates = true;
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        });
                                                        if (hasDuplicates === false) {
                                                            props.aggiornaStateModifica(true);
                                                            aggiornaStateServiziDuplicati(true);
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                    }
                                    else {
                                        setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                        Ext.Msg.alert('Elimina servizio fallito', 'Elimina servizio fallito');
                                    }
                                }
                            });
                        }
                        else {
                            Ext.ComponentQuery.query('#gridServiziImpianto')[0].getStore().remove(info.record);
                            setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: true });
                            Ext.toast({ message: 'Servizio eliminato', timeout: 4000 });
                        }
                    }
                })

            }
        })

    }

    const renderListOfCalendar = (calendars) => {
        const cal = calendars.map((name, i) => <><Panel collapsible={false} key={`sintesi${i}`} cls="calendar-container">
            <br />
            <div className="periodicitaHeader">
                <CalendarioSintetico
                    giorniTreno={calendars[i].snG_PER_ERR ? calendars[i].snG_PER_ERR.split('1').length - 1 : '0'}
                    periodicitaSintetico={calendars[i].snG_GIO_SET}
                />
            </div>
            <Button cls="plus-minus-button" itemId={`calendarioExpButminII${i}`} iconCls="x-fa fa-minus" handler={function () {
                Ext.ComponentQuery.query(`#calendarioExpButPlusII${i}`)[0].show();
                this.hide();
                Ext.ComponentQuery.query(`#calendarioExpII${i}`)[0].hide();
            }}></Button>
            <Button cls="plus-minus-button" itemId={`calendarioExpButPlusII${i}`} hidden={true} iconCls="x-fa fa-plus" handler={function () {
                Ext.ComponentQuery.query(`#calendarioExpButminII${i}`)[0].show();
                this.hide();
                Ext.ComponentQuery.query(`#calendarioExpII${i}`)[0].show();
            }}></Button>
        </Panel>
            <Panel itemId={`calendarioExpII${i}`} hidden={false} collapsible={false}>
                <Calendario
                    dataInizio={calendars[i].daT_INI_VAL}
                    dataFine={calendars[i].daT_FIN_VAL}
                    periodicita={calendars[i].snG_PER_ERR}
                    dataAttProvvedimento={null}
                    periodicitaSintetico={calendars[i].snG_GIO_SET}
                    IsNonEffettuabile = {true}
                    isAttivazioneProvvedimento = {false}
                />
            </Panel>
        </>)
        return cal;
    }

    function handleCollapse(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#nuovoServizio')[0].setHidden(true);
        Ext.ComponentQuery.query('#containerServiziII')[0].setCls("container-spinner");       
    };

    function handleExpand(panel, direction, eOpts) {
        if (props.inEdit === true)
            Ext.ComponentQuery.query('#nuovoServizio')[0].setHidden(false);
        Ext.ComponentQuery.query('#containerServiziII')[0].setCls("container-spinner PnlSectionL2B"); 
    };
    const images = {
        1: IMP_1,
        2: IMP_2,
        3: IMP_3,
        4: IMP_4,
        5: IMP_5,
        6: IMP_6,
        7: IMP_7,
        8: IMP_8,
        9: IMP_9,
        10: IMP_10,
      };
    return (
        <Container itemId="containerServiziII" margin='2px 0px 2px 0px' border={true} cls="container-spinner" height="100%" width="100%" layout="fit" >
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoDet"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
        <Panel
            title="Servizi Impianto"
            titleCollapse={true}
            iconCls="x-fa fa-charging-station"
                collapsible={{ collapsed: true, direction: 'top' }}
            cls='PnlSectionL2 iconPanelsRTO'
            onCollapse={handleCollapse}
            onExpand={handleExpand}
            tools={[
                {
                    iconCls: 'x-fa x-fa fa-plus', tooltip: 'Nuovo', hidden: "true", itemId: "nuovoServizio", handler: onNuovoServizioImpiantoClick.bind(this)
                }
                /* { type: 'menu', handler: toolHandler }*/
            ]}
        >
            <Container
                    layout="vbox" itemId="containerServ" fitToParent={true} cls="column-in-panel"
            >
                    <Grid
                        itemId="gridServiziImpianto"
                        minHeight={100}
                        maxHeight={100}
                        disabled = {isCalendarioVisible}
                        markDirty={true}
                        flex={1}
                        scrollable
                        selectable={{ checkbox: false, mode: 'single' }}
                        store={storeServiziImpianto}
                        plugins={{
                            gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" },
                        }}
                        itemConfig={{
                            viewModel: true
                    }}
                    listeners={{
                        beforeedit: function (editor, context, eOpts) {
                            if (!props.inEdit)
                                return false;
                            else {
                                Ext.ComponentQuery.query('#gridServiziImpianto')[0].setMinHeight(Ext.ComponentQuery.query('#gridServiziImpianto')[0]._minHeight + 50);
                                if (context.source.type === 'doubletap')
                                    caricamentoLocalitaDisponibili().then(function () {
                                        return true;
                                    })
                                else
                                    return true;
                            }
                        },
                        edit: function (grid, location) {
                            Ext.ComponentQuery.query('#gridServiziImpianto')[0].setMinHeight(Ext.ComponentQuery.query('#gridServiziImpianto')[0]._minHeight - 50);
                            setStateLoadMask({ hidden: false, message: "Salvataggio in corso...", ricarica: false });
                            var serviziIIByDB = null;
                            Ext.Ajax.request({
                                url: 'Rto/LeggiServiziImpegnoImpianto',
                                method: 'GET',
                                params: {
                                    ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                },
                                callback: function (resp, success, operation) {
                                    const idServizioII = location.record.data.idE_SER_IFS_LOC;
                                    serviziIIByDB = Ext.decode(operation.responseText);

                                    if (serviziIIByDB.find(per => per.idE_SER_IFS_LOC === idServizioII) == null) {
                                        Ext.Ajax.request({
                                            url: 'Rto/InserisciImpegnoImpiantoSer',
                                            method: 'POST',
                                            params: {
                                                ideUte : user.ideUte,
                                                idePerc: arrayLocalitaDisponibili.find(x => x.value === location.record.data.idE_LOC).idePrc,
                                                ideServizio: location.record.data.idE_SER_IFS,
                                                ideArea: location.record.data.iD_AREA,
                                                durata: location.record.data.durata
                                            },
                                            callback: function (records, success, operation) {
                                                if (success) {
                                                    var returnObj = Ext.decode(operation.responseText);
                                                    //modifica gestione errore
                                                    if (returnObj.resp == "KO") {
                                                        Ext.Msg.alert('Inserimento servizio fallito', returnObj.msgErr)
                                                    }
                                                    else {
                                                        setStateLoadMask({ hidden: true, message: "Salvataggio in corso...", ricarica: true });
                                                        Ext.toast({ message: 'Servizio creato', timeout: 4000 });
                                                        caricaPercorso();
                                                        aggiornaControllo(true);
                                                        let hasDuplicates = false;
                                                        //verifico che non ci siano servizi duplicati
                                                        Ext.Ajax.request({
                                                            url: 'Rto/LeggiServiziImpegnoImpianto',
                                                            method: 'GET',
                                                            params: {
                                                                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                                            },
                                                            callback: function (resp, success, operation) {
                                                                serviziIIByDB = Ext.decode(operation.responseText);

                                                                for (let i = 0; i < serviziIIByDB.length; i++) {
                                                                    if (hasDuplicates) {
                                                                        break;
                                                                    }

                                                                    Ext.Ajax.request({
                                                                        url: 'Rto/CheckServiziDuplicati',
                                                                        method: 'POST',
                                                                        async: false, 
                                                                        params: {
                                                                            idServ: serviziIIByDB[i].idE_SER_IFS_LOC
                                                                        },
                                                                        callback: function (records, success, operation) {
                                                                            var returnCheckServ = Ext.decode(operation.responseText);
                                                                            if (returnCheckServ.length > 0) {
                                                                                Ext.Msg.alert('Attenzione servizi duplicati', returnCheckServ);
                                                                                aggiornaStateServiziDuplicati(false);
                                                                                props.aggiornaStateModifica(false);
                                                                                hasDuplicates = true;
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        });
                                                        if (hasDuplicates === false) {
                                                            props.aggiornaStateModifica(true);
                                                            aggiornaStateServiziDuplicati(true);
                                                        }
                                                    }
                                                }
                                                else {
                                                    setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                    Ext.Msg.alert('Inserimento servizio fallito', 'Inserimento servizio fallito');
                                                }
                                            }
                                        })
                                    }
                                    else {
                                        Ext.Ajax.request({
                                            url: 'Rto/ModificaImpegnoImpiantoServizi',
                                            method: 'POST',
                                            params: {
                                                ideUte: user.ideUte,
                                                idePerc: arrayLocalitaDisponibili.find(x => x.value === location.record.data.idE_LOC).idePrc,
                                                ideServizio: location.record.data.idE_SER_IFS,
                                                ideArea: location.record.data.iD_AREA,
                                                durata: location.record.data.durata,
                                                ideservizioII: location.record.data.idE_SER_IFS_LOC
                                            },
                                            callback: function (records, success, operation) {
                                                if (success) {
                                                    var returnObj = Ext.decode(operation.responseText);
                                                    //modifica gestione errore
                                                    if (returnObj.resp === "KO") {
                                                        Ext.Msg.alert('Modifica servizio fallita', returnObj.msgErr);
                                                        location.record.reject();
                                                    }
                                                    else {
                                                        setStateLoadMask({ hidden: true, message: "Salvataggio in corso...", ricarica: true });
                                                        Ext.toast({ message: 'Servizio modificato', timeout: 4000 });
                                                        caricaPercorso();
                                                        aggiornaControllo(true);
                                                        let hasDuplicates = false;
                                                        //verifico che non ci siano servizi duplicati
                                                        Ext.Ajax.request({
                                                            url: 'Rto/LeggiServiziImpegnoImpianto',
                                                            method: 'GET',
                                                            params: {
                                                                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                                            },
                                                            callback: function (resp, success, operation) {
                                                                serviziIIByDB = Ext.decode(operation.responseText);

                                                                for (let i = 0; i < serviziIIByDB.length; i++) {
                                                                    if (hasDuplicates) {
                                                                        break;
                                                                    }

                                                                    Ext.Ajax.request({
                                                                        url: 'Rto/CheckServiziDuplicati',
                                                                        method: 'POST',
                                                                        async: false, 
                                                                        params: {
                                                                            idServ: serviziIIByDB[i].idE_SER_IFS_LOC
                                                                        },
                                                                        callback: function (records, success, operation) {
                                                                            var returnCheckServ = Ext.decode(operation.responseText);
                                                                            if (returnCheckServ.length > 0) {
                                                                                Ext.Msg.alert('Attenzione servizi duplicati', returnCheckServ);
                                                                                aggiornaStateServiziDuplicati(false);
                                                                                props.aggiornaStateModifica(false);
                                                                                hasDuplicates = true;
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        });
                                                        if (hasDuplicates === false) {
                                                            props.aggiornaStateModifica(true);
                                                            aggiornaStateServiziDuplicati(true);
                                                        }
                                                    }
                                                }
                                                else {
                                                    Ext.Msg.alert('Modifica servizio fallita', 'Modifica servizio fallita');
                                                    location.record.reject();
                                                    setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                }

                                            }
                                        });
                                    }
                                }
                            })

                        },
                        canceledit: function (editor, context) {
                            caricaServiziImpianto();
                        }
                    }}
                    >
                        <Column itemId='actionRTOIIServizi' editable={false} text="Azioni" flex={1}
                            cell={{

                                tools: {

                                    aggiungiRTOIISERVIZI: {

                                        hidden: !props.inEdit,

                                        handler: onAggiungiServizioIIClick.bind(this),

                                        bind: {

                                            iconCls: "x-fa fa-plus",

                                            tooltip: 'Aggiungi'
                                        }

                                    },
                                    modificaRTOIISERVIZI: {

                                        hidden: !props.inEdit,

                                        handler: onModificaServizioIIClick.bind(this),

                                        bind: {

                                            iconCls: "x-fa fa-edit",

                                            tooltip: 'Modifica'
                                        }

                                    },

                                    eliminaRTOIISERVIZI: {

                                        hidden: !props.inEdit,


                                        handler: onEliminaServiziIIClick.bind(this),

                                        bind: {

                                            iconCls: "x-fa fa-trash",

                                            tooltip: 'Elimina'
                                        }

                                    }

                                }

                            }}
                        />
                    <Column flex={1} text="Localita" dataIndex="idE_LOC"  renderer={function (value, metaData, records, view) {
                            return locApp.find(x => x.ideLoc === value) !== undefined ? locApp.find(x => x.ideLoc === value).nomLoc : metaData.data.noM_LOC;
                    }} editor={{
                        xtype: 'combobox', itemId:"cmbLocalitaServiziII", triggerAction: 'all', mode: 'local', options: arrayLocalitaDisponibili, allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true,
                        listeners:
                        {
                            change: function (gridView, value, columnIndex) {
                                arrayServiziDisponibili = [];
                                arrayAreeManovraLoc = [];
                                Ext.ComponentQuery.query('#cmbServiziDisponibiliII')[0].setOptions(arrayServiziDisponibili);
                                Ext.ComponentQuery.query('#cmbAreeII')[0].setOptions(arrayAreeManovraLoc);
                                Ext.ComponentQuery.query('#cmbServiziDisponibiliII')[0].setValue("");
                                Ext.ComponentQuery.query('#cmbAreeII')[0].setValue("");
                                if (value !== "" && value !== null) {
                                    Ext.Ajax.request({
                                        url: 'Rto/GetServiziInfrastrutturaLocalita',
                                        method: 'POST',
                                        params: { ideRTO: rtoSchede.data.items[0].data.rto.ideRTO, ideLoc: value, tipoServizioInfrastruttura: tipServInfApp },
                                        callback: function (resp, success, operation) {
                                            if (success) {
                                                Ext.ComponentQuery.query('#cmbServiziDisponibiliII')[0].enable();
                                                var resptext = Ext.decode(operation.responseText);
                                                arrayServiziDisponibili = [];
                                                for (let i = 0; i < resptext.length; i++) {
                                                    let app = { text: resptext[i].noM_SER_IFS, value: resptext[i].idE_SER_IFS, fltTmp: resptext[i].flG_TMP_UTL, image: `<img width='20' height='20' src=${images[resptext[i].idE_SER_IFS]}/>` };
                                                    arrayServiziDisponibili.push(app);
                                                }
                                                Ext.ComponentQuery.query('#cmbServiziDisponibiliII')[0].setOptions(arrayServiziDisponibili);
                                            }
                                        }
                                    })
                                }

                            },
                            select: function (gridView, value, columnIndex) {
                                arrayServiziDisponibili = [];
                                arrayAreeManovraLoc = [];
                                Ext.ComponentQuery.query('#cmbServiziDisponibiliII')[0].setOptions(arrayServiziDisponibili);
                                Ext.ComponentQuery.query('#cmbAreeII')[0].setOptions(arrayAreeManovraLoc);
                                Ext.ComponentQuery.query('#cmbServiziDisponibiliII')[0].setValue("");
                                Ext.ComponentQuery.query('#cmbAreeII')[0].setValue("");
                                if (value.data.value !== "" && value.data.value !== null) {
                                    Ext.Ajax.request({
                                        url: 'Rto/GetServiziInfrastrutturaLocalita',
                                        method: 'POST',
                                        params: { ideRTO: rtoSchede.data.items[0].data.rto.ideRTO, ideLoc: value.data.value, tipoServizioInfrastruttura: tipServInfApp },
                                        callback: function (resp, success, operation) {
                                            if (success) {
                                                Ext.ComponentQuery.query('#cmbServiziDisponibiliII')[0].enable();
                                                var resptext = Ext.decode(operation.responseText);
                                                arrayServiziDisponibili = [];
                                                for (let i = 0; i < resptext.length; i++) {
                                                    let app = { text: resptext[i].noM_SER_IFS, value: resptext[i].idE_SER_IFS, fltTmp: resptext[i].flG_TMP_UTL, image: `<img width='20' height='20' src=${images[resptext[i].idE_SER_IFS]}/>` };
                                                    arrayServiziDisponibili.push(app);
                                                }
                                                Ext.ComponentQuery.query('#cmbServiziDisponibiliII')[0].setOptions(arrayServiziDisponibili);
                                            }
                                        }
                                    })
                                }

                            }
                        }
                    }} filter='string' />
                    <Column text="Servizio" dataIndex="idE_SER_IFS" flex={1} renderer={function (value, metaData, records, view) {
                            var nome = Ext.decode(props.tipiServizioInfrastruttura).find(x => x.idE_SER_IFS === value) !== undefined ? Ext.decode(tipServInfApp).find(x => x.idE_SER_IFS === value).noM_SER_IFS : metaData.data.noM_SER_IFS;
                            var stile = "display: inline-flex; align-items: center;";
                            return <div style={{ display:'inline-flex', alignItems:'center' }}><img style={{ marginRight: '5px' }} width='15' height='15' src={images[value]} /><span>{nome}</span></div>;
                    }} editor={{
                        xtype: 'combobox', itemId: 'cmbServiziDisponibiliII', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true, mode: 'local', options: arrayServiziDisponibili,
                        itemTpl: new Ext.XTemplate(`<div class="alignItemsInSelect">{image} {text}</div>`),
                        listeners:
                        {
                            change: function (gridView, value, columnIndex) {
                                Ext.ComponentQuery.query('#cmbAreeII')[0].setValue("");
                                arrayAreeManovraLoc = [];
                                Ext.ComponentQuery.query('#cmbAreeII')[0].setOptions(arrayAreeManovraLoc);
                                //if (arrayServiziDisponibili.find(x => x.value === value) === undefined || arrayServiziDisponibili.find(x => x.value === value).fltTmp === 0) {
                                //    Ext.ComponentQuery.query('#minServiziII')[0].disable();
                                //    Ext.ComponentQuery.query('#minServiziII')[0].setValue(null);
                                //}
                                //else {
                                //    Ext.ComponentQuery.query('#minServiziII')[0].enable();
                                //}
                                if (value !== "") {
                                    Ext.Ajax.request({
                                        url: 'Rto/GetAreeDisponibiliServizi',
                                        method: 'POST',
                                        params: { ideLoc: Ext.ComponentQuery.query('#cmbLocalitaServiziII')[0]._value, ideSer: value, areeManovraLoc: areeApp },
                                        callback: function (resp, success, operation) {
                                            if (success) {
                                                Ext.ComponentQuery.query('#cmbAreeII')[0].enable();
                                                var resptext = Ext.decode(operation.responseText);
                                                arrayAreeManovraLoc = [];
                                                for (let i = 0; i < resptext.length; i++) {
                                                    let app = { text: resptext[i].noM_ARE_IMT, value: resptext[i].idE_ARE_IMT };
                                                    arrayAreeManovraLoc.push(app);
                                                }
                                                let app = { text: "", value: 0 };
                                                arrayAreeManovraLoc.push(app);
                                                Ext.ComponentQuery.query('#cmbAreeII')[0].setOptions(arrayAreeManovraLoc);
                                            }
                                        }
                                    })
                                }

                            }
                        }
                        }} />
                    <Column text="Area" dataIndex="iD_AREA" flex={1} renderer={function (value, metaData, records, view) {
                            return Ext.decode(areeApp).find(x => x.idE_ARE_IMT === value) !== undefined ? Ext.decode(areeApp).find(x => x.idE_ARE_IMT === value).noM_ARE_IMT : metaData.data.noM_AREA;
                    }} editor={{
                        xtype: 'combobox', itemId: 'cmbAreeII', mode: 'local', options: arrayAreeManovraLoc
                    }} />
                    <Column text="Minuti" dataIndex="durata" filter='string' flex={1}  editor={{
                        xtype: 'numberfield', itemId: 'minServiziII', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true

                    }} />
                        <Column text="Periodicit&aacute"  renderer={function (value, metaData, record, rowIndex, colIndex, store, view) {
                            return metaData.data.periodicita;
                        }} editable={false} filter='string' flex={1} cell={{

                            tools: {

                                modificaPeriodicita: {

                                    handler: modificaPeriodicita.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-calendar buttonMerciTool",

                                        tooltip: 'Periodicita'
                                    }
                                }

                            }

                        }} />

                </Grid>
                {isCalendarioVisible && (
                    <Panel title={titolo} itemId="panelCalendarioPerServII" closable={true} listeners=
                        {
                            {
                            close: function () {
                                    setIsCalendarioVisible(false);
                                    setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: false });
                            }
                            }} tools={[
                                {
                                    iconCls: 'x-fa x-fa fa-plus', tooltip: 'Nuovo', hidden: !props.inEdit, handler: onNuovaPeriodicitaIIServClick.bind(this)
                                }
                                /* { type: 'menu', handler: toolHandler }*/
                            ]}>
                        <Grid
                            itemId="gridPeriodicitaIIServizi"
                                minHeight={100}
                                maxHeight={100}
                            markDirty={true}
                            flex={1}
                            scrollable
                            selectable={{ checkbox: false, mode: 'single' }}
                            store={storePeriodicitaIIServizi}
                            plugins={{
                                gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" },
                            }}
                            itemConfig={{
                                viewModel: true
                            }}
                            listeners={{
                                beforeedit: function (editor, context, eOpts) {
                                    if (!props.inEdit)
                                        return false;
                                },
                                edit: function (grid, location) {
                                    setStateLoadMask({ hidden: false, message: "Salvataggio in corso...", ricarica: false });
                                    var periodicitaByDB = null;
                                    var serviziIIByDB = null;
                                    var idPeriodicitaNew = null;
                                    Ext.Ajax.request({
                                        url: 'Rto/GetImpegnoImpianto',
                                        method: 'GET',
                                        params: {
                                            ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                        },
                                        callback: function (resp, success, operation) {
                                            const idPeriodicita = location.record.data.idePerImpegnoImpianto;
                                            periodicitaByDB = Ext.decode(operation.responseText);

                                            if (periodicitaByDB.periodicitaImpegnoImpiantoServizi.find(per => per.idePerImpegnoImpianto === idPeriodicita) === undefined) {
                                                var periodicitaNewObject = location.record.data;
                                                periodicitaNewObject.ideLoc = localitaSelezionata;
                                                periodicitaNewObject.ideParent = servizioSelezionato;
                                                periodicitaNewObject.datIniPer.setHours(periodicitaNewObject.datIniPer.getHours() + 4);
                                                periodicitaNewObject.datFinPer.setHours(periodicitaNewObject.datFinPer.getHours() + 4);
                                                periodicitaNewObject.flgSmr = true;
                                                periodicitaNewObject.flgLnd = false;
                                                periodicitaNewObject.flgMrt = false;
                                                periodicitaNewObject.flgMrc = false;
                                                periodicitaNewObject.flgGvd = false;
                                                periodicitaNewObject.flgVnr = false;
                                                periodicitaNewObject.flgSbt = false;
                                                periodicitaNewObject.flgDmn = false;
                                                periodicitaNewObject.flgLav = false;
                                                periodicitaNewObject.flgFes = false;
                                                periodicitaNewObject.flgPtf = false;
                                                periodicitaNewObject.flgPof = false;
                                                periodicitaNewObject.flgSegPof = false;
                                                var periodicitaNewJson = Ext.encode(periodicitaNewObject);
                                                Ext.Ajax.request({
                                                    url: 'Rto/InserisciPeriodicitaServiziImpegnoImpianto',
                                                    method: 'POST',
                                                    params: {
                                                        ideUte: user.ideUte,
                                                        dati: periodicitaNewJson
                                                    },
                                                    callback: function (records, success, operation) {
                                                        if (success) {
                                                            var returnObj = Ext.decode(operation.responseText);
                                                            //modifica gestione errore
                                                            if (returnObj.resp == "KO") {
                                                                Ext.Msg.alert('Inserimento periodicita servizio fallito', returnObj.msgErr)
                                                            }
                                                            else {
                                                                idPeriodicitaNew = returnObj.idPerServ;
                                                                setStateLoadMask({ hidden: false, message: "Attendere...", ricarica: false });
                                                                Ext.Ajax.request({
                                                                    url: 'Rto/GetImpegnoImpianto',
                                                                    method: 'GET',
                                                                    params: {
                                                                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                                                    },
                                                                    callback: function (resp, success, operation) {
                                                                        const info = new Object();
                                                                        info.record = new Object();
                                                                        info.record.data = new Object();
                                                                        const idPeriodicitaNewApp = idPeriodicitaNew;
                                                                        periodicitaByDB = Ext.decode(operation.responseText);
                                                                        info.record.data = periodicitaByDB.periodicitaImpegnoImpiantoServizi.find(per => per.idePerImpegnoImpianto === idPeriodicitaNewApp);
                                                                        setStateLoadMask({ hidden: true, message: "Attendere...", ricarica: false });
                                                                        modificaGiorniCircolazione(null, info);
                                                                    }
                                                                })
                                                            }
                                                        }
                                                        else {
                                                            Ext.Msg.alert('Inserimento periodicita servizio fallito', 'Inserimento periodicita servizio fallito');
                                                            setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                        }
                                                    }
                                                })
                                            }
                                            else {

                                                var periodicitaObject = location.record.data;
                                                periodicitaObject.datIniPer = new Date(periodicitaObject.datIniPer);
                                                periodicitaObject.datFinPer = new Date(periodicitaObject.datFinPer);
                                                periodicitaObject.datIniPer.setHours(periodicitaObject.datIniPer.getHours() + 4);
                                                periodicitaObject.datFinPer.setHours(periodicitaObject.datFinPer.getHours() + 4);
                                                periodicitaObject.ideLoc = localitaSelezionata;
                                                periodicitaObject.ideParent = servizioSelezionato;
                                                periodicitaObject.flgSmr = location.record.data.flgSmr;
                                                periodicitaObject.flgLnd = location.record.data.flgLnd;
                                                periodicitaObject.flgMrt = location.record.data.flgMrt;
                                                periodicitaObject.flgMrc = location.record.data.flgMrc;
                                                periodicitaObject.flgGvd = location.record.data.flgGvd;
                                                periodicitaObject.flgVnr = location.record.data.flgVnr;
                                                periodicitaObject.flgSbt = location.record.data.flgSbt;
                                                periodicitaObject.flgDmn = location.record.data.flgDmn;
                                                periodicitaObject.flgLav = location.record.data.flgLav;
                                                periodicitaObject.flgFes = location.record.data.flgFes;
                                                periodicitaObject.flgPtf = location.record.data.flgPtf;
                                                periodicitaObject.flgPof = location.record.data.flgPof;
                                                periodicitaObject.flgSegPof = location.record.data.flgSegPof;
                                                var periodicitaJson = Ext.encode(periodicitaObject);
                                                Ext.Ajax.request({
                                                    url: 'Rto/ModificaPeriodicitaServiziImpegnoImpianto',
                                                    method: 'POST',
                                                    params: {
                                                        ideUte: user.ideUte,
                                                        periodicitaDes: periodicitaJson
                                                    },
                                                    callback: function (records, success, operation) {
                                                        if (success) {
                                                            var returnObj = Ext.decode(operation.responseText);
                                                            //modifica gestione errore
                                                            if (returnObj.resp === "KO") {
                                                                Ext.Msg.alert('Modifica periodicita servizio fallita', returnObj.msgErr);
                                                                location.record.reject();
                                                            }
                                                            else {
                                                                refreshGridPeriodicitaServiII();
                                                                Ext.toast({ message: 'Periodicita servizio modificata', timeout: 4000 });
                                                                aggiornaControllo(true);
                                                                Ext.Ajax.request({
                                                                    url: 'Rto/CheckPeriodicitaSML',
                                                                    method: 'POST',
                                                                    params: {
                                                                        idePer: servizioSelezionato,
                                                                        ideTipo: 1
                                                                    },
                                                                    callback: function (records, success, operation) {
                                                                        var returnCheck = Ext.decode(operation.responseText);
                                                                        if (returnCheck.length > 0) {
                                                                            Ext.Msg.alert('Attenzione', returnCheck);
                                                                            aggiornaStateCheck(false);
                                                                        }
                                                                        else {
                                                                            aggiornaStateCheck(true);
                                                                            let hasDuplicates = false;
                                                                            //verifico che non ci siano servizi duplicati
                                                                            Ext.Ajax.request({
                                                                                url: 'Rto/LeggiServiziImpegnoImpianto',
                                                                                method: 'GET',
                                                                                params: {
                                                                                    ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                                                                },
                                                                                callback: function (resp, success, operation) {
                                                                                    serviziIIByDB = Ext.decode(operation.responseText);

                                                                                    for (let i = 0; i < serviziIIByDB.length; i++) {
                                                                                        if (hasDuplicates) {
                                                                                            break;
                                                                                        }

                                                                                        Ext.Ajax.request({
                                                                                            url: 'Rto/CheckServiziDuplicati',
                                                                                            method: 'POST',
                                                                                            async: false,
                                                                                            params: {
                                                                                                idServ: serviziIIByDB[i].idE_SER_IFS_LOC
                                                                                            },
                                                                                            callback: function (records, success, operation) {
                                                                                                var returnCheckServ = Ext.decode(operation.responseText);
                                                                                                if (returnCheckServ.length > 0) {
                                                                                                    Ext.Msg.alert('Attenzione servizi duplicati', returnCheckServ);
                                                                                                    aggiornaStateServiziDuplicati(false);
                                                                                                    props.aggiornaStateModifica(false);
                                                                                                    hasDuplicates = true;
                                                                                                }
                                                                                            }
                                                                                        });
                                                                                    }
                                                                                }
                                                                            });
                                                                            if (hasDuplicates === false) {
                                                                                props.aggiornaStateModifica(true);
                                                                                aggiornaStateServiziDuplicati(true);
                                                                            }
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                        }
                                                        else {
                                                            Ext.Msg.alert('Modifica periodicita servizio fallita', 'Modifica periodicita servizio fallita');
                                                            location.record.reject();
                                                            setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                        }

                                                    }
                                                });
                                            }
                                        }
                                    })

                                },
                                canceledit: function (editor, context) {
                                    refreshGridPeriodicitaServiII();
                                }
                            }}
                            >
                                <Column itemId='actionRTOPeriodicita' editable={false} text="Azioni" width={150} hidden={!props.inEdit}
                                    cell={{

                                        tools: {

                                            aggiungiPeriodicita: {

                                                handler: onAggiungiPeriodicitaServizioIIClick.bind(this),

                                                bind: {

                                                    iconCls: "x-fa fa-plus",

                                                    tooltip: 'Aggiungi'
                                                }

                                            },

                                            modificaPeriodicita: {

                                                handler: onModificaPeriodicitaServizioIIClick.bind(this),

                                                bind: {

                                                    iconCls: "x-fa fa-edit",

                                                    tooltip: 'Modifica'
                                                }

                                            },

                                            eliminaPeriodicita: {

                                                handler: onEliminaPeriodicitaServizioIIClick.bind(this),

                                                bind: {

                                                    iconCls: "x-fa fa-trash",

                                                    tooltip: 'Elimina'
                                                }

                                            }



                                        }

                                    }}
                                />
                            <Column text="Operativita" dataIndex="ideOpv" width={150} filter='string' renderer={function (value, metaData, records, view) {
                                if (value === 0)
                                    return "Si Effettua"
                                else
                                    return "Non si Effettua"
                            }} editor={{
                                xtype: 'combobox', editable: true, itemId: 'cmbOpe', triggerAction: 'all', mode: 'local', options:
                                    [
                                        { text: 'Si Effettua', value: 0 },
                                        { text: 'Non si Effettua', value: 1 }
                                    ]
                                ,
                                allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true

                            }} />
                            <Column text="Dal" width={200} type="datecolumn" formatter='date("d/m/Y")' filter='date' dataIndex="datIniPer" editor={{
                                xtype: 'datefield', triggerAction: 'all', allowBlank: false, itemId:"dataIniServiII", requiredMessage: "Questo campo non puo' essere vuoto", required: true, listeners: {
                                    change: function (filed, newValue) {
                                        if (newValue > rangeDateEnd || newValue < rangeDateStart) {
                                            Ext.ComponentQuery.query('#dataIniServiII')[0].setValue(null);
                                        }
                                    }
                                } }} />
                            <Column text="Al" width={200} type="datecolumn" formatter='date("d/m/Y")' filter='date' dataIndex="datFinPer" editor={{
                                xtype: 'datefield', triggerAction: 'all', allowBlank: false, itemId: "dataFinServiII", requiredMessage: "Questo campo non puo' essere vuoto", required: true, listeners: {
                                    change: function (filed, newValue) {
                                        if (newValue > rangeDateEnd || newValue < rangeDateStart) {
                                            Ext.ComponentQuery.query('#dataFinServiII')[0].setValue(null);
                                        }
                                    }
                                } }} />
                                <Column text="Giorni" editable={false} dataIndex="desPer" filter='string' flex={1} cell={{

                                    tools: {

                                        modificaMerci: {

                                            handler: modificaGiorniCircolazione.bind(this),

                                            bind: {

                                                iconCls: "x-fa fa-edit buttonMerciTool",

                                                tooltip: 'Modifica Giorni Circolazione',

                                                hidden: !props.inEdit
                                            }
                                        }

                                    }

                                }}  />

                        </Grid>

                        {calendars != null && renderListOfCalendar(calendars)}
                </Panel>)}
            </Container>
            </Panel>
        </Container>
    );
}

export default RtoIIServizi;
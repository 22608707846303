import React from 'react';
import { Panel, FormPanel, Container, Button, ComboBoxField, CheckBoxField, DatePickerField, TimeField, FileField, Spacer, Grid, Column, FieldSet, TextField, TextAreaField } from '@sencha/ext-react-modern';
import { useState, useContext, useEffect } from "react";
import periodoFerroviarioModel from '../models/PeriodoFerroviarioModel.js';
import { useUser } from '../components/UserContext';
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import MainRto from './Rto.js'

const Ext = window['Ext'];

var rangeDateStartProvvediamento = null;
var rangeDateEndProvvediamento = null;

const storeLotti = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Lotti/LeggiLotti',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});


function RtoRiepilogo(props) {
    const contextVersioneRto = useContext(VersioneRtoContext);
    const rtoSchede = contextVersioneRto.storeRtoSchede;
    const { user, switchUser } = useUser();
    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    var rangeDateStart = new Date(contextVersioneRto.storePeriodiFerroviari.data.items.find(pf => new Date(pf.data.datIniVal) <= (new Date()) && new Date(pf.data.datFinVal) >= (new Date())).data.datIniVal);
    var rangeDateEnd = new Date(contextVersioneRto.storePeriodiFerroviari.data.items.find(pf => new Date(pf.data.datIniVal) <= (new Date()) && new Date(pf.data.datFinVal) >= (new Date())).data.datFinVal);
    contextVersioneRto.storeCommittenti.insert(0, [{ ideCommittente: -1, nomeCommittente: "" }]);

    let origine = rtoSchede.data.items[0].data.rto.ideLocPrv
    let destinazione = rtoSchede.data.items[0].data.rto.ideLocDsn
    let categoriaIF = rtoSchede.data.items[0].data.rto.ideCatTreIF
    let statoRTO = rtoSchede.data.items[0].data.rto.ideStoRTO
    /*    let giorniRTO = rtoSchede.data.items[0].data.rto.giO_KMT*/
    let giorniRTO = props.modificaGiorniCircolazione
    let periodoRto = rtoSchede.data.items[0].data.rto.ideTipPer
    let periodo = rtoSchede.data.items[0].data.rto.ideAnnFro
    let dataProvvedimento = (rtoSchede.data.items[0].data.rto.datAtvPvv !== null && rtoSchede.data.items[0].data.rto.datAtvPvv.trim() !== '') ? new Date(rtoSchede.data.items[0].data.rto.datAtvPvv).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' }) : ""
    let tipoVco = rtoSchede.data.items[0].data.rto.ideTipVCO
    let rtoOrig = rtoSchede.data.items[0].data.rto.rtoOrig 
    let rtoCls = (rtoSchede.data.items[0].data.rto.ideRTOOrig !== null && rtoSchede.data.items[0].data.rto.ideRTOOrig !== "") ? "marginButton appButtonRTO" : "" 
    let protocolloIF = rtoSchede.data.items[0].data.rto.ptlIF
    let dataProtIF = (rtoSchede.data.items[0].data.rto.datPtlIF !== null && rtoSchede.data.items[0].data.rto.datPtlIF.trim() !== '') ? new Date(rtoSchede.data.items[0].data.rto.datPtlIF).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' }) : ""
    let numTrenoIF = rtoSchede.data.items[0].data.rto.nomTreIpr
    let protocolloRFI = rtoSchede.data.items[0].data.rto.ptlRFI
    let dataProtRFI = (rtoSchede.data.items[0].data.rto.datPtlRFI !== null && rtoSchede.data.items[0].data.rto.datPtlRFI.trim() !== '') ? new Date(rtoSchede.data.items[0].data.rto.datPtlRFI).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' }) : ""
    let committente = rtoSchede.data.items[0].data.rto.ideCommittente
    let tipoRTO = rtoSchede.data.items[0].data.rto.ideTipRTO
    let tipoTraffico = rtoSchede.data.items[0].data.rto.ideTipTra
    let accordoQuadro = rtoSchede.data.items[0].data.rto.ideAcoQrd
    let tipoTraccia = rtoSchede.data.items[0].data.rto.idE_TIP_TRC
    let lotto = rtoSchede.data.items[0].data.rto.ideLtx
    let note = rtoSchede.data.items[0].data.rto.note
    let tipoVcoRequire = (periodoRto === 1 || periodoRto === 2) ? true : false
    let dataProvvedimentoRequired = periodoRto !== 0 ? true : false
    let oraPartenza = rtoSchede.data.items[0].data.rto.oraPar
    let oraArrivo = rtoSchede.data.items[0].data.rto.oraArr
    let ideRtoOri = rtoSchede.data.items[0].data.rto.ideRTOOrig
    
    

    function onAnnullaModificaIFClick() {
        Ext.ComponentQuery.query('#buttonModificaRiepilogo')[0].setDisabled(false);
        Ext.ComponentQuery.query('#confermaRiepilogo')[0].setDisabled(true);
        Ext.ComponentQuery.query('#annullaRiepilogo')[0].setDisabled(true);
        //reimposto i valori originali
        Ext.ComponentQuery.query('#periodoRtoId')[0].setValue(rtoSchede.data.items[0].data.rto.ideTipPer);
        Ext.ComponentQuery.query('#periodoFerroviarioId')[0].setValue(rtoSchede.data.items[0].data.rto.ideAnnFro);
        Ext.ComponentQuery.query('#tipoVCOId')[0].setValue(rtoSchede.data.items[0].data.rto.ideTipVCO);
        Ext.ComponentQuery.query('#rtoOriginariaId')[0].setText(rtoSchede.data.items[0].data.rto.rtoOrig);
        Ext.ComponentQuery.query('#protocolloIFId')[0].setValue(rtoSchede.data.items[0].data.rto.ptlIF);
        Ext.ComponentQuery.query('#dataProtocolloIFId')[0].setValue(rtoSchede.data.items[0].data.rto.datPtlIF);
        Ext.ComponentQuery.query('#prtotocolloRFIId')[0].setValue(rtoSchede.data.items[0].data.rto.ptlRFI);
        Ext.ComponentQuery.query('#dataProtocolloRFIId')[0].setValue(rtoSchede.data.items[0].data.rto.datPtlRFI);
        Ext.ComponentQuery.query('#trenoIFId')[0].setValue(rtoSchede.data.items[0].data.rto.nomTreIpr);
        Ext.ComponentQuery.query('#dataProvvedimentoId')[0].setValue(rtoSchede.data.items[0].data.rto.datAtvPvv);
        Ext.ComponentQuery.query('#categoriaIFId')[0].setValue(rtoSchede.data.items[0].data.rto.ideCatTreIF);
        Ext.ComponentQuery.query('#committenteId')[0].setValue(rtoSchede.data.items[0].data.rto.ideCommittente);
        Ext.ComponentQuery.query('#origineId')[0].setValue(rtoSchede.data.items[0].data.rto.ideLocPrv);
        Ext.ComponentQuery.query('#destinazioneId')[0].setValue(rtoSchede.data.items[0].data.rto.ideLocDsn);
        Ext.ComponentQuery.query('#timeOraDest')[0].setValue(rtoSchede.data.items[0].data.rto.oraArr);
        Ext.ComponentQuery.query('#timeOraPart')[0].setValue(rtoSchede.data.items[0].data.rto.oraPar);
        Ext.ComponentQuery.query('#giorniRTOId')[0].setValue(rtoSchede.data.items[0].data.rto.giO_KMT);
        Ext.ComponentQuery.query('#tipoTracciaId')[0].setValue(rtoSchede.data.items[0].data.rto.ideTipTraccia);
        Ext.ComponentQuery.query('#tipoRTOId')[0].setValue(rtoSchede.data.items[0].data.rto.ideTipRTO);
        Ext.ComponentQuery.query('#tipoTrafficoId')[0].setValue(rtoSchede.data.items[0].data.rto.ideTipTra);
        Ext.ComponentQuery.query('#accordoQuadroId')[0].setValue(rtoSchede.data.items[0].data.rto.ideAcoQrd);
        Ext.ComponentQuery.query('#lottoId')[0].setValue(rtoSchede.data.items[0].data.rto.ideLtx);
        Ext.ComponentQuery.query('#noteId')[0].setValue(rtoSchede.data.items[0].data.rto.note);
        //reimposto tutto a readonly
        Ext.ComponentQuery.query('#periodoRtoId')[0].disable();
        Ext.ComponentQuery.query('#periodoFerroviarioId')[0].disable();
        Ext.ComponentQuery.query('#tipoVCOId')[0].disable();
        Ext.ComponentQuery.query('#protocolloIFId')[0].disable();
        Ext.ComponentQuery.query('#dataProtocolloIFId')[0].disable();
        Ext.ComponentQuery.query('#prtotocolloRFIId')[0].disable();
        Ext.ComponentQuery.query('#dataProtocolloRFIId')[0].disable();
        Ext.ComponentQuery.query('#trenoIFId')[0].disable();
        Ext.ComponentQuery.query('#dataProvvedimentoId')[0].disable();
        Ext.ComponentQuery.query('#categoriaIFId')[0].disable();
        Ext.ComponentQuery.query('#committenteId')[0].disable();
        Ext.ComponentQuery.query('#origineId')[0].disable();
        Ext.ComponentQuery.query('#destinazioneId')[0].disable();
        Ext.ComponentQuery.query('#timeOraDest')[0].disable();
        Ext.ComponentQuery.query('#timeOraPart')[0].disable();
        Ext.ComponentQuery.query('#giorniRTOId')[0].disable();
        Ext.ComponentQuery.query('#tipoTracciaId')[0].disable();
        Ext.ComponentQuery.query('#tipoRTOId')[0].disable();
        Ext.ComponentQuery.query('#tipoTrafficoId')[0].disable();
        Ext.ComponentQuery.query('#accordoQuadroId')[0].disable();
        Ext.ComponentQuery.query('#lottoId')[0].disable();
        Ext.ComponentQuery.query('#noteId')[0].disable();
    }

    function validatoreCampiRiepilogo(campo) {
        if (campo._required === true) {
            if (campo.getValue() !== null) {
                if (typeof campo.getValue() === 'string') {
                    if (campo.getValue().trim().length > 0)
                        return true;
                    else
                        return false;
                }
                else
                    return true;
            }
            else
                return false;
        }
        else
            return true;

    }

    function onConfermaModificaIFClick() {
        if (validatoreCampiRiepilogo(Ext.ComponentQuery.query('#periodoRtoId')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#periodoFerroviarioId')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#tipoVCOId')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#protocolloIFId')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#dataProtocolloIFId')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#trenoIFId')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#dataProvvedimentoId')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#categoriaIFId')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#origineId')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#destinazioneId')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#tipoRTOId')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#tipoTrafficoId')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#lottoId')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#timeOraDest')[0]) &&
            validatoreCampiRiepilogo(Ext.ComponentQuery.query('#timeOraPart')[0]) && Ext.ComponentQuery.query('#timeOraDest')[0]._error === null && Ext.ComponentQuery.query('#timeOraPart')[0]._error === null) {
            var timeArrMod = "";
            var timeParMod = "";
            if (typeof Ext.ComponentQuery.query('#timeOraDest')[0]._value !== 'string') {
                timeArrMod = ('0' + Ext.ComponentQuery.query('#timeOraDest')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#timeOraDest')[0]._value.getMinutes()).slice(-2);
            }
            if (typeof Ext.ComponentQuery.query('#timeOraPart')[0]._value !== 'string') {
                timeParMod = ('0' + Ext.ComponentQuery.query('#timeOraPart')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#timeOraPart')[0]._value.getMinutes()).slice(-2);
            }
            Ext.Ajax.request({
                url: 'Rto/ModificaRiepilogoRTO',
                method: 'POST',
                params: {
                    ideUte: user.ideUte,
                    periodoRtoId: Ext.ComponentQuery.query('#periodoRtoId')[0]._value,
                    protocolloIFId: Ext.ComponentQuery.query('#protocolloIFId')[0]._value,
                    trenoIFId: Ext.ComponentQuery.query('#trenoIFId')[0]._value,
                    origineId: Ext.ComponentQuery.query('#origineId')[0]._value,
                    tipoRTOId: Ext.ComponentQuery.query('#tipoRTOId')[0]._value,
                    dataProtocolloIFId: Ext.ComponentQuery.query('#dataProtocolloIFId')[0]._value,
                    dataProvvedimentoId: Ext.ComponentQuery.query('#dataProvvedimentoId')[0]._value,
                    destinazioneId: Ext.ComponentQuery.query('#destinazioneId')[0]._value,
                    tipoTrafficoId: Ext.ComponentQuery.query('#tipoTrafficoId')[0]._value,
                    categoriaIFId: Ext.ComponentQuery.query('#categoriaIFId')[0]._value,
                    accordoQuadroId: Ext.ComponentQuery.query('#accordoQuadroId')[0]._value,
                    committenteId: Ext.ComponentQuery.query('#committenteId')[0]._value,
                    lottoId: Ext.ComponentQuery.query('#lottoId')[0]._value,
                    noteId: Ext.ComponentQuery.query('#noteId')[0]._value,
                    minFlsDns: rtoSchede.data.items[0].data.rto.minFlsDns,
                    minFlsPrv: rtoSchede.data.items[0].data.rto.minFlsPrv,
                    ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                    oraArr: timeArrMod,
                    oraPar: timeParMod,
                    originePrecedente: origine,
                    destinazionePrecedente: destinazione,
                    protocolloIFPrecedente: rtoSchede.data.items[0].data.rto.ptlIF

                },
                callback: function (records, success, operation) {
                    if (success) {
                        var returnObj = Ext.decode(operation.responseText);
                        //modifica gestione errore
                        if (returnObj.resp === "KO") {
                            Ext.Msg.alert('Modifica riepilogo fallito', returnObj.msgErr);
                        }
                        else {
                            Ext.toast({ message: 'Riepilogo Salvato!', timeout: 4000 });
                            props.aggiornaStateTabPeriodicita(true);
                            props.aggiornaStateTabServiziComm(true);
                            props.aggiornaStateTabPercorso(true);
                            Ext.ComponentQuery.query('#buttonModificaRiepilogo')[0].setDisabled(false);
                            Ext.ComponentQuery.query('#confermaRiepilogo')[0].setDisabled(true);
                            Ext.ComponentQuery.query('#annullaRiepilogo')[0].setDisabled(true);
                            //reimposto tutto a readonly
                            Ext.ComponentQuery.query('#periodoRtoId')[0].disable();
                            Ext.ComponentQuery.query('#periodoFerroviarioId')[0].disable();
                            Ext.ComponentQuery.query('#tipoVCOId')[0].disable();
                            Ext.ComponentQuery.query('#protocolloIFId')[0].disable();
                            Ext.ComponentQuery.query('#dataProtocolloIFId')[0].disable();
                            Ext.ComponentQuery.query('#prtotocolloRFIId')[0].disable();
                            Ext.ComponentQuery.query('#dataProtocolloRFIId')[0].disable();
                            Ext.ComponentQuery.query('#trenoIFId')[0].disable();
                            Ext.ComponentQuery.query('#dataProvvedimentoId')[0].disable();
                            Ext.ComponentQuery.query('#categoriaIFId')[0].disable();
                            Ext.ComponentQuery.query('#committenteId')[0].disable();
                            Ext.ComponentQuery.query('#origineId')[0].disable();
                            Ext.ComponentQuery.query('#destinazioneId')[0].disable();
                            Ext.ComponentQuery.query('#timeOraDest')[0].disable();
                            Ext.ComponentQuery.query('#timeOraPart')[0].disable();
                            Ext.ComponentQuery.query('#giorniRTOId')[0].disable();
                            Ext.ComponentQuery.query('#tipoTracciaId')[0].disable();
                            Ext.ComponentQuery.query('#tipoRTOId')[0].disable();
                            Ext.ComponentQuery.query('#tipoTrafficoId')[0].disable();
                            Ext.ComponentQuery.query('#accordoQuadroId')[0].disable();
                            Ext.ComponentQuery.query('#lottoId')[0].disable();
                            Ext.ComponentQuery.query('#noteId')[0].disable();
                        
                            props.RefreshRto();
                            props.aggiornaStateModifica(true);
                        }
                    }
                    else {
                        Ext.Msg.alert('Modifica riepilogo fallita', 'Modifica riepilogo fallita');
                    }

                }
            });
        }
        else
            Ext.toast({ message: 'Verificare i campi contrassegnati come obbligatori!', timeout: 4000 })
    }
    function onModificaIFClick() {
        Ext.ComponentQuery.query('#panelRiepilogo')[0].expand();
        Ext.ComponentQuery.query('#buttonModificaRiepilogo')[0].setDisabled(true);
        Ext.ComponentQuery.query('#confermaRiepilogo')[0].setDisabled(false);
        Ext.ComponentQuery.query('#annullaRiepilogo')[0].setDisabled(false);
        //Ext.ComponentQuery.query('#periodoRtoId')[0].setReadOnly()
        switch (statoRTO) {
            case 0:
                Ext.ComponentQuery.query('#periodoRtoId')[0].disable();
                Ext.ComponentQuery.query('#periodoFerroviarioId')[0].disable();
                Ext.ComponentQuery.query('#tipoVCOId')[0].disable();
                Ext.ComponentQuery.query('#protocolloIFId')[0].enable();
                tipoTraccia !== 1 ? Ext.ComponentQuery.query('#dataProtocolloIFId')[0].enable() : Ext.ComponentQuery.query('#dataProtocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#prtotocolloRFIId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloRFIId')[0].disable();
                tipoTraccia !== 1 ? Ext.ComponentQuery.query('#trenoIFId')[0].enable() : Ext.ComponentQuery.query('#trenoIFId')[0].disable();
                tipoTraccia !== 1 && periodoRto === 2 ? Ext.ComponentQuery.query('#dataProvvedimentoId')[0].enable() : Ext.ComponentQuery.query('#dataProvvedimentoId')[0].disable();
                tipoVco !== '3' ? Ext.ComponentQuery.query('#categoriaIFId')[0].enable() : Ext.ComponentQuery.query('#categoriaIFId')[0].disable();
                tipoTraffico === 2 && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#committenteId')[0].enable() : Ext.ComponentQuery.query('#committenteId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#origineId')[0].enable() : Ext.ComponentQuery.query('#origineId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#destinazioneId')[0].enable() : Ext.ComponentQuery.query('#destinazioneId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraDest')[0].enable() : Ext.ComponentQuery.query('#timeOraDest')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraPart')[0].enable() : Ext.ComponentQuery.query('#timeOraPart')[0].disable();
                Ext.ComponentQuery.query('#giorniRTOId')[0].disable();
                Ext.ComponentQuery.query('#tipoTracciaId')[0].disable();
                tipoTraccia !== 1 ? Ext.ComponentQuery.query('#tipoRTOId')[0].enable() : Ext.ComponentQuery.query('#tipoRTOId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#tipoTrafficoId')[0].enable() : Ext.ComponentQuery.query('#tipoTrafficoId')[0].disable();
                (tipoVco === '0' || tipoVco === '2' || tipoVco === null) && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#accordoQuadroId')[0].enable() : Ext.ComponentQuery.query('#accordoQuadroId')[0].disable();
                Ext.ComponentQuery.query('#lottoId')[0].enable();
                Ext.ComponentQuery.query('#noteId')[0].enable();
                break;
            case 2:
                Ext.ComponentQuery.query('#periodoRtoId')[0].disable();
                Ext.ComponentQuery.query('#periodoFerroviarioId')[0].disable();
                Ext.ComponentQuery.query('#tipoVCOId')[0].disable();
                Ext.ComponentQuery.query('#protocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#prtotocolloRFIId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloRFIId')[0].disable();
                tipoTraccia !== 1 ? Ext.ComponentQuery.query('#trenoIFId')[0].enable() : Ext.ComponentQuery.query('#trenoIFId')[0].disable();
                tipoTraccia !== 1 && periodoRto === 2 ? Ext.ComponentQuery.query('#dataProvvedimentoId')[0].enable() : Ext.ComponentQuery.query('#dataProvvedimentoId')[0].disable();
                tipoVco !== '3' ? Ext.ComponentQuery.query('#categoriaIFId')[0].enable() : Ext.ComponentQuery.query('#categoriaIFId')[0].disable();
                tipoTraffico === 2 && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#committenteId')[0].enable() : Ext.ComponentQuery.query('#committenteId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#origineId')[0].enable() : Ext.ComponentQuery.query('#origineId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#destinazioneId')[0].enable() : Ext.ComponentQuery.query('#destinazioneId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraDest')[0].enable() : Ext.ComponentQuery.query('#timeOraDest')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraPart')[0].enable() : Ext.ComponentQuery.query('#timeOraPart')[0].disable();
                Ext.ComponentQuery.query('#giorniRTOId')[0].disable();
                Ext.ComponentQuery.query('#tipoTracciaId')[0].disable();
                Ext.ComponentQuery.query('#tipoRTOId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#tipoTrafficoId')[0].enable() : Ext.ComponentQuery.query('#tipoTrafficoId')[0].disable();
                (tipoVco === '0' || tipoVco === '2' || tipoVco === null) && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#accordoQuadroId')[0].enable() : Ext.ComponentQuery.query('#accordoQuadroId')[0].disable();
                Ext.ComponentQuery.query('#lottoId')[0].enable();
                Ext.ComponentQuery.query('#noteId')[0].enable();
                break;
            case 4:
                Ext.ComponentQuery.query('#periodoRtoId')[0].disable();
                Ext.ComponentQuery.query('#periodoFerroviarioId')[0].disable();
                Ext.ComponentQuery.query('#tipoVCOId')[0].disable();
                Ext.ComponentQuery.query('#protocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#prtotocolloRFIId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloRFIId')[0].disable();
                tipoTraccia !== 1 ? Ext.ComponentQuery.query('#trenoIFId')[0].enable() : Ext.ComponentQuery.query('#trenoIFId')[0].disable();
                tipoTraccia !== 1 && periodoRto === 2 ? Ext.ComponentQuery.query('#dataProvvedimentoId')[0].enable() : Ext.ComponentQuery.query('#dataProvvedimentoId')[0].disable();
                tipoVco !== '3' ? Ext.ComponentQuery.query('#categoriaIFId')[0].enable() : Ext.ComponentQuery.query('#categoriaIFId')[0].disable();
                tipoTraffico === 2 && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#committenteId')[0].enable() : Ext.ComponentQuery.query('#committenteId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#origineId')[0].enable() : Ext.ComponentQuery.query('#origineId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#destinazioneId')[0].enable() : Ext.ComponentQuery.query('#destinazioneId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraDest')[0].enable() : Ext.ComponentQuery.query('#timeOraDest')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraPart')[0].enable() : Ext.ComponentQuery.query('#timeOraPart')[0].disable();
                Ext.ComponentQuery.query('#giorniRTOId')[0].disable();
                Ext.ComponentQuery.query('#tipoTracciaId')[0].disable();
                Ext.ComponentQuery.query('#tipoRTOId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#tipoTrafficoId')[0].enable() : Ext.ComponentQuery.query('#tipoTrafficoId')[0].disable();
                (tipoVco === '0' || tipoVco === '2' || tipoVco === null) && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#accordoQuadroId')[0].enable() : Ext.ComponentQuery.query('#accordoQuadroId')[0].disable();
                Ext.ComponentQuery.query('#lottoId')[0].enable();
                Ext.ComponentQuery.query('#noteId')[0].enable();
                break;
            case 5:
                Ext.ComponentQuery.query('#periodoRtoId')[0].disable();
                Ext.ComponentQuery.query('#periodoFerroviarioId')[0].disable();
                Ext.ComponentQuery.query('#tipoVCOId')[0].disable();
                Ext.ComponentQuery.query('#protocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#prtotocolloRFIId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloRFIId')[0].disable();
                tipoTraccia !== 1 ? Ext.ComponentQuery.query('#trenoIFId')[0].enable() : Ext.ComponentQuery.query('#trenoIFId')[0].disable();
                tipoTraccia !== 1 && periodoRto === 2 ? Ext.ComponentQuery.query('#dataProvvedimentoId')[0].enable() : Ext.ComponentQuery.query('#dataProvvedimentoId')[0].disable();
                tipoVco !== '3' ? Ext.ComponentQuery.query('#categoriaIFId')[0].enable() : Ext.ComponentQuery.query('#categoriaIFId')[0].disable();
                tipoTraffico === 2 && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#committenteId')[0].enable() : Ext.ComponentQuery.query('#committenteId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#origineId')[0].enable() : Ext.ComponentQuery.query('#origineId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#destinazioneId')[0].enable() : Ext.ComponentQuery.query('#destinazioneId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraDest')[0].enable() : Ext.ComponentQuery.query('#timeOraDest')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraPart')[0].enable() : Ext.ComponentQuery.query('#timeOraPart')[0].disable();
                Ext.ComponentQuery.query('#giorniRTOId')[0].disable();
                Ext.ComponentQuery.query('#tipoTracciaId')[0].disable();
                Ext.ComponentQuery.query('#tipoRTOId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#tipoTrafficoId')[0].enable() : Ext.ComponentQuery.query('#tipoTrafficoId')[0].disable();
                (tipoVco === '0' || tipoVco === '2' || tipoVco === null) && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#accordoQuadroId')[0].enable() : Ext.ComponentQuery.query('#accordoQuadroId')[0].disable();
                Ext.ComponentQuery.query('#lottoId')[0].enable();
                Ext.ComponentQuery.query('#noteId')[0].enable();
                break;
            case 6:
                Ext.ComponentQuery.query('#periodoRtoId')[0].disable();
                Ext.ComponentQuery.query('#periodoFerroviarioId')[0].disable();
                Ext.ComponentQuery.query('#tipoVCOId')[0].disable();
                Ext.ComponentQuery.query('#protocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#prtotocolloRFIId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloRFIId')[0].disable();
                tipoTraccia !== 1 ? Ext.ComponentQuery.query('#trenoIFId')[0].enable() : Ext.ComponentQuery.query('#trenoIFId')[0].disable();
                tipoTraccia !== 1 && periodoRto === 2 ? Ext.ComponentQuery.query('#dataProvvedimentoId')[0].enable() : Ext.ComponentQuery.query('#dataProvvedimentoId')[0].disable();
                tipoVco !== '3' ? Ext.ComponentQuery.query('#categoriaIFId')[0].enable() : Ext.ComponentQuery.query('#categoriaIFId')[0].disable();
                tipoTraffico === 2 && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#committenteId')[0].enable() : Ext.ComponentQuery.query('#committenteId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#origineId')[0].enable() : Ext.ComponentQuery.query('#origineId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#destinazioneId')[0].enable() : Ext.ComponentQuery.query('#destinazioneId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraDest')[0].enable() : Ext.ComponentQuery.query('#timeOraDest')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraPart')[0].enable() : Ext.ComponentQuery.query('#timeOraPart')[0].disable();
                Ext.ComponentQuery.query('#giorniRTOId')[0].disable();
                Ext.ComponentQuery.query('#tipoTracciaId')[0].disable();
                Ext.ComponentQuery.query('#tipoRTOId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#tipoTrafficoId')[0].enable() : Ext.ComponentQuery.query('#tipoTrafficoId')[0].disable();
                (tipoVco === '0' || tipoVco === '2' || tipoVco === null) && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#accordoQuadroId')[0].enable() : Ext.ComponentQuery.query('#accordoQuadroId')[0].disable();
                Ext.ComponentQuery.query('#lottoId')[0].enable();
                Ext.ComponentQuery.query('#noteId')[0].enable();
                break;
            case 8:
                Ext.ComponentQuery.query('#periodoRtoId')[0].disable();
                Ext.ComponentQuery.query('#periodoFerroviarioId')[0].disable();
                Ext.ComponentQuery.query('#tipoVCOId')[0].disable();
                Ext.ComponentQuery.query('#protocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#prtotocolloRFIId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloRFIId')[0].disable();
                tipoTraccia !== 1 ? Ext.ComponentQuery.query('#trenoIFId')[0].enable() : Ext.ComponentQuery.query('#trenoIFId')[0].disable();
                tipoTraccia !== 1 && periodoRto === 2 ? Ext.ComponentQuery.query('#dataProvvedimentoId')[0].enable() : Ext.ComponentQuery.query('#dataProvvedimentoId')[0].disable();
                tipoVco !== '3' ? Ext.ComponentQuery.query('#categoriaIFId')[0].enable() : Ext.ComponentQuery.query('#categoriaIFId')[0].disable();
                tipoTraffico === 2 && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#committenteId')[0].enable() : Ext.ComponentQuery.query('#committenteId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#origineId')[0].enable() : Ext.ComponentQuery.query('#origineId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#destinazioneId')[0].enable() : Ext.ComponentQuery.query('#destinazioneId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraDest')[0].enable() : Ext.ComponentQuery.query('#timeOraDest')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraPart')[0].enable() : Ext.ComponentQuery.query('#timeOraPart')[0].disable();
                Ext.ComponentQuery.query('#giorniRTOId')[0].disable();
                Ext.ComponentQuery.query('#tipoTracciaId')[0].disable();
                Ext.ComponentQuery.query('#tipoRTOId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#tipoTrafficoId')[0].enable() : Ext.ComponentQuery.query('#tipoTrafficoId')[0].disable();
                (tipoVco === '0' || tipoVco === '2' || tipoVco === null) && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#accordoQuadroId')[0].enable() : Ext.ComponentQuery.query('#accordoQuadroId')[0].disable();
                Ext.ComponentQuery.query('#lottoId')[0].enable();
                Ext.ComponentQuery.query('#noteId')[0].enable();
                break;
            default:
                Ext.ComponentQuery.query('#periodoRtoId')[0].disable();
                Ext.ComponentQuery.query('#periodoFerroviarioId')[0].disable();
                Ext.ComponentQuery.query('#tipoVCOId')[0].disable();
                Ext.ComponentQuery.query('#protocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#prtotocolloRFIId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloRFIId')[0].disable();
                Ext.ComponentQuery.query('#trenoIFId')[0].disable();
                Ext.ComponentQuery.query('#dataProvvedimentoId')[0].disable();
                Ext.ComponentQuery.query('#categoriaIFId')[0].disable();
                Ext.ComponentQuery.query('#committenteId')[0].disable();
                Ext.ComponentQuery.query('#origineId')[0].disable();
                Ext.ComponentQuery.query('#destinazioneId')[0].disable();
                Ext.ComponentQuery.query('#timeOraDest')[0].disable();
                Ext.ComponentQuery.query('#timeOraPart')[0].disable();
                Ext.ComponentQuery.query('#giorniRTOId')[0].disable();
                Ext.ComponentQuery.query('#tipoTracciaId')[0].disable();
                Ext.ComponentQuery.query('#tipoRTOId')[0].disable();
                Ext.ComponentQuery.query('#tipoTrafficoId')[0].disable();
                Ext.ComponentQuery.query('#accordoQuadroId')[0].disable();
                Ext.ComponentQuery.query('#lottoId')[0].disable();
                Ext.ComponentQuery.query('#noteId')[0].disable();
        }
    }

    function onModificaRFIClick() {
        Ext.ComponentQuery.query('#panelRiepilogo')[0].expand();
        Ext.ComponentQuery.query('#buttonModificaRiepilogo')[0].setDisabled(true);
        Ext.ComponentQuery.query('#confermaRiepilogo')[0].setDisabled(false);
        Ext.ComponentQuery.query('#annullaRiepilogo')[0].setDisabled(false);
        switch (statoRTO) {
            case 2:
                Ext.ComponentQuery.query('#periodoRtoId')[0].disable();
                Ext.ComponentQuery.query('#periodoFerroviarioId')[0].disable();
                Ext.ComponentQuery.query('#tipoVCOId')[0].disable();
                Ext.ComponentQuery.query('#protocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#prtotocolloRFIId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloRFIId')[0].disable();
                tipoTraccia !== 1 ? Ext.ComponentQuery.query('#trenoIFId')[0].enable() : Ext.ComponentQuery.query('#trenoIFId')[0].disable();
                tipoTraccia !== 1 && periodoRto === 2 ? Ext.ComponentQuery.query('#dataProvvedimentoId')[0].enable() : Ext.ComponentQuery.query('#dataProvvedimentoId')[0].disable();
                tipoVco !== '3' ? Ext.ComponentQuery.query('#categoriaIFId')[0].enable() : Ext.ComponentQuery.query('#categoriaIFId')[0].disable();
                tipoTraffico === 2 && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#committenteId')[0].enable() : Ext.ComponentQuery.query('#committenteId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#origineId')[0].enable() : Ext.ComponentQuery.query('#origineId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#destinazioneId')[0].enable() : Ext.ComponentQuery.query('#destinazioneId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraDest')[0].enable() : Ext.ComponentQuery.query('#timeOraDest')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#timeOraPart')[0].enable() : Ext.ComponentQuery.query('#timeOraPart')[0].disable();
                Ext.ComponentQuery.query('#giorniRTOId')[0].disable();
                Ext.ComponentQuery.query('#tipoTracciaId')[0].disable();
                Ext.ComponentQuery.query('#tipoRTOId')[0].disable();
                tipoTraccia !== 1 && tipoVco !== '3' ? Ext.ComponentQuery.query('#tipoTrafficoId')[0].enable() : Ext.ComponentQuery.query('#tipoTrafficoId')[0].disable();
                (tipoVco === '0' || tipoVco === '2' || tipoVco === null) && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#accordoQuadroId')[0].enable() : Ext.ComponentQuery.query('#accordoQuadroId')[0].disable();
                Ext.ComponentQuery.query('#lottoId')[0].enable();
                Ext.ComponentQuery.query('#noteId')[0].enable();
                break;
            default:
                Ext.ComponentQuery.query('#periodoRtoId')[0].disable();
                Ext.ComponentQuery.query('#periodoFerroviarioId')[0].disable();
                Ext.ComponentQuery.query('#tipoVCOId')[0].disable();
                Ext.ComponentQuery.query('#protocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloIFId')[0].disable();
                Ext.ComponentQuery.query('#prtotocolloRFIId')[0].disable();
                Ext.ComponentQuery.query('#dataProtocolloRFIId')[0].disable();
                Ext.ComponentQuery.query('#trenoIFId')[0].disable();
                Ext.ComponentQuery.query('#dataProvvedimentoId')[0].disable();
                Ext.ComponentQuery.query('#categoriaIFId')[0].disable();
                Ext.ComponentQuery.query('#committenteId')[0].disable();
                Ext.ComponentQuery.query('#origineId')[0].disable();
                Ext.ComponentQuery.query('#destinazioneId')[0].disable();
                Ext.ComponentQuery.query('#timeOraDest')[0].disable();
                Ext.ComponentQuery.query('#timeOraPart')[0].disable();
                Ext.ComponentQuery.query('#giorniRTOId')[0].disable();
                Ext.ComponentQuery.query('#tipoTracciaId')[0].disable();
                Ext.ComponentQuery.query('#tipoRTOId')[0].disable();
                Ext.ComponentQuery.query('#tipoTrafficoId')[0].disable();
                Ext.ComponentQuery.query('#accordoQuadroId')[0].disable();
                Ext.ComponentQuery.query('#lottoId')[0].disable();
                Ext.ComponentQuery.query('#noteId')[0].disable();
        }
    }

    function openLink() {
        window.open("/SchedaRtoLettura?ideRto=" + rtoSchede.data.items[0].data.rto.ideRTOOrig + "&ideCli=" + rtoSchede.data.items[0].data.rto.ideCli, '_blank');
    }



    return (

        <Panel
            title="Riepilogo"
            scrollable
            itemId="panelRiepilogo"
            bodyPadding={5}
            titleCollapse={true}
            iconCls="x-fa fa-th-list"
            collapsible={{ collapsed: false, direction: 'top' }}
            cls='PnlSectionL1 iconPanelsRTO'
            tools={[
                {
                    iconCls: 'x-fa fa-edit', tooltip: 'Modifica', hidden: !props.inEdit, itemId: "buttonModificaRiepilogo", handler: isIF ? onModificaIFClick.bind(this) : onModificaRFIClick.bind(this)
                },
                { iconCls: 'x-fa fa-check', itemId: "confermaRiepilogo", hidden: !props.inEdit, disabled: 'true', handler: onConfermaModificaIFClick.bind(this), tooltip: 'Conferma' },
                { iconCls: 'x-fa fa-undo', itemId: "annullaRiepilogo", handler: onAnnullaModificaIFClick.bind(this), hidden: !props.inEdit, disabled: 'true', tooltip: 'Annulla' },
                /* { type: 'menu', handler: toolHandler }*/
            ]}
        >
            <FormPanel layout="hbox" >

                <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                    <ComboBoxField
                        label="Periodo RTO"
                        labelAlign="top"
                        store={contextVersioneRto.storePeriodoRTO}
                        displayField="desTipPer"
                        valueField="ideTipPer"
                        queryMode="local"
                        itemId="periodoRtoId"
                        typeAhead
                        required={true}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        forceSelection={true}
                        disabled={true}
                        value={periodoRto}
                    />
                    <TextField clearable={false} label="Protocollo IF" itemId="protocolloIFId" labelAlign="top" disabled={true} head="20" minwidth="200" required={true} maxLength={50} requiredMessage="Questo campo &egrave; obbligatorio" value={protocolloIF} />
                    <TextField clearable={false} label="N. Treno IF" itemId="trenoIFId" labelAlign="top" labelCls='LabelRiepilogoBlu' head="20" disabled={true} required={true} maxLength={100} requiredMessage="Questo campo &egrave; obbligatorio" value={numTrenoIF} />
                    <Panel layout="hbox" margin="4 0 4 0">
                    <ComboBoxField
                            label="Origine"
                            labelAlign="top"
                            itemId="origineId"
                            typeAhead
                            store={contextVersioneRto.storeLocalita}
                            displayField="nomLoc"
                            valueField="ideLoc"
                            labelCls='LabelRiepilogoBlu'
                            required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                            forceSelection={true}
                            disabled={true}
                            flex={0.8}
                            value={origine}
                            queryMode="local"
                        />
                        <TimeField
                            label="Ora"
                            labelAlign="top"
                            flex={0.2}
                            minWidth={80}
                            disabled={true}
                            itemId='timeOraPart'
                            format='H:i'
                            maxLength={5}
                            picker={{
                                meridiem: false,
                                alignPMInside: true
                            }}
                            value={oraPartenza}
                       />
                    </Panel>
                    <ComboBoxField
                        label="Tipo RTO"
                        labelAlign="top"
                        disabled={true}
                        store={contextVersioneRto.storeTipologieRTO}
                        displayField="desTipRTO"
                        itemId="tipoRTOId"
                        valueField="ideTipRTO"
                        queryMode="local"
                        required={true}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        forceSelection={true}
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        value={tipoRTO}
                    />
                </Panel>

                <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                    <ComboBoxField
                        label="Periodo Ferroviario"
                        labelAlign="top"
                        store={contextVersioneRto.storePeriodiFerroviari}
                        displayField="desAnnFro"
                        disabled={true}
                        itemId="periodoFerroviarioId"
                        valueField="ideAnnFro"
                        queryMode="local"
                        required={true}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        forceSelection={true}
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        value={periodo}
                        listeners={{
                            select: function (combo, value, oldValue) {
                                rangeDateStartProvvediamento = new Date(combo._selection.data.datIniVal);
                                rangeDateEndProvvediamento = new Date(combo._selection.data.datFinVal);
                                storeLotti.clearData();
                                storeLotti.removeAll();
                                storeLotti.load({
                                    params: {
                                        ideUte: user.ideUte,
                                        ideCli: rtoSchede.data.items[0].data.rto.ideCli,
                                        ideAnnFro: value.data.ideAnnFro
                                    }
                                });
                            },
                            change: function (combo, value, oldValue) {
                                rangeDateStartProvvediamento = new Date(combo._selection.data.datIniVal);
                                rangeDateEndProvvediamento = new Date(combo._selection.data.datFinVal);
                                storeLotti.clearData();
                                storeLotti.removeAll();
                                storeLotti.load({
                                    params: {
                                        ideUte: user.ideUte,
                                        ideCli: rtoSchede.data.items[0].data.rto.ideCli,
                                        ideAnnFro: value
                                    }
                                });
                            }
                        }}
                    />
                    <DatePickerField
                        clearable={false}
                        label="Data Prot.IF"
                        itemId="dataProtocolloIFId"
                        labelAlign="top"
                        disabled={true}
                        value={dataProtIF}
                        required={true}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        destroyPickerOnHide
                        picker={{
                            yearFrom: 1990
                        }}
                        listeners={{
                            change: function (filed, newValue) {
                                if (newValue > rangeDateEnd || newValue < rangeDateStart) {
                                    Ext.ComponentQuery.query('#dataProtocolloIFId')[0].setValue(null);
                                }
                            }
                        }}
                    />
                    <DatePickerField
                        clearable={false}
                        label="Data Provvedimento"
                        itemId="dataProvvedimentoId"
                        labelAlign="top"
                        labelCls='LabelRiepilogoBlu'
                        disabled={true}
                        value={dataProvvedimento}
                        required={dataProvvedimentoRequired}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        destroyPickerOnHide
                        picker={{
                            yearFrom: 1990
                        }}
                        listeners={{
                            change: function (filed, newValue) {
                                if (newValue > rangeDateEndProvvediamento || newValue < rangeDateStartProvvediamento) {
                                    Ext.ComponentQuery.query('#dataProvvedimentoId')[0].setValue(null);
                                }
                            }
                        }}
                    />
                    <Panel layout="hbox" margin="4 0 4 0">
                    <ComboBoxField
                        label="Destinazione"
                        labelAlign="top"
                        itemId="destinazioneId"
                        typeAhead
                        labelCls='LabelRiepilogoBlu'
                        store={contextVersioneRto.storeLocalita}
                        displayField="nomLoc"
                        valueField="ideLoc"
                            required={true}
                            flex={0.8}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        forceSelection={true}
                        disabled={true}
                        value={destinazione}
                            queryMode="local"
                    />
                        <TimeField
                            label="Ora"
                            labelAlign="top"
                            itemId='timeOraDest'
                            format='H:i'
                            disabled={true}
                            flex={0.2}
                            minWidth={80}
                            maxLength={5}
                            picker={{
                                meridiem: false,
                                alignPMInside: true
                            }}
                            value={oraArrivo}
                       />
                    </Panel>
                    <ComboBoxField
                        label="Tipo Traffico"
                        labelAlign="top"
                        store={contextVersioneRto.storeTipoTraffico}
                        displayField="desTipTra"
                        valueField="ideTipTra"
                        itemId="tipoTrafficoId"
                        queryMode="local"
                        forceSelection={true}
                        disabled={true}
                        required={true}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        value={tipoTraffico}
                        listeners={{
                            change: function (combo, value, oldValue) {
                                value === 2 && tipoTraccia !== 1 ? Ext.ComponentQuery.query('#committenteId')[0].enable() : Ext.ComponentQuery.query('#committenteId')[0].disable();
                                if (value === 4 && tipoTraffico === 2) {
                                    Ext.Msg.alert('Attenzione', 'Con la modifica del Tipo Traffico verrà cancellato l’eventuale Committente Principale inserito e verranno cancellate eventuali specifiche di Servizi Commerciali e Fermate Viaggiatori.')
                                }
                                else if (value === 4 && tipoTraffico === 1) {
                                    Ext.Msg.alert('Attenzione', 'Con la modifica del Tipo Traffico verranno cancellate eventuali specifiche di Servizi Commerciali e Fermate Viaggiatori')
                                }
                                else if (value === 4 && tipoTraffico === 3) {
                                    Ext.Msg.alert('Attenzione', 'Con la modifica del Tipo Traffico verranno cancellate eventuali specifiche di Dettaglio Merci e Fermate Merci')
                                }
                            }
                            
                        }}
                    />
                 </Panel>

                <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                    <ComboBoxField
                        label="Tipo VCO"
                        labelAlign="top"
                        store={contextVersioneRto.storeTipoVCO}
                        displayField="etichetta"
                        itemId="tipoVCOId"
                        disabled={true}
                        valueField="valore"
                        queryMode="local"
                        required={tipoVcoRequire}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        forceSelection={true}
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        value={tipoVco !== '0' ? tipoVco : null}
                    />
                    <TextField clearable={false} disabled={true} label="Protocollo RFI" itemId="prtotocolloRFIId" labelAlign="top" head="20" minwidth="200" value={protocolloRFI} />
                    <ComboBoxField
                        label="Categoria IF"
                        labelAlign="top"
                        store={contextVersioneRto.storeCatTreniIF}
                        displayField="desCatTre"
                        valueField="ideCatTre"
                        queryMode="local"
                        labelCls='LabelRiepilogoBlu'
                        itemId="categoriaIFId"
                        disabled={true}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        required={true}
                        forceSelection={true}
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        value={categoriaIF}
                    />
                    <TextField clearable={false} label="Giorni RTO" itemId="giorniRTOId" labelCls='LabelRiepilogoBlu' disabled={true} labelAlign="top"  head="20" value={giorniRTO} />
                    <ComboBoxField
                        label="Accordo Quadro"
                        labelAlign="top"
                        store={contextVersioneRto.storeAccordiQuadro}
                        displayField="desAcoQrd"
                        valueField="ideAcoQrd"
                        itemId="accordoQuadroId"
                        queryMode="local"
                        disabled={true}
                        forceSelection={true}
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        value={accordoQuadro}
                    />
                </Panel>

                <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                    <Button text={rtoOrig} cls={rtoCls} style={{
                        color: ideRtoOri !== 0 ? '#FFFFFF' : '#000000',
                    }} labelAlign="top" margin="23 0 5 0" itemId="rtoOriginariaId" head="20" handler={() => {
                        if (ideRtoOri !== 0) {
                            openLink();
                        } 
                    }} />
                    <DatePickerField
                        clearable={false}
                        label="Data Prot.RFI"
                        itemId="dataProtocolloRFIId"
                        labelAlign="top"
                        disabled={true}
                        value={dataProtRFI}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        destroyPickerOnHide
                        picker={{
                            yearFrom: 1990
                        }}
                    />
                    <ComboBoxField
                        label="Committente"
                        labelAlign="top"
                        itemId="committenteId"
                        labelCls='LabelRiepilogoBlu'
                        store={contextVersioneRto.storeCommittenti}
                        displayField="nomeCommittente"
                        valueField="ideCommittente"
                        disabled={true}
                        queryMode="local"
                        forceSelection={true}
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        value={committente}
                    />
                    <ComboBoxField
                        label="Tipo Traccia"
                        labelAlign="top"
                        itemId="tipoTracciaId"
                        store={contextVersioneRto.storeTipoTraccia}
                        disabled={true}
                        displayField="desTipTraccia"
                        valueField="ideTipTraccia"
                        queryMode="local"
                        forceSelection={true}
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        value={tipoTraccia}
                    />
                    <ComboBoxField
                        label="Lotto"
                        labelAlign="top"
                        store={storeLotti}
                        displayField="nomLtx"
                        itemId="lottoId"
                        valueField="ideLtx"
                        required={true}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        disabled={true}
                        queryMode="local"
                        forceSelection={true}
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        value={lotto}
                    />
                </Panel>

            </FormPanel>


            <Panel
                title="Note"
                iconCls="x-fa fa-sticky-note"
                collapsible={{ collapsed: false, direction: 'top' }}
                cls='PnlSectionL1'
                padding={3}
            >
                <FormPanel >
                    <TextAreaField
                        flex={1}
                        maxLength={1000}
                        maxRows={10}
                        itemId="noteId"
                        disabled={true}
                        clearable={false}
                        value={note}
                    />
                </FormPanel>
            </Panel>

        </Panel>
    );
}

export default RtoRiepilogo;